import { Injectable } from '@angular/core';
import { Workbook, Worksheet } from 'exceljs';
import * as fs from 'file-saver';
import { calidad_customer }  from '../../models/hipotecario/calidad_customer';

@Injectable({
  providedIn: 'root'
})
export class hipotecarioExcelCustomerService {

  constructor() { }
  exportExcel(info : calidad_customer[],sector_name:string) { 
    
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Calidad Customer Hipotecario');
   
    var name = 'reporte_calidadCustomer_hipotecario';
    var header = ['CALIDAD_ID', 'AUDITOR', 'CANAL', 'FECHA', 'FECHA GESTION', 'ASESOR', 'DNI CLIENTE', 'ID AUDIO','REGISTRO DE ACTIVIDAD EN SISTEMA','ERROR DE INFORMACIÓN','VALIDACIÓN Y ACTUALIZACIÓN DE DATOS','INTERES POR RESOLVER/RETENER','PROCEDIMIENTO DE RETENCIÓN/RESOLUCIÓN','SALUDO INICIAL / SALUDO FINAL','HABILIDADES DE COMUNICACIÓN','RESUMEN DE GESTIÓN','HOLD','SCORE','NOTA DE CALIDAD','REGISTRO','NO RETENCIÓN','EVALUACIÓN CROSS','OBS. ADICIONAL'];
  
        
    var fila = worksheet.addRow([]);
    fila.height = 30;                      
    fila.font = { name: 'Arial', family: 4, size: 10, bold: true};//underline: 'double', 
    fila.getCell('A').value = 'REPORTE CALIDAD CUSTOMER';
    fila.getCell('A').style.alignment = {horizontal: 'center'};
    fila.getCell('A').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
          argb: '00939b9e'
      }
    };
    
    fila.getCell('I').value = 'PONDERACIONES CUSTOMER SERVICES';
    fila.getCell('I').style.alignment = {horizontal: 'center'};
    fila.getCell('I').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
          argb: '00D3D7D8'
      }
    };

    fila.getCell('R').value = '';
    fila.getCell('R').style.alignment = {horizontal: 'center'};
    fila.getCell('R').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
          argb: '00939b9e'
      }
    };
    //fila.getCell('I').font = {color: {argb: "004e47cc"}};
    worksheet.mergeCells('A1:H1');   
    worksheet.mergeCells('I1:Q1');   
    worksheet.mergeCells('R1:W1');   
    //worksheet.getColumn(1).alignment = { horizontal: 'center' };
    worksheet.getRow(1).alignment = { horizontal: 'center' };
  
    //fila 2: subtitulos
    var filaEncabezado = worksheet.addRow(header);

    filaEncabezado.height = 50;
    filaEncabezado.font = { name: 'Calibri', family: 4, size: 9, bold: true };
    filaEncabezado.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
          argb: '00D3D7D8'
      }
    };
    worksheet.getRow(2).alignment = { horizontal: 'center' };
  
      
    info.forEach(d => {
      this.addRow(d, worksheet);
     
    });


   
    //calidadId
    worksheet.getColumn(1).width = 40;
    filaEncabezado.getCell(1).alignment = { horizontal: 'center', wrapText: true};
    //auditor
    worksheet.getColumn(2).width = 20;
    filaEncabezado.getCell(2).alignment = { horizontal: 'center', wrapText: true};
    //canal
    worksheet.getColumn(3).width = 40;
    filaEncabezado.getCell(3).alignment = { horizontal: 'center', wrapText: true};
    //fecha
    worksheet.getColumn(4).width = 20;
    filaEncabezado.getCell(4).alignment = { horizontal: 'center', wrapText: true};
    //fecha gestion
    worksheet.getColumn(5).width = 20;
    filaEncabezado.getCell(5).alignment = { horizontal: 'center', wrapText: true};
    //asesor
    worksheet.getColumn(6).width = 16;
    filaEncabezado.getCell(6).alignment = { horizontal: 'center', wrapText: true};
    //dni cliente ()
    worksheet.getColumn(7).width = 15;
    filaEncabezado.getCell(7).alignment = { horizontal: 'center', wrapText: true};
    //id audio
    worksheet.getColumn(8).width = 40;  
    filaEncabezado.getCell(8).alignment = { horizontal: 'center', wrapText: true};
    //REGISTRO ACT.SISTEMA
    worksheet.getColumn(9).width = 15;  
    filaEncabezado.getCell(9).alignment = { horizontal: 'center', wrapText: true};
    //ERROR DE INF.
    worksheet.getColumn(10).width = 15;  
    filaEncabezado.getCell(10).alignment = { horizontal: 'center', wrapText: true};
    //VALIDACIÓN Y ACTUALIZACIÓN DE DATOS
    worksheet.getColumn(11).width = 15;  
    filaEncabezado.getCell(11).alignment = { horizontal: 'center', wrapText: true};
    //INTERES POR RESOLVER/RETENER
    worksheet.getColumn(12).width = 15;  
    filaEncabezado.getCell(12).alignment = { horizontal: 'center', wrapText: true};
    //PROCEDIMIENTO DE RET/RES.
    worksheet.getColumn(13).width = 20;  
    filaEncabezado.getCell(13).alignment = { horizontal: 'center', wrapText: true};
    //SALUDO INI/FIN
    worksheet.getColumn(14).width = 15;  
    filaEncabezado.getCell(14).alignment = { horizontal: 'center', wrapText: true};
    //HAB. DE COMUNIC.
    worksheet.getColumn(15).width = 15;  
    filaEncabezado.getCell(15).alignment = { horizontal: 'center', wrapText: true};
    //RESUMEN DE GESTIÓN
    worksheet.getColumn(16).width = 15;  
    filaEncabezado.getCell(6).alignment = { horizontal: 'center', wrapText: true};
    //HOLD
    worksheet.getColumn(17).width = 15;  
    filaEncabezado.getCell(17).alignment = { horizontal: 'center', wrapText: true};

    //score
    worksheet.getColumn(18).width = 10;
    filaEncabezado.getCell(18).alignment = { horizontal: 'center', wrapText: true};
    //nota de calidad
    worksheet.getColumn(19).width = 20;
    filaEncabezado.getCell(19).alignment = { horizontal: 'center', wrapText: true};
    //registro
    worksheet.getColumn(20).width = 12;
    filaEncabezado.getCell(20).alignment = { horizontal: 'center', wrapText: true};
    //no retencion
    worksheet.getColumn(21).width = 18;
    filaEncabezado.getCell(21).alignment = { horizontal: 'center', wrapText: true};
    //evaluacion cross
    worksheet.getColumn(22).width = 20;
    filaEncabezado.getCell(22).alignment = { horizontal: 'center', wrapText: true};
    //obs adicional
    worksheet.getColumn(23).width = 50;  
    filaEncabezado.getCell(23).alignment = { horizontal: 'center', wrapText: true};
    
    
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, name + '.xlsx');
    });
  }

  private addRow(info : calidad_customer, worksheet: Worksheet) {    
    var row = worksheet.addRow([info.evaluationId,info.evaluator,info.mediaType,info.fecha_llamada,info.fecha_auditoria,info.agente,info.dni_cliente,info.conversationId,info.RegistroActividad,info.errorInfo,info.validacionDatos,info.interesResolver,info.procedimientoRetencion,info.saludosIniFin,info.habilidadesComunicacion,info.resumenGestion,info.hold,info.score,info.NotaCalidad,info.motivo_llamado,info.retencion,info.answer_textCross,info.comments]);
    row.height = 25; 
    row.font = {name: 'Calibri', size: 9};
  }
}
