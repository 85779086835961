import { Injectable } from '@angular/core';
import * as platformClient  from 'purecloud-platform-client-v2';
import { Conversation } from 'src/app/models/purecloud/conversation';
import Swal from 'sweetalert2'
import { TimeRange }  from '../../../services/purecloud/time-range';
import { PurecloudService } from '../../purecloud/purecloud.service';
import { venta_cci }  from 'src/app/models/ventas/venta_cci';

@Injectable({
  providedIn: 'root'
})
export abstract class ConversationDataPurecloudService {
  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1000
  });

  constructor(private PurecloudService : PurecloudService) { }

  protected abstract processInternalCall(conversation: platformClient.Models.AnalyticsConversation, timeRange : TimeRange,calls : Object[],timeRangeDay: TimeRange,aUsers:any,aQueues:any,aWraUps,aUserStatusAggregateDay: Array<any>);
  public abstract processCall(conversation : Conversation, calls : Object[]);

  public generate(from: Date, to: Date,predicateClause, predicate, isSync: boolean, callsVenta,aWraUps,aUserStatusAggregateDay: Array<any>) : Promise<Object[]> {
    let promise = new Promise<Object[]>((resolve, reject) => {
     // console.log(callsVenta);
      var calls = callsVenta;

      if(calls.length === 0){

        calls.push({});
      }

      var call = new venta_cci();
      call.aUsers = [];
      call.aQueues = [];
      var timeRange = new TimeRange(from, to,31);
      var timeRangeDay = new TimeRange(from, to,1);
      console.log(timeRangeDay);
      console.log("isSync" ,isSync);
      if(isSync) {
        //TODO: si nos dan ok pasar los param del sync al async
      this.downSync(timeRange,timeRangeDay, predicateClause,predicate, calls,call.aUsers,call.aQueues,aWraUps,aUserStatusAggregateDay, resolve, reject);
      } else {
        this.downAsync(timeRange,timeRangeDay, predicateClause,predicate, calls,call.aUsers,call.aQueues,aWraUps,aUserStatusAggregateDay,{} ,resolve, reject);
      }
    });
    return promise;
  }

  protected downSync(timeRange : TimeRange,timeRangeDay: TimeRange,predicateClause, predicate, calls : Object[],aUsers:any,aQueues:any,aWraUps,aUserStatusAggregateDay: Array<any>, resolve, reject) : void {
    if(timeRange.isOk()) {
      this.downSyncParcial(0, timeRange,timeRangeDay,predicateClause, predicate, calls,aUsers,aQueues,aWraUps,aUserStatusAggregateDay, {}, resolve, reject);
    } else {
      resolve(calls);
    }
  }

  private downSyncParcial(index : number, timeRange : TimeRange,timeRangeDay: TimeRange, predicateClause,predicate, calls : Object[],aUsers:any,aQueues:any,aWraUps,aUserStatusAggregateDay: Array<any>, errors, resolve, reject) : void {
    index++;
    this.PurecloudService.postAnalyticsConversationsDetailsQuery(timeRange.fromParcial, timeRange.toParcial,predicateClause, predicate, index)
    .then((queryResponse) => this.processSyncParcial(queryResponse.conversations, index, timeRange,timeRangeDay,predicateClause, predicate, calls,aUsers,aQueues,aWraUps,aUserStatusAggregateDay, errors, resolve, reject))
    .catch((response) => this.downSyncParcialCatch(index, timeRange,timeRangeDay, predicateClause,predicate, calls,aUsers,aQueues,aWraUps,aUserStatusAggregateDay, response, errors, resolve, reject));
  }
  private downSyncParcialCatch(index : number, timeRange : TimeRange,timeRangeDay: TimeRange,predicateClause, predicate, calls : Object[],aUsers:any,aQueues:any,aWraUps,aUserStatusAggregateDay, response, errors, resolve, reject) : void {
    if(response.error !== undefined && response.error.errno !== undefined) {
      var count = errors[response.error.errno + "_" + index]
      if(count === undefined) {
        count = 0;
      }
      index--;

      if(count < 3) {
        count++;
        errors[response.error.errno + "_" + index] = count;
        console.log("Errors: ", errors);
        this.downSyncParcial(index, timeRange,timeRangeDay,predicateClause, predicate, calls,aUsers,aQueues,aWraUps,aUserStatusAggregateDay, errors, resolve, reject);
      } else {
        reject(response);
      }
    } else {
      reject(response);
    }
  }
  private processSyncParcial(conversations : platformClient.Models.AnalyticsConversation[], index : number, timeRange : TimeRange,timeRangeDay: TimeRange,predicateClause, predicate, calls : Object[],aUsers:any,aQueues:any,aWraUps, aUserStatusAggregateDay: Array<any>,errors, resolve, reject) {
    console.log("index: ", index, ", length: ", conversations === undefined ? undefined : conversations.length);
    if(conversations == undefined || conversations.length == 0) {
      console.log(timeRange.addDate());
      timeRange.addDate();
      this.downSync(timeRange, timeRangeDay,predicateClause,predicate, calls,aUsers,aQueues,aWraUps,aUserStatusAggregateDay, resolve, reject);
    } else {
      timeRange.addCount(conversations.length);
      conversations.forEach((conversation) =>  this.processInternalCall(conversation, timeRange,calls,timeRangeDay,aUsers,aQueues,aWraUps,aUserStatusAggregateDay));


      this.Toast.fire({
        title: 'Interacciones consideradas ' + calls.length + ' de ' + timeRange.count,
        timer: 1000
      });

      this.downSyncParcial(index, timeRange,timeRangeDay,predicateClause, predicate, calls,aUsers,aQueues,aWraUps,aUserStatusAggregateDay, errors, resolve, reject);
    }
  }

  private downAsync(timeRange : TimeRange,timeRangeDay: TimeRange,predicateClause, predicate, calls : Object[],aUsers:any,aQueues:any,aWraUps,aUserStatusAggregateDay: Array<any>,errors, resolve, reject) {

    var info = {
      html: undefined,
      cancel: false
    }

    Swal.fire({
      title: 'Esperando...',
      html: 'Se espera la respuesta de Purecloud <b></b>',
      didOpen: () => this.didOpenSwal(timeRange, predicateClause, predicate, calls, info,timeRangeDay,aUsers,aQueues,aWraUps,aUserStatusAggregateDay,errors ,resolve, reject),
      willClose: () => {
        info.cancel = true;
      }
    }).then((result) => {
      console.log(result);
    })
  }

  private async didOpenSwal(timeRange : TimeRange, predicateClause, predicate, calls : Object[], info,timeRangeDay: TimeRange,aUsers:any,aQueues:any,aWraUps,aUserStatusAggregateDay: Array<any>,errors, resolve, reject) {
    Swal.showLoading();
    const content = Swal.getContent();
    if (content) {
      const b = content.querySelector('b');
      if (b) {
        b.textContent = '';
        info.html = b;
      }
      console.log("predicate:" , predicate);
      console.log("predicate:" , predicateClause);
      console.log("timeRange.to",timeRange.to);
      console.log("timeRange.from",timeRange.from);

      this.PurecloudService.postAnalyticsConversationsDetailsJobs(timeRange.from, timeRange.to, predicate, predicateClause)
      .then((queryResponse) => this.checkStatusJob(queryResponse.jobId, timeRange, predicate,predicateClause, calls, 0, info,timeRangeDay,aUsers,aQueues,aWraUps,aUserStatusAggregateDay,errors, resolve, reject))
      .catch((response) => reject(response));
    }
  }
  private async checkStatusJob(jobId: string, timeRange : TimeRange, predicate, predicateClause, calls : Object[], retry : number, info,timeRangeDay: TimeRange,aUsers:any, aQueues:any, aWraUps,aUserStatusAggregateDay: Array<any>,errors, resolve, reject) {
    await this.sleep(1000);
    retry++;
    this.PurecloudService.getAnalyticsConversationsDetailsJob(jobId)
    .then((statusResponse) => this.validateStatusJob(statusResponse.state, jobId, timeRange, predicate, predicateClause, calls, retry,info, timeRangeDay,aUsers,aQueues,aWraUps,aUserStatusAggregateDay,  errors,resolve, reject))
    .catch((response) => reject(response));

  }
  private async validateStatusJob(state: string, jobId: string, timeRange : TimeRange, predicate, predicateClause, calls : Object[], retry : number, info, timeRangeDay: TimeRange,aUsers:any,aQueues:any,aWraUps,aUserStatusAggregateDay: Array<any>,errors,resolve, reject) {
    if(!info.cancel) {
      console.info('a: ' + retry);
      if(state === 'FULFILLED') {
        this.Toast.fire({
          title: 'Comenzando...',
          timer: 5000
        });
        console.log("jobID",jobId);
        console.log("predicate",predicate);
        console.log("predicateClause",predicateClause);
          this.PurecloudService.getAnalyticsConversationsDetailsJobResults(jobId)
        .then((resultResponse) => this.processJob(resultResponse.cursor, jobId, resultResponse.conversations, 0, timeRange, predicate, calls,timeRangeDay, aUsers,aQueues,aWraUps, aUserStatusAggregateDay,errors, resolve, reject))
        .catch((response) => reject(response));
      } else {
        if (info.html) {
          info.html.textContent = ': ' + retry
        }
        await this.checkStatusJob(jobId, timeRange, predicate, predicateClause, calls, retry, info,timeRangeDay,aUsers,aQueues,aWraUps,aUserStatusAggregateDay,errors, resolve, reject)
      }
    } else {
      reject('Cancelado');
    }
  }
  private processJob(cursor: string, jobId: string, conversations : platformClient.Models.AnalyticsConversation[], index : number, timeRange : TimeRange, predicate, calls : Object[],timeRangeDay: TimeRange, aUsers:any,aQueues:any,aWraUps, aUserStatusAggregateDay: Array<any>,errors, resolve, reject) {
    console.log("index: ", index, ", length: ", conversations === undefined ? undefined : conversations.length);
    index++;
    if(conversations == undefined || conversations.length == 0) {
      resolve(calls);
    } else {
      timeRange.addCount(conversations.length);
      conversations.forEach((conversation) => this.processInternalCall(conversation, timeRange,calls,timeRangeDay,aUsers,aQueues,aWraUps,aUserStatusAggregateDay));

      this.Toast.fire({
        title: 'Llamadas consideradas ' + calls.length + ' de ' + (1000*(index - 1) + conversations.length),
        timer: 5000
      });

      if(cursor == undefined) {
        resolve(calls);
      } else {
        this.PurecloudService.getAnalyticsConversationsDetailsJobResults(jobId, cursor)
        .then((resultResponse) => this.processJob(resultResponse.cursor, jobId, resultResponse.conversations, index, timeRange, predicate, calls,timeRangeDay,aUsers,aQueues,aWraUps, aUserStatusAggregateDay,errors,resolve, reject))
        .catch((response) => reject(response));
      }
    }
  }
  private sleep(ms:number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
