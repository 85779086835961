import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DownloaderComponent } from './components/downloader/downloader.component';
import { HipotecarioComponent } from './components/hipotecario/calidad/hipotecario.component';
import { HipotecarioServices } from './components/hipotecario/servicios/servicios.component';
import { HipotecarioVentas } from './components/hipotecario/ventas/ventasCCI.component';

const routes: Routes = [
  {
    path: 'download',
    component: DownloaderComponent
  },
  {
    path: 'download/hipotecario',
    component: HipotecarioComponent,
  },
  {
    path: 'download/servicios',
    component: HipotecarioServices,
  },
  {
    path: 'download/ventas',
    component: HipotecarioVentas,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

