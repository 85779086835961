<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark">Descargas</h1>
            </div>
        </div>
    </div>
</div>

<div class="content">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-5 col-sm-2">
                    <div class="nav flex-column nav-tabs h-100" id="vert-tabs-tab" role="tablist" aria-orientation="vertical">
                        <a [hidden]="grupoCalidad !== 'CALIDAD'" class="nav-link" id="vert-tabs-tab" data-toggle="pill" role="tab" aria-controls="vert-tabs" aria-selected="true" routerLink="download/hipotecario">Calidad</a>
                        <a [hidden]="grupoOtros !== 'OTROS'" class="nav-link" id="vert-tabs-tab" data-toggle="pill" role="tab" aria-controls="vert-tabs" aria-selected="true" routerLink="download/servicios">Servicios</a>
                        <a [hidden]="grupoOtros !== 'OTROS'" class="nav-link" id="vert-tabs-tab" data-toggle="pill" role="tab" aria-controls="vert-tabs" aria-selected="true" routerLink="download/ventas">Ventas CCI</a>
                    </div>
                </div>
                <div class="col-7 col-sm-9">
                    <div class="tab-content" id="vert-tabs-tabContent">
                        <div class="tab-pane text-left fade show active" id="vert-tabs" role="tabpanel" aria-labelledby="vert-tabs-tab">
                          <router-outlet></router-outlet>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
