<!-- <div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark">Hipotecario</h1>
            </div>
        </div>
    </div>
</div> -->
<!-- /.content-header -->

<!-- Main content -->
<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <!-- <h5 class="card-title">Hipotecario</h5>
                      generar reportes de customer, ventas, siniestros -->
                        <div class="row">
                            <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                                <div class="form-group">
                                    <p class="card-text">Selecciona un rango:</p>
                                    <div class="center">
                                        <div>
                                            <mat-form-field>
                                                <input matInput [ngxMatDatetimePicker]="pickerFrom_customer"
                                                    placeholder="Desde" [min]="minDate"
                                                    (dateInput)="addEvent('input', $event)"
                                                    [(ngModel)]="myDatePickerFrom_customer">
                                                <mat-datepicker-toggle matSuffix [for]="pickerFrom_customer">
                                                </mat-datepicker-toggle>
                                                <ngx-mat-datetime-picker [defaultTime]="[0,0,0]" #pickerFrom_customer
                                                    [showSeconds]="true">
                                                </ngx-mat-datetime-picker>
                                            </mat-form-field>
                                            <mat-form-field>
                                                <input matInput [ngxMatDatetimePicker]="pickerTo_customer"
                                                    placeholder="Hasta" [max]="maxDate"
                                                    (dateInput)="addEvent('input', $event)"
                                                    [(ngModel)]="myDatePickerTo_customer"
                                                    [min]="myDatePickerFrom_customer">
                                                <mat-datepicker-toggle matSuffix [for]="pickerTo_customer">
                                                </mat-datepicker-toggle>
                                                <ngx-mat-datetime-picker [defaultTime]="[0,0,0]" #pickerTo_customer
                                                    [showSeconds]="true">
                                                </ngx-mat-datetime-picker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- /.form group -->
                            <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                                <div class="form-group">
                                    <p class="card-text">Seleccione Sector:</p>
                                    <div class="center">
                                        <div>
                                            <mat-form-field>
                                                <mat-select name="sector_level" [(ngModel)]="sector_level">
                                                    <!--  -->
                                                    <mat-option (onSelectionChange)="onGeneratecampania($event)"
                                                        *ngFor="let sectors of sector" [value]="sectors.value">{{
                                                        sectors.viewValue }}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                <!-- /.form group -->
                                <div class="form-group">
                                    <p class="card-text">Seleccione Campaña:</p>
                                    <div class="center">
                                        <div>
                                            <div class="container">
                                                <dual-list [source]="campania" key="value" display="viewValue"
                                                    filter="true" [(destination)]="target" height="100px"
                                                    style="width:400px; display:block;"
                                                    (destinationChange)="onGenerateAgente($event)" [format]="format">
                                                </dual-list><!-- (open)="onOpen($event)" -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- /.form group-->
                            </div>
                            <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                <div class="form-group">
                                    <p class="card-text">Seleccione Agente:</p>
                                    <div class="center">
                                        <div>
                                            <div class="container">
                                                <dual-list [source]="agente" key="value" display="viewValue"
                                                    filter="true" [(destination)]="target_agente" height="100px"
                                                    style="width:400px; display:block;"
                                                    (destinationChange)="onCtrlSelectedItems($event,'agente')"
                                                    [format]="format"></dual-list>
                                            </div>
                                            <!--<select class="duallistbox" multiple="multiple"></select>[key]="key" -->
                                        </div>
                                    </div>
                                </div>
                                <!--  /.form group-->
                            </div>
                            <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                                <div class="form-group">
                                    <p class="card-text">Seleccione Resultado:</p>
                                    <div class="center">
                                        <div>
                                            <div class="container">
                                                <dual-list [source]="resultado" key="value" display="viewValue"
                                                    filter="true" [(destination)]="target_resultado" height="100px"
                                                    style="width:400px; display:block;"
                                                    (destinationChange)="onCtrlSelectedItems($event,'resultado')"
                                                    [format]="format"></dual-list><!-- filter="true" -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- /.form group-->
                                <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                                    <div class="form-group">
                                        <p class="card-text">Seleccione Auditor:</p>
                                        <div class="center">
                                            <div>
                                                <div class="container">
                                                    <dual-list [source]="auditor" key="value" display="viewValue"
                                                        filter="true" [(destination)]="target_auditor" height="100px"
                                                        style="width:400px; display:block;"
                                                        (destinationChange)="onCtrlSelectedItems($event,'auditor')"
                                                        [format]="format"></dual-list><!-- filter="true" -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- /.form group-->
                                </div>
                                <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                                    <button
                                        [disabled]="!myDatePickerFrom_customer || !myDatePickerTo_customer || !sector_level"
                                        id="generate-calidad" type="button" class="btn btn-block btn-secondary"
                                        (click)="onGenerateReportesCalidad()">Generar</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <!-- /.content -->