import * as platformClient  from 'purecloud-platform-client-v2';
import { userDetail , primaryPresence , routingStatus }  from '../models/purecloud/userDetail';

export class MapUserDetail {
    public static analyticsUserDetail(analyticsUserDetail : platformClient.Models.AnalyticsUserDetail) : userDetail {
       // console.log(analyticsUserDetail);
      
        var UserDetail = new userDetail();
       
        UserDetail.userId = analyticsUserDetail.userId;
       
        if (analyticsUserDetail.primaryPresence !== undefined){
            UserDetail.primaryPresence = MapUserDetail.analyticsPresences(analyticsUserDetail.primaryPresence);
            
        }
        if (analyticsUserDetail.routingStatus != undefined){
           UserDetail.routingStatus = MapUserDetail.analyticsRoutingsStatus(analyticsUserDetail.routingStatus);
        }
       /*  UserDetail.primaryPresence = (analyticsUserDetail.primaryPresence != undefined) ? MapUserDetail.analyticsPresences(analyticsUserDetail.primaryPresence) : undefined;
        UserDetail.routingStatus = (analyticsUserDetail.routingStatus != undefined) ?  MapUserDetail.analyticsRoutingsStatus(analyticsUserDetail.routingStatus) : undefined;
       */
              
        return UserDetail;
    }

    public static analyticsPresences(analyticsPresences : platformClient.Models.AnalyticsUserPresenceRecord[]) : Array<primaryPresence> {
        var presences = new Array<primaryPresence>();
        analyticsPresences.forEach((analyticsPresence) => presences.push(MapUserDetail.analyticsPresence(analyticsPresence)));       
        return presences;
    }
    
    public static analyticsPresence(analyticsPresence : platformClient.Models.AnalyticsUserPresenceRecord) : primaryPresence {
        var PrimaryPresence = new primaryPresence();
        PrimaryPresence.startTime = analyticsPresence.startTime;
        PrimaryPresence.endTime = analyticsPresence.endTime;
        PrimaryPresence.organizationPresenceId = analyticsPresence.organizationPresenceId;
        PrimaryPresence.systemPresence= analyticsPresence.systemPresence;
        
        return PrimaryPresence;
    }

    
    public static analyticsRoutingsStatus(analyticsRoutingsStatus : platformClient.Models.AnalyticsRoutingStatusRecord[]) : Array<routingStatus> {
        var routingsStatus = new Array<routingStatus>();
        analyticsRoutingsStatus.forEach((analyticsRoutingStatus) => routingsStatus.push(MapUserDetail.analyticsRoutingStatus(analyticsRoutingStatus)));
        return routingsStatus;
    }
    
    public static analyticsRoutingStatus(analyticsRoutingStatus : platformClient.Models.AnalyticsRoutingStatusRecord) : routingStatus {
        var RoutingStatus = new routingStatus();
        RoutingStatus.startTime = analyticsRoutingStatus.startTime;
        RoutingStatus.endTime = analyticsRoutingStatus.endTime;
        RoutingStatus.routingStatus = analyticsRoutingStatus.routingStatus;
        
        return RoutingStatus;
    }
    
    
}