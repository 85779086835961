import { Injectable } from '@angular/core';
import Swal from 'sweetalert2'
import * as platformClient from 'purecloud-platform-client-v2';
import { PurecloudService } from '../../services/purecloud/purecloud.service';
/**estructura de combos */
import { sector } from '../../models/hipotecario/sector';
import { campania } from '../../models/hipotecario/campania';
import { agente } from 'src/app/models/hipotecario/agente';
import { auditor } from 'src/app/models/hipotecario/auditor';
import { resultado } from 'src/app/models/hipotecario/resultado';

@Injectable({
  providedIn: 'root'
})

export class hipotecarioDataService {
  private Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1000
  });

  constructor(private purecloud: PurecloudService) { }

  public convertStringToDate(date: string): Date {
    var year = parseInt(date.substring(0, 4));
    var month = parseInt(date.substring(5, 7)) - 1;
    var day = parseInt(date.substring(8, 10));
    var hours = parseInt(date.substring(11, 13));
    var minutes = parseInt(date.substring(14, 16));
    var seconds = parseInt(date.substring(17, 19));
    var milliseconds = 0;
    if (date.indexOf('.') >= 0)
      milliseconds = parseInt(date.substring(20, date.length - 1));

    return new Date(year, month, day, hours, minutes, seconds, milliseconds);
  }

  /**combo sector */
  generateSync_sector(): Promise<sector[]> {
    let promise = new Promise<sector[]>((resolve, reject) => {
      var calls: sector[] = [];

      this.downSync_sector(0, calls, resolve, reject);
    });
    return promise;
  }
  private downSync_sector(index: number, calls: sector[], resolve, reject): void {
    index++;
    this.purecloud.getQualityFormsEvaluations(index)
      .then((queryResponse) => this.processSync_sector(queryResponse.entities, index, calls, resolve, reject))
      .catch((response) => reject(response));
  }

  private processSync_sector(formEvaluations: platformClient.Models.EvaluationForm[], index: number, calls: sector[], resolve, reject) {
    if (formEvaluations == undefined || formEvaluations.length == 0) {
      resolve(calls);
    } else {
      // console.log(formEvaluations);

      formEvaluations.forEach((formEvaluation) => this.processCall_sector(formEvaluation, calls));
      this.Toast.fire({
        title: 'Llamadas consideradas ' + calls.length + ' de ' + (100 * (index - 1) + formEvaluations.length),
        timer: 1000
      });

      this.downSync_sector(index, calls, resolve, reject);
    }
  }

  private processCall_sector(sector_: platformClient.Models.EvaluationForm, calls: sector[]) {
    //console.log(sector_);
    if (sector_.name.indexOf('Customer') > 0 || sector_.name.indexOf('Siniestros') > 0 || sector_.name.indexOf('Ventas') > 0) {

      var calidad_sector = new sector();
      /*  calidad_sector.viewValue = sector_.name;
      calidad_sector.value = (sector_.name.indexOf('Customer')>0 ? 'Customer' : (sector_.name.indexOf('Siniestros')>0 ? 'Siniestros' : (sector_.name.indexOf('Ventas')>0 ? 'Ventas' : 'Customer')))//sector_.id;        */

      calidad_sector.viewValue = (sector_.name.indexOf('Customer') > 0 ? 'Customer' : (sector_.name.indexOf('Siniestros') > 0 ? 'Siniestros' : 'Ventas'));

      calidad_sector.value = sector_.name;
      calls.push(calidad_sector);
    }
  }



  /* para comobo campaña, que son las colas*/

  generateSync_campania(formName: string): Promise<campania[]> {
    let promise = new Promise<campania[]>((resolve, reject) => {
      var calls: campania[] = [];

      this.downSync_campania(0, formName, calls, resolve, reject);
    });
    return promise;
  }
  private downSync_campania(index: number, formName: string, calls: campania[], resolve, reject): void {
    index++;
    //this.purecloud.getRoutingQueues(undefined,index)
    this.purecloud.getFlowsDatatableRows('c4335df5-6291-422e-9a79-215f3c879b5f', false, index)
      .then((queryResponse) => this.processSync_campania(queryResponse.entities, index, formName, calls, resolve, reject))
      .catch((response) => reject(response));
  }

  private processSync_campania(campanias: { [key: string]: object; }[], index: number, formName: string, calls: campania[], resolve, reject) {
    if (campanias == undefined || campanias.length == 0) {
      resolve(calls);
    } else {

      //campanias.forEach((campania) => this.processCall_campania(campania, calls));
      for (var i = 0; i < campanias.length; i++) {
        //this.processCall_queues(Object.values(campanias[i]), calls, index,resolve,reject);
        this.processCall_queues(campanias[i], calls, index, formName, resolve, reject);
      }

      this.Toast.fire({
        title: 'Campañas consideradas ' + calls.length + ' de ' + (100 * (index - 1) + campanias.length),
        timer: 1000
      });

      this.downSync_campania(index, formName, calls, resolve, reject);
    }
  }

  private processCall_queues(campania_: { [key: string]: object; }, calls: campania[], index: number, formName: string, resolve, reject) {

    let values = Object.values(campania_);
    let filter = values.filter((valor) => { return JSON.parse(JSON.stringify(valor)).includes(formName); });
    //console.log(filter);
    //console.log(JSON.parse(JSON.stringify(values[0])).value.includes(formName));
    //console.log(JSON.parse(JSON.stringify(values[0])).includes(formName));
    if (filter.length > 0) {
      var datosQueue = this.purecloud.getRoutingQueues(JSON.parse(JSON.stringify(values[1])), index)
        .then((datosQueue) => this.processCall_queue(datosQueue.entities, calls, resolve, reject))
        .catch((response) => reject(response));

    }

  }

  private processCall_queue(campania_: platformClient.Models.Queue[], calls: campania[], resolve, reject) {
    //private processCall_campania(campania_ : { [key: string]: object; }, calls : campania[]) {

    var customer_campania = new campania();
    campania_.forEach((queue) => {
      customer_campania.value = queue.id;
      customer_campania.viewValue = queue.name;
      calls.push(customer_campania);
    });

    // console.log(customer_campania);
  }

  /*combo agentes*/

  generateSync_agente(queueId: string): Promise<agente[]> {
    let promise = new Promise<agente[]>((resolve, reject) => {
      var calls: agente[] = [];

      this.downSync_agente(0, queueId, calls, resolve, reject);
    });
    return promise;
  }

  private downSync_agente(index: number, queueId: string, calls: agente[], resolve, reject): void {
    index++;
    this.purecloud.getRoutingQueueMembers(queueId, index)
      //this.purecloud.getRoutingQueues(queueId,index)
      .then((queryResponse) => this.processSync_queue(queryResponse.entities, queueId, index, calls, resolve, reject))
      .catch((response) => reject(response));
  }


  private processSync_queue(queues: platformClient.Models.QueueMember[], queueId: string, index: number, calls: agente[], resolve, reject) {
    if (queues == undefined || queues.length == 0) {
      resolve(calls);
    } else {
      queues.forEach((queue) => this.process_agente(queue, calls));
      /*  for(var i= 0; i< queues.length; i++) {
         console.log(queues[i].id);
         this.process_queue(queues[i].id,index, calls);
       } */
      this.Toast.fire({
        title: 'Llamadas consideradas ' + calls.length + ' de ' + (100 * (index - 1) + queues.length),
        timer: 1000
      });
      //console.log(queueId);
      this.downSync_agente(index, queueId, calls, resolve, reject);
    }
  }
  private process_agente(agente_: platformClient.Models.QueueMember, calls: agente[]) {
    var calidad_agente = new agente();
    calidad_agente.viewValue = agente_.name;
    calidad_agente.value = agente_.id;
    calls.push(calidad_agente);
  }

  /**combo resultado */
  generateSync_resultado(queueId: string): Promise<resultado[]> {
    let promise = new Promise<resultado[]>((resolve, reject) => {
      var calls: resultado[] = [];

      this.downSync_resultado(0, queueId, calls, resolve, reject);
    });
    return promise;
  }

  private downSync_resultado(index: number, queueId: string, calls: resultado[], resolve, reject): void {
    index++;
    this.purecloud.getRoutingQueueWrapupcodes(queueId, index)
      .then((queryResponse) => this.processSync_Wrapupcode(queryResponse.entities, queueId, index, calls, resolve, reject))
      .catch((response) => reject(response));
  }


  private processSync_Wrapupcode(wrapupcodes: platformClient.Models.WrapupCode[], queueId: string, index: number, calls: resultado[], resolve, reject) {
    if (wrapupcodes == undefined || wrapupcodes.length == 0) {
      resolve(calls);
    } else {
      wrapupcodes.forEach((wrapupcode) => this.process_wrapupcode(wrapupcode, calls));

      this.Toast.fire({
        title: 'Llamadas consideradas ' + calls.length + ' de ' + (100 * (index - 1) + wrapupcodes.length),
        timer: 1000
      });

      this.downSync_resultado(index, queueId, calls, resolve, reject);
    }
  }
  private process_wrapupcode(resultado_: platformClient.Models.WrapupCode, calls: resultado[]) {

    var calidad_resultado = new resultado();
    calidad_resultado.viewValue = resultado_.name;
    calidad_resultado.value = resultado_.id;
    calls.push(calidad_resultado);

  }


  /*combo auditor*/
  generateSync_auditor(): Promise<auditor[]> {
    let promise = new Promise<auditor[]>((resolve, reject) => {
      var calls: auditor[] = [];
      this.downSync_auditor(0, calls, resolve, reject);
    });
    return promise;
  }
  private downSync_auditor(index: number, calls: auditor[], resolve, reject): void {
    index++;
    this.purecloud.getUsers(100, index)
      //this.purecloud.getUsers(100,index)
      .then((queryResponse) => this.processSync_auditor(queryResponse.entities, index, calls, resolve, reject))
      .catch((response) => reject(response));
  }

  private processSync_auditor(auditores: platformClient.Models.User[], index: number, calls: auditor[], resolve, reject) {
    if (auditores == undefined || auditores.length == 0) {
      resolve(calls);
    } else {
      auditores.forEach((auditor) => this.processCall_auditor(auditor, calls));
      this.Toast.fire({
        title: 'Auditores ' + calls.length + ' de ' + (100 * (index - 1) + auditores.length),
        timer: 1000
      });
      this.downSync_auditor(index, calls, resolve, reject);
    }
  }

  private processCall_auditor(auditor_: platformClient.Models.User, calls: auditor[]) {

    if (auditor_.authorization === undefined || auditor_.authorization.permissions === undefined) {
      return;
    }

    var hasPermission = false;
    for (let i = 0; i < auditor_.authorization.permissions.length; i++) {
      if (auditor_.authorization.permissions[i].startsWith('quality:evaluation:editScore')) {
        hasPermission = true;
        break;
      }
      if (auditor_.authorization.permissions[i].startsWith('quality:evaluation:add')) {
        hasPermission = true;
        break;
      }
    }

    if (hasPermission) {
      var calidad_auditor = new auditor();
      calidad_auditor.viewValue = auditor_.name;
      calidad_auditor.value = auditor_.id;
      //console.log(customer_campania);
      calls.push(calidad_auditor);
    }
  }

  public validateGroups(groupsIds: string[], groupsAvail: boolean[] ): Promise<boolean[]>{
    return new Promise( (resolve, reject) => {
      this.checkGroups(0, groupsIds, groupsAvail, resolve, reject);
    });
  }

  private checkGroups(index, groupsIds: Array<string>, groupsAvail: boolean[], resolve, reject){
    if( index < groupsIds.length ){
      this.purecloud.getGroupsApi().getGroup( groupsIds[index] )
        .then( (group) => {
          if(group.name === 'Reporteria Calidad Customer Itx') groupsAvail[0] = true; //idName de Customer
          if(group.name === 'Reporteria Calidad Ventas Itx') groupsAvail[1] = true; //idName de Ventas
          if(group.name === 'Reporteria Calidad Siniestros Itx') groupsAvail[2] = true; //idName de Siniestros
          if(group.name === 'Reporteria Calidad Itx') groupsAvail = [true,true,true]; //pasan a ser todos true
          this.checkGroups(index+1, groupsIds, groupsAvail, resolve, reject);
        })
        .catch( (error) => this.thenCatch(error));
    }
    else{
      resolve(groupsAvail);
    }
  }
  private thenCatch(response){
    Swal.fire({
      title: 'Cancelado',
      html: 'Se cancelo la tarea por... <b></b>',
      didOpen: () => {
        Swal.showLoading();
        const content = Swal.getContent();
        if (content) {
          const b = content.querySelector('b');
          if (b) {
            b.textContent = JSON.stringify(response);
          }
        }
      },
      willClose: () => {
        window.location.href = window.location.href
      }
    }).then((result) => {
      console.log(result);
    })
  }
}
