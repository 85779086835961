import { Injectable } from '@angular/core';
import { PurecloudService } from '../purecloud/purecloud.service';
import * as platformClient from 'purecloud-platform-client-v2';

class getUsersOptions implements platformClient.UsersApi.getUsersOptions{
    constructor(public pageSize?: number, public pageNumber?: number) {
    } 
 } 

@Injectable({
  providedIn: 'root'
})

export class UsersSessionsCloudService {

  constructor(private purecloudService : PurecloudService) { }
  
  public getUsers(): Promise<any|Array<any>> {
    return this.purecloudService.checkLogin() || new Promise<Array<any>>((resolve, reject) => {
      this.getUsersInternal(100,1, new Array<any>(), resolve, reject);
    });
  }
  private getUsersInternal(pageSize:number,index: number,users: Array<any>,resolve, reject) {
    this.purecloudService.getUsers(pageSize,index)
      .then((response) => this.thenUsers(response,users, resolve, reject))
      .catch((response) => this.catchUsers(response, reject));
  }

  private thenUsers(response: platformClient.Models.UserEntityListing, users: Array<any>, resolve, reject) {
    response.entities.forEach((user) => users.push({
      id: user.id,
      name: user.name
    }));
    if(response.pageNumber < response.pageCount) {
      this.getUsersInternal(100,response.pageNumber + 1, users, resolve, reject);
    } else {
      resolve(users);
    }
  }
  private catchUsers(response: any, reject) {
    reject(response);
  } 
}
