export class venta_cci {
  aUsers: Array<aUser>;
  aQueues : Array<aQueue>;
  aUsersId: Array<aUserId>;
  aQueuesId: Array<aQueueId>;
  

  agenteId: string;//userId
  agenteName: string;
  agenteUser:string;
  teamLeaderId: string;//userId
  teamLeaderName: string;
 
  //loguin: UserStatusAggregate
  totalOnQueue: number;
  totalOffQueue: number;
  totalIdle: number;
  timeAgentetLogueo:string;
  totalConversations: number;
  totalClientesGestionados: number;
  iTotalInterlocutorValido: number;
  totalInterlocutorValido: string;
  iTotalContactoUtil:number;
  totalContactoUtil:string;
  totalVentas: number;
  totalVolverLlamar: number;
  iTotalSalientes:number;
  iTotalHablado:number;
  totalSalientes: string;
  totalTiempoHablado: string;
  totalReady:string;
  totalNotReady: string;
  totalLlamadosHora:number;
  totalSPH: number;
  totalConversionEfectividad: string;
  totalCUporHora: number;
  totalPP: string;
  aDayTotalAgente:Array<aDayTotalAgente>;
  
}
export class venta_cci_queue{
   //datos por queue: acumulador de RESULTADOS
   timeQueueLogueo:string;
   totalConversations: number;
   totalClientesGestionados: number; 
   totalInterlocutorValido: string;
   totalContactoUtil:string;
   totalVentas: number;
   totalVolverLlamar: number;

   
   totalSalientes: string;
   totalTiempoHablado: string;
   totalReady:string;
   totalNotReady: string;
   totalLlamadosHora:number;
   totalSPH: number;
   totalConversionEfectividad: string;
   totalCUporHora: number;
   totalPP: string;

   aDayTotalQueue:Array<aDayTotalAgente>;

}
export class aUserId {
  id: string;
}
export class aQueueId {
  id: string;
}
export class aUser {
  id: string;
  aTotals: Array<aTotal>;
  aDays: Array<aDays>;
}
export class aQueue {
  aTotals: Array<aTotal>;
  aDays: Array<aDays>;
}
export class aTotal {
  totalLlamados: number;
  clientesGestionados: string;
  contactoInterlocutor: string;
  contactoUtil: number;
  ventas: number;
  volverLlamar : number;
}

export class aDays {
  day: string;
  aTotals: Array<aTotal>;
}

export class aDayTotalAgente {
  day: string;
  aTotales: Array<aTotales>; 
}
export class aTotales {
  totalOnQueue: number;
  totalOffQueue: number;
  totalIdle: number;
  timeLoguin: string;
  totalConversation: number;
  totalClientesGestionados: number;
  iTotalInterlocutorValido: number;
  totalInterlocutorValido: string;
  iTotalContactoUtil:number;
  totalContactoUtil:string;
  totalVentas: number;
  totalVolverLlamar: number;
  iTotalSalientes:number;
  iTotalHablado:number;
  totalSalientes: string;
  totalTiempoHablado: string;
  totalReady:string;
  totalNotReady: string;
  totalLlamadosHora:number;
  totalSPH: number;
  totalConversionEfectividad: string;
  totalCUporHora: number;
  totalPP: string;
}
