import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import Swal from 'sweetalert2';
import * as platformClient from 'purecloud-platform-client-v2';
import { ventasDataService } from '../../../services/ventas/ventas-data.services';
import { campania } from '../../../models/hipotecario/campania';
import { queue } from '../../../models/servicios/queue';
import { agente } from '../../../models/hipotecario/agente';
import { venta_cci } from '../../../models/ventas/venta_cci';
import { venta_cci_queue } from '../../../models/ventas/venta_cci';
import { TimeRange } from '../../../services/purecloud/time-range';

import { PurecloudService } from '../../../services/purecloud/purecloud.service';

import { PredicateDimension, SegmentDetailQueryPredicateDimension } from '../../../constants/purecloud';
import { ConversationsDetailDataService } from '../../../services/ventas/conversations-detail-data.service';
import { ventasExcelService } from '../../../services/ventas/ventas-data.excel';

import * as moment from 'moment';
import { ConversationsDetailSimpleDataService } from 'src/app/services/ventas/conversations-detail-simple-data.service';
import { CloseScrollStrategy } from '@angular/cdk/overlay';
import { getLocaleDayNames } from '@angular/common';
import { Console } from 'console';
import { Models } from 'purecloud-platform-client-v2'; //Agregado

@Component({
  selector: 'app-ventasCCI',
  templateUrl: './ventasCCI.component.html',
  styleUrls: ['./ventasCCI.component.css'],
  encapsulation: ViewEncapsulation.None
})


export class HipotecarioVentas implements OnInit {



  pickerFrom_ventasCCI;
  myDatePickerFrom_ventasCCI: number;
  pickerTo_ventasCCI;
  myDatePickerTo_ventasCCI: number;
  campania_level: string;
  campania: Array<campania> = [];
  format = { add: 'Agregar', remove: 'Borrar', all: 'Todo', none: 'Ninguno', draggable: true };

  queue: Array<queue> = [];
  destino_queue: Array<queue> = [];

  agente: Array<agente> = [];
  destino_agente: Array<agente> = [];
  hoy: Date = new Date();
  ayer: Date = new Date();

  //callsVenta : venta_cci[] = [];

  constructor(private ConversationsDetailDataService: ConversationsDetailSimpleDataService, private ventasDataService: ventasDataService, private VentasExcelService: ventasExcelService, private purecloud: PurecloudService) { }

  ngOnInit(): void {

    this.ayer = new Date(this.ayer.setDate(this.ayer.getDate() - 1));
    this.onGenerateCampania();
  }

  /**combo sectores: formularios de evaluacion -  */
  onGenerateCampania(): void {
    this.ventasDataService.generateSync_campania()
      .then((response) => this.onComboCampania(response))//traigo toas las colas, sin filtrar por sector
      .catch(function (response) {
        Swal.fire({
          title: 'Cancelado',
          html: 'Se cancelo la tarea por... <b></b>',
          didOpen: () => {
            Swal.showLoading();
            const content = Swal.getContent();
            if (content) {
              const b = content.querySelector('b');
              if (b) {
                b.textContent = JSON.stringify(response);
              }
            }
          },
          willClose: () => {
            window.location.href = window.location.href
          }
        }).then((result) => {
          //console.log(result);
        })
      });
  }

  private onComboCampania(response: campania[]): void {
    const filtro = [];
    let result = response.filter((item, index) => {
      if (!filtro.includes(item.value)) {
        filtro.push(item.value);
        return item;
      }
    });
    ////console.log(result);

    this.campania = result;
  }

  /*completar comboQueue()  */
  onGenerateQueue(event): void {   //filtra segun la campaña
    //alert(event);
    if (event.isUserInput) { //controla que funcione el onChnage de forma correcta, sino toma el anterior seleccionado
      //limpiar los combos de campaña, agente y auditor
      this.queue = [];
      this.destino_queue = [];

      this.agente = [];
      this.destino_agente = [];

      //this.callsVenta = [];
      //this.ventas_cciUser = [];
      ////console.log(this.callsVenta);

      // alert(event.source.value);
      let query: queue[] = [];
      this.ventasDataService.generateSync_queue(event.source.value)
        .then((response) => {
          //alert(response);
          query = query.concat(response);
          console.log(query);
          this.onComboQueue(response);
          this.onGenerateAgente(query, undefined);
        })
        .catch(function (response) {
          Swal.fire({
            title: 'Cancelado',
            html: 'Se cancelo la tarea por... <b></b>',
            didOpen: () => {
              Swal.showLoading();
              const content = Swal.getContent();
              if (content) {
                const b = content.querySelector('b');
                if (b) {
                  b.textContent = JSON.stringify(response);
                }
              }
            },
            willClose: () => {
              window.location.href = window.location.href
            }
          }).then((result) => {
            //console.log(result);
          })
        });   //llamar a agentes filtrados por las queue de la campaña seleccionada

    }

  }

  private onComboQueue(response: queue[]): void {
    // //console.log(response);
    this.queue = response;
    ////console.log(this.callsVenta);
    // this.onGenerateAgente(response,undefined);
  }

  /*completar comboAgentes()  */
  onGenerateAgente(response, event): void {

    // //console.log(event);
    if (event !== undefined) {
      //console.log('event');
      if (this.destino_queue.length > 50) {
        Swal.fire({
          title: 'Cancelado',
          html: 'Se cancelo la tarea por... <b>Se supero la cantidad de queues permitidas: 50</b>',
        }).then((result) => {
          //console.log(result);
        })
        return;
      }
      this.agente = [];
      this.destino_agente = [];
      // if (this.onCtrlSelectedItems(event,'queue')){
      event.forEach((queue_) => this.ventasDataService.generateSync_agente(queue_.value)
        .then((response) => this.onComboAgente(response))
        .catch(function (response) {
          Swal.fire({
            title: 'Cancelado',
            html: 'Se cancelo la tarea por... <b></b>',
            didOpen: () => {
              Swal.showLoading();
              const content = Swal.getContent();
              if (content) {
                const b = content.querySelector('b');
                if (b) {
                  b.textContent = JSON.stringify(response);
                }
              }
            },
            willClose: () => {
              window.location.href = window.location.href
            }
          }).then((result) => {
            //console.log(result);
          })
        })
      )
    }
    else {
      /* //console.log('response');*/
      //console.log(response);
      if (this.destino_queue.length > 50) {
        Swal.fire({
          title: 'Cancelado',
          html: 'Se cancelo la tarea por... <b>Se supero la cantidad de queues permitidas: 50</b>',
        }).then((result) => {
          //console.log(result);
        })
        return;
      }
      this.agente = [];
      this.destino_agente = [];
      response.forEach((queue_) => {
        this.ventasDataService.generateSync_agente(queue_.value)
          .then((response) => this.onComboAgente(response))
          .catch(function (response) {
            Swal.fire({
              title: 'Cancelado',
              html: 'Se cancelo la tarea por... <b></b>',
              didOpen: () => {
                Swal.showLoading();
                const content = Swal.getContent();
                if (content) {
                  const b = content.querySelector('b');
                  if (b) {
                    b.textContent = JSON.stringify(response);
                  }
                }
              },
              willClose: () => {
                window.location.href = window.location.href
              }
            }).then((result) => {
              //console.log(result);
            })
          })
      })
      // }
    }
  }

  private onComboAgente(response: agente[]): void {
    ////console.log(response);
    this.agente = this.agente.concat(response);

    let unicos = this.agente.filter((agent) => { return this.agente[agent.value] ? false : (this.agente[agent.value] = true); });
    this.agente = unicos;

    let filter: Array<agente> = [];
    let filter_: Array<agente> = [];
    if (this.destino_agente.length > 0) {
      this.destino_agente.forEach((a) => {
        filter = unicos.filter((valor) => {
          return valor.value.includes(a.value);
        })
      });
    }
    this.destino_agente = filter;
  }

  onCtrlSelectedItems(event, name: string): boolean {
    if (event !== undefined && event.length > 50) {
      Swal.fire(
        'Cancelado Por: ',
        'Seleccionar hasta 50 Items',
        'warning'
      )

      switch (name) {
        case 'queue': this.queue = []; break;
        case 'agente': this.destino_agente = []; break;

      }
      return false;
    }
    else {
      return true;
    }
  }

  async onGenerateReporteVentasCCI() {

    if (this.myDatePickerFrom_ventasCCI == undefined || this.myDatePickerTo_ventasCCI == undefined) {
      return;
    }
    var from = new Date(this.myDatePickerFrom_ventasCCI);
    var to = new Date(this.myDatePickerTo_ventasCCI);

    //adicionar un dia mas a to, para que incluya el dia de to en el reporte
    //to = this.convertStringToDate(moment(moment(to).add(1, 'days')).format('YYYY-MM-DD HH:mm:sss'));

    from.setHours(from.getHours() + 3);
    to.setHours(to.getHours() + 3);

    let campania_level = this.campania_level;
    ////console.log(campania_level);
    var callsVenta: venta_cci[] = [];
    var ventas_cci = new venta_cci();

    let queues = (this.destino_queue.length > 0) ? this.destino_queue : this.queue;
    let q: Array<any> = [];
    queues.forEach(a => q.push(a.value));
    /* //console.log(q);
    ventas_cci.aQueuesId = q; */


    let agentes = (this.destino_agente.length > 0) ? this.destino_agente : this.agente;
    let u: Array<any> = [];
    agentes.forEach(a => u.push(a.value));
    //console.log(u);
    /*  ventas_cci.aUsersId = u;
    callsVenta.push(ventas_cci);  */

    //NO SE PERMITEN MAS DE 100 FILTROS EN UNA CONUSULTA A API
    var iCantFiltrosUsers = Math.ceil(u.length / 100);
    var k = 0; var iBand = 0;
    let aUsers: Array<any> = [];
    while (k < iCantFiltrosUsers && iBand === 0) {
      var ini = k * 100;
      var fin = (u.length - (k + 1) * 100) < 0 ? u.length : ((k + 1) * 100 - 1);
      //obtiene usuarios
      aUsers = aUsers.concat((await this.purecloud.getUsers(100, 1, u.slice(ini, fin))).entities);
      k++;
    }
    //console.log(aUsers);

    //para buscar datos del manager
    //let aUsersComplete : Array<platformClient.Models.User> = [];
    let aUsersComplete = (await this.ventasDataService.generateSync_users());
    ////console.log(aUsersComplete);
    var iCantLlamadas = Math.ceil(aUsers.length / 100);
    let aUserStatusAggregate: Array<any> = [];
    let aUserStatusAggregateDay: Array<any> = [];
    let aConversationAggregate: Array<any> = [];
    let aConversationAggregateDay: Array<any> = [];
    var i = 0; var iBand = 0;
    while (i < iCantLlamadas && iBand === 0) {
      var ini = i * 100;
      var fin = (aUsers.length - (i + 1) * 100) < 0 ? aUsers.length : ((i + 1) * 100 - 1);
      aUserStatusAggregateDay = aUserStatusAggregateDay.concat(await this.purecloud.postAnalyticsUsersAggregatesQuery(from, to, 'P1D', aUsers.slice(ini, fin))
        .then((queryResponse) => { return queryResponse })
        .catch((response) => {
          iBand = 1;
          Swal.fire(
            response.status,
            response.text,
            'error'
          )
        })
      );
      //! Problema del Post aca, le ponemos reintentos
      var conversations = undefined;
      for (var i = 0; i <= 50; i++) {
        conversations = await this.purecloud.postAnalyticsConversationsAggregatesQuery(from, to, 'P1D', aUsers.slice(ini, fin))
          .then((queryResponse) => { return queryResponse })
          .catch((response) => {
            iBand = 1;
            Swal.fire(
              'Servicio no disponible',
              'Reintentos: ' + (i + 1) + ', reintentando...',
              'warning'
              /*
              response.status,
              response.text,
              'error'
            */

            )

          });
        if (conversations !== undefined) {
          break;
        }
      }


      if (conversations !== undefined) {
        aConversationAggregateDay = aConversationAggregateDay.concat(conversations);
      }


      i++;
      ////console.log(aUserStatusObservation);
    }

    //OBTENER LAS wrapup
    let aWraUps = await this.purecloud.getWraUps();

    console.log(aWraUps);

    var predicateClause = {};
    var predicate = {};

    predicateClause[PredicateDimension.segment] = {};
    predicate[PredicateDimension.segment] = {};

    predicateClause[PredicateDimension.segment][SegmentDetailQueryPredicateDimension.userId] = u;
    //predicateClause[PredicateDimension.segment][SegmentDetailQueryPredicateDimension.wrapUpCode] = Object.keys(aWraUps);
    predicate[PredicateDimension.segment][SegmentDetailQueryPredicateDimension.queueId] = q;
    // //console.log(predicateClause);

    // //console.log(predicate);
    /* let aConversationsDetail:Array<any> = (await this.purecloud.postAnalyticsConversationsDetailsQuery(from, to,predicateClause,undefined,1)).conversations;
    //console.log(aConversationsDetail);     */
    console.log("se repetira");
    let aConversationsDetail: Array<any> = await this.ConversationsDetailDataService.generate(from, to, predicateClause, predicate, false, callsVenta, aWraUps, aUserStatusAggregateDay);
    console.log(aConversationsDetail);
    /*//console.log(callsVenta); */

    if (aUsers !== undefined && aUserStatusAggregate !== undefined && aConversationsDetail !== undefined && aConversationsDetail[0] !== undefined) {
      //aqui llama funcion que genera excel
      this.processCalidad_Excel_ventaCCI(campania_level, aUsers, aUsersComplete, aUserStatusAggregateDay, aConversationAggregateDay, aConversationsDetail[0], from, to);

    }
    else {
      Swal.fire({
        title: 'Cancelado',
        html: 'Se cancelo la tarea por... <b></b>',
        didOpen: () => {
          Swal.showLoading();
          const content = Swal.getContent();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = JSON.stringify(aUserStatusAggregate);
            }
          }
        },
        willClose: () => {
          window.location.href = window.location.href
        }
      }).then((result) => {
        //console.log(result);
      })
    }

  }
  /* Procesa datos previo a ingresarlos al Excel
  * campania_level: string
  * aUsers: platformClient.Models.User[] :: usuarios seleccionados
  * aUsersComplete: Array<any> :: all users
  * aUserStatusAggregateDay: Array<any>
  * aConversationAggregateDay: Array<any>
  * aConversationsDetail: Array<any>
  * from: Date
  * to: Date
  */
  private processCalidad_Excel_ventaCCI(campania_level: string, aUsers: platformClient.Models.User[], aUsersComplete: Array<any>, aUserStatusAggregateDay: Array<any>, aConversationAggregateDay: Array<any>, aConversationsDetail: Array<any>, from: Date, to: Date): void {

    from = new Date(from.getTime() - 3 * 60 * 60 * 1000);
    to = new Date(to.getTime() - 3 * 60 * 60 * 1000);

    var aVenta_CCI: Array<venta_cci> = []; //contiene totales generales
    var aVenta_CCI_Queue: Array<venta_cci_queue> = []; //contiene los totales queue
    //hicimos las consultas granuladas por days, entonces debemos ir acumulando el total
    var Venta_CCI_Queue = new venta_cci_queue(); //total suma days


    //esto genera las variables totales para cada usuario sin distinguir por fecha
    this.processUser(aUsers, from, to, aUserStatusAggregateDay, aUsersComplete, aConversationsDetail, aConversationAggregateDay, aVenta_CCI);
    //console.log(aVenta_CCI);

    //calculamos los totales queue por fecha
    this.CalculoTotales(Venta_CCI_Queue, from, to, aVenta_CCI, aVenta_CCI_Queue);
    //console.log(aVenta_CCI_Queue);

    this.VentasExcelService.exportExcel(campania_level, aVenta_CCI, aVenta_CCI_Queue, from, to);
  }


  private CalculoTotales(Venta_CCI_Queue: venta_cci_queue, from: Date, to: Date, aVenta_CCI: venta_cci[], aVenta_CCI_Queue: venta_cci_queue[]) {
    var totalOffQueue = 0;
    var totalQueueLogueo = 0;
    var totalIdle = 0;
    var totalQueueConversations = 0;
    var totalQueueClientesGestionados = 0;
    var iTotalContactoInterlocutor = 0;
    var totalContactoUtil = 0;
    var totalVentas = 0;
    var totalVolverLlamar = 0;
    var totalSalientes = 0;
    var iTotalHablado = 0;
    Venta_CCI_Queue.aDayTotalQueue = [];

    let headerDays = [];
    var timeRangeDays = new TimeRange(from, to, 1);
    while (timeRangeDays.toParcial.getTime() <= to.getTime()) {
      headerDays.push(moment(timeRangeDays.fromParcial).format('YYYY-MM-DD'));
      timeRangeDays.fromParcial = this.convertStringToDate(moment(moment(timeRangeDays.fromParcial).add(1, 'days')).format('YYYY-MM-DD HH:mm:sss'));
      timeRangeDays.toParcial = this.convertStringToDate(moment(moment(timeRangeDays.toParcial).add(1, 'days')).format('YYYY-MM-DD HH:mm:sss'));
      ////console.log(timeRangeDays);
    }
    aVenta_CCI.forEach((totales) => {

      totalOffQueue = totalOffQueue + totales.totalOffQueue;
      totalQueueLogueo = totalQueueLogueo + (totales.totalOnQueue + totales.totalOffQueue);
      totalIdle = totalIdle + totales.totalIdle;
      totalQueueConversations = totalQueueConversations + totales.totalConversations;
      totalQueueClientesGestionados = totalQueueClientesGestionados + totales.totalClientesGestionados;
      iTotalContactoInterlocutor = iTotalContactoInterlocutor + totales.iTotalInterlocutorValido;
      totalContactoUtil = totalContactoUtil + totales.iTotalContactoUtil;
      totalVentas = totalVentas + totales.totalVentas;
      totalVolverLlamar = totalVolverLlamar + totales.totalVolverLlamar;
      totalSalientes = totalSalientes + totales.iTotalSalientes;
      iTotalHablado = iTotalHablado + totales.iTotalHablado;

      //suma todos los aDays
      this.computaTotales(totales, Venta_CCI_Queue);

    });

    // TOTAL POR DIA
    //console.log("Total  por dia sumarizado primer linea");
    //TODO: ver que aca creo que esta sumarizando cuando deberia promediar
    Venta_CCI_Queue.timeQueueLogueo = this.parseMillisecondsIntoReadableTime(totalQueueLogueo);
    Venta_CCI_Queue.totalConversations = totalQueueConversations;
    Venta_CCI_Queue.totalClientesGestionados = totalQueueClientesGestionados;
    var porcentajeCIV = (totalQueueConversations !== 0) ? Math.round((iTotalContactoInterlocutor * 100 / totalQueueConversations) * 100) / 100 : null;
    Venta_CCI_Queue.totalInterlocutorValido = (porcentajeCIV === null) ? '0 %' : porcentajeCIV.toString() + ' %';
    var porcentajeCU = (totalQueueConversations !== 0) ? Math.round((totalContactoUtil / totalQueueConversations) * 100 * 100) / 100 : null;
    Venta_CCI_Queue.totalContactoUtil = (porcentajeCU === null) ? '0 %' : porcentajeCU.toString() + ' %';
    Venta_CCI_Queue.totalVentas = totalVentas;
    Venta_CCI_Queue.totalVolverLlamar = totalVolverLlamar;
    ////console.log(totalSalientes);
    ////console.log(iTotalHablado);
    Venta_CCI_Queue.totalSalientes = (totalSalientes !== 0) ? this.parseMillisecondsIntoReadableTime(iTotalHablado / totalSalientes) : '00:00:00';
    Venta_CCI_Queue.totalTiempoHablado = this.parseMillisecondsIntoReadableTime(iTotalHablado);

    var iReady = (totalQueueLogueo !== 0) ? Math.round((totalIdle / totalQueueLogueo) * 100 * 100) / 100 : null;
    Venta_CCI_Queue.totalReady = (iReady === null) ? '0 %' : iReady.toString() + ' %';
    Venta_CCI_Queue.totalNotReady = this.parseMillisecondsIntoReadableTime(totalOffQueue);

    const duration = moment.duration(Venta_CCI_Queue.timeQueueLogueo);
    var iHorasLoguin_ = totalQueueLogueo / 3600000;
    var iLlamadosHora = (totalQueueLogueo !== 0) ? Math.round(totalQueueConversations * 100 / iHorasLoguin_) / 100 : 0;
    Venta_CCI_Queue.totalLlamadosHora = iLlamadosHora;

    let num_ = (iHorasLoguin_ !== 0) ? totalVentas / iHorasLoguin_ : 0;
    Venta_CCI_Queue.totalSPH = this.trunc(num_, 2);

    var iEfectividad = (iTotalContactoInterlocutor !== 0) ? Math.round((totalVentas * 100 / iTotalContactoInterlocutor) * 100) / 100 : null;
    Venta_CCI_Queue.totalConversionEfectividad = (iEfectividad === null) ? '0 %' : iEfectividad.toString() + '%';


    let iNum_ = (iHorasLoguin_ != 0) ? Math.round((iTotalContactoInterlocutor * 100 / iHorasLoguin_)) / 100 : 0;
    Venta_CCI_Queue.totalCUporHora = iNum_;

    var iPP = (totalQueueClientesGestionados !== 0) ? Math.round((totalVentas * 100 / totalQueueClientesGestionados) * 100) / 100 : null;
    Venta_CCI_Queue.totalPP = (iPP === null) ? '0 %' : iPP.toString() + '%';

    aVenta_CCI_Queue.push(Venta_CCI_Queue);
  }

  /*esto genera las variables totales para cada usuario sin distinguir por fecha
  * aUsers: platformClient.Models.User[] :: lista usuarios seleccionados
  * from: Date
  * to: Date
  * aUserStatusAggregateDay: any[]
  * aUsersComplete: any[] :: all users
  * aConversationsDetail: any[]
  * aConversationAggregateDay: any[]
  * aVenta_CCI: venta_cci[]
  */
  private processUser(aUsers: platformClient.Models.User[], from: Date, to: Date, aUserStatusAggregateDay: any[], aUsersComplete: any[], aConversationsDetail: any[], aConversationAggregateDay: any[], aVenta_CCI: venta_cci[]) {
    aUsers.forEach((user) => {
      this.getDatosPorFecha(from, to, aUserStatusAggregateDay, user, aUsersComplete, aConversationsDetail, aConversationAggregateDay, aVenta_CCI);
    });
  }
  /**
   *
   * @param from
   * @param to
   * @param aUserStatusAggregateDay
   * @param user
   * @param aUsersComplete
   * @param aConversationsDetail
   * @param aConversationAggregateDay
   * @param aVenta_CCI
   */
  private getDatosPorFecha(from: Date, to: Date, aUserStatusAggregateDay: any[], user: platformClient.Models.User, aUsersComplete: any[], aConversationsDetail: any[], aConversationAggregateDay: any[], aVenta_CCI: venta_cci[]) {
    var Venta_CCI = new venta_cci();

    //inicializar las vbles
    var Total_tOnQueue = 0;
    var Total_tOffQueue = 0;
    var Total_tIdle = 0;

    var totalLlamados = 0;
    var clientesGestionados = 0;
    var iContactoInterlocutor = 0;
    var iContactoUtil = 0;
    var iVenta = 0;
    var iVolverLlamar = 0;

    var totalSalientes = 0;
    var totalTiempoHablado = 0;

    var timeRangeDay = new TimeRange(from, to, 1);

    //Genera datos por fecha
    //console.log(timeRangeDay);
    aUserStatusAggregateDay.forEach((usersStatus) => {
      try {
        //? aUserStatusAggregateDay: platformClient.Models.UserAggregateQueryResponse[]
        console.log("por cada usuario");
        usersStatus.results.filter((u) => {
          try {
            if (u.group.userId === user.id) {

              //el usuario existe, traer datos del usuario
              //los agrega en Venta_CCI
              this.getDatosDelUsuario(user, aUsersComplete, Venta_CCI);

              //agrego datos por day en array aDayTotalAgente
              //siempre es offqueue a menos q este en cola: presence: avalaible, away, busy,idle,meal,training, meeting,break
              Venta_CCI.aDayTotalAgente = [];
              ////console.log(u.data);

              var statisticalResponse = {};
              u.data.forEach((m: Models.StatisticalResponse) => {
                var day = m.interval?.substring(0, 10);
                statisticalResponse[day] = m;
              });
              while(timeRangeDay.fromParcial.getTime() < timeRangeDay.to.getTime()) {
                try {
                  var tOnQueue = 0;
                  var tOffQueue = 0;
                  var tIdle = 0;
  
                  var agent = aConversationsDetail[user.id];
                  var parcialLlamados =
                    (agent !== undefined && agent.daily[moment(timeRangeDay.fromParcial).format('YYYY-MM-DD')] !== undefined) ?
                      agent.daily[moment(timeRangeDay.fromParcial).format('YYYY-MM-DD')]
                      :
                      0;
                  if (parcialLlamados !== 0) {
                    var m = statisticalResponse[moment(timeRangeDay.fromParcial).format('YYYY-MM-DD')];
                    m.metrics.forEach((met) => {
                      try {
                        switch (met.qualifier) {
                          case 'AVAILABLE':
                            tOffQueue += met.stats.sum;
                            Total_tOffQueue += met.stats.sum;
                            break;
                          case 'AWAY':
                            tOffQueue += met.stats.sum;
                            Total_tOffQueue += met.stats.sum;
                            break;
                          case 'BUSY':
                            tOffQueue += met.stats.sum;
                            Total_tOffQueue += met.stats.sum;
                            break;
                          case 'ON_QUEUE':
                            tOnQueue = met.stats.sum;
                            Total_tOnQueue += met.stats.sum;
                            break;
                          case 'MEAL':
                            tOffQueue += met.stats.sum;
                            Total_tOffQueue += met.stats.sum;
                            break;
                          case 'TRAINING':
                            tOffQueue += met.stats.sum;
                            Total_tOffQueue += met.stats.sum;
                            break;
                          case 'MEETING':
                            tOffQueue += met.stats.sum;
                            Total_tOffQueue += met.stats.sum;
                            break;
                          case 'BREAK':
                            tOffQueue += met.stats.sum;
                            Total_tOffQueue += met.stats.sum;
                            break;
                          case 'IDLE': //corresponde al idle de routing, por eso no lo tengo en cuenta para off_queue
                            tIdle += met.stats.sum;
                            Total_tIdle += met.stats.sum;
                            break;
                        }
                      } catch (error) {
                        console.error('Error al procesar las metricas: ', met, error);
                      }
                    });
                  }
                  //Venta_CCI.timeAgentetLogueo = this.parseMillisecondsIntoReadableTime(tOnQueue + tOffQueue);
                  //var parcialLlamados = 0; //! Si esta es 0 en el dia X todos los datos de ese dia no deben sumar nada
                  var parcialClientesGestionados = 0;
                  var parcialContactoInterlocutor = '0 %';
                  var iParcialContactoInterlocutor = 0;
                  var iParcialContactoInterlocutor_ = 0;
                  var iParcialContactoUtil = 0;
                  var iParcialContactoUtil_ = 0;
                  var parcialContactoUtil = '0 %';
                  var parcialVenta = 0;
                  var parcialVolverLlamar = 0;
  
                  //var agent = aConversationsDetail[user.id];
                  if (agent !== undefined && parcialLlamados !== 0) {
                    var date = moment(timeRangeDay.fromParcial).format('YYYY-MM-DD');
                    var daily = agent.daily[date];
                    if (daily !== undefined) {
                      totalLlamados += daily.totalLlamados;
                      clientesGestionados += daily.iClientesGestionados;
                      iContactoInterlocutor += daily.iContactoInterlocutor;
                      iContactoUtil += daily.iContactoUtil;
                      iVenta += daily.iVenta;
                      iVolverLlamar += daily.iVolverLlamar;
  
                      parcialLlamados = daily.totalLlamados;
                      parcialClientesGestionados = daily.iClientesGestionados;
                      iParcialContactoInterlocutor = daily.iContactoInterlocutor;
                      iParcialContactoInterlocutor_ = (daily.totalLlamados !== 0) ? Math.round((daily.iContactoInterlocutor * 100 / daily.totalLlamados) * 100) / 100 : null;
                      parcialContactoInterlocutor = (iParcialContactoInterlocutor_ === null) ? '0 %' : iParcialContactoInterlocutor_.toString() + ' %';
                      iParcialContactoUtil = daily.iContactoUtil;
                      iParcialContactoUtil_ = (daily.totalLlamados !== 0) ? Math.round((daily.iContactoUtil * 100 / daily.totalLlamados) * 100) / 100 : null;
                      parcialContactoUtil = (iParcialContactoUtil_ === null) ? '0 %' : iParcialContactoUtil_.toString() + ' %';
                      parcialVenta = daily.iVenta;
                      parcialVolverLlamar = daily.iVolverLlamar;
                    }
                  }
                  //conversationAggregate
                  aConversationAggregateDay.forEach((conversationAgregate) => {
                    try {
                      if (conversationAgregate !== undefined && conversationAgregate.results !== undefined && parcialLlamados !== 0) {
                        conversationAgregate.results.filter((c) => {
                          try {
                            var tSalientes = 0;
                            var tHablado = 0;
                            if (c.group.userId === user.id && c.group.mediaType === 'voice') {
    
                              c.data.forEach((metrica) => {
                                try {
                                  console.log("metrica");
                                  //se mueve por days
                                  if (moment(metrica.interval.substring(0, metrica.interval.indexOf('/'))).format('YYYY-MM-DD') === moment(timeRangeDay.fromParcial).format('YYYY-MM-DD')) {
                                    ////console.log('ingresa');
                                    metrica.metrics.forEach((setDay) => {
                                      try {
                                        console.log("setDay", setDay);
                                        switch (setDay.metric) {
                                          case 'nOutbound':
                                            tSalientes += setDay.stats.count;
                                            totalSalientes += setDay.stats.count;
                                            break;
                                          //tiempo hablado: entrante + saliente
                                          case 'tTalk': tHablado += setDay.stats.sum;
                                            totalTiempoHablado += setDay.stats.sum;
                                            break;
        
                                        }
                                      } catch (error) {
                                        console.error('Error al procesar la metrica del dia para las conversaciones agrupados por dia: ', setDay, error);
                                      }
                                    });
                                  }
                                } catch (error) {
                                  console.error('Error al procesar la metrica de las conversaciones agrupados por dia: ', metrica, error);
                                }
                              });
                              //recien vendria el push
                              //calculamos promedio de salientes en tiempos
                              var iLoguin = tOnQueue + tOffQueue;
                              console.log("iLogin", iLoguin);
                              //console.log("tSalientes",tSalientes);
                              //console.log("tHablado");
                              //console.log(tSalientes / tHablado);
                              var promedioSaliente = (tSalientes !== 0) ? this.parseMillisecondsIntoReadableTime(tHablado / tSalientes) : '';
                              //console.log("promedioSaliente",promedioSaliente);
                              var tLoguin = this.parseMillisecondsIntoReadableTime(iLoguin);
                              //console.log("tLogin",tLoguin);
                              //console.log("tiempo en horas",iLoguin*1000*60*60);
                              var iReady = (iLoguin !== 0) ? Math.round((tIdle * 100 / iLoguin) * 100) / 100 : null;
                              //console.log("tIdle",tIdle);
                              //console.log("iReady",iReady);
                              var tReady = (iReady === null) ? '0 %' : iReady.toString() + ' %';
                              //console.log("tReady",tReady);
                              var tNotReady = this.parseMillisecondsIntoReadableTime(tOffQueue);
                              //console.log("tNotReady",tNotReady);ng
                              const durationAvailable = moment.duration(tLoguin);
                              //console.log("durationAvailable",durationAvailable);
                              var iHorasLoguin = durationAvailable.hours() + 1;
                              //console.log("iHorasLoguin", iHorasLoguin);
                              var tLlamadosPorHora = (iLoguin !== 0) ? Math.ceil(parcialLlamados / iLoguin * 1000 * 60 * 60) : 0;
                              //console.log("parcialLlamados",parcialLlamados);
                              //console.log("tLlamadosPorHora", tLlamadosPorHora);
                              //console.log("parcialVenta",parcialVenta);
                              //console.log("iHorasLoguin");
                              //Porcentaje de llamadas Tipificadas "ventas" / Suma Total de llamadas Tipificadas ("venta" + "no venta" + "volver a llamar argumentado").
                              var iEfec = (iParcialContactoInterlocutor !== 0) ? Math.round((parcialVenta * 100 / iParcialContactoInterlocutor) * 100) / 100 : null;
                              //console.log("iParcialContactoInterlocutor",iParcialContactoInterlocutor);
                              //console.log("parcialVenta",parcialVenta);
                              //console.log("iEfec",iEfec);
                              var tConversionEfectividad = (iEfec === null) ? '0 %' : iEfec.toString() + ' %';
                              //console.log("tConversionEfectividad",tConversionEfectividad);
                              //Cantidad de llamadas que fueron tipificadas "venta" "no venta" y "volver a llamar argumentado" / Total Login
                              var tCUporHora = (iLoguin !== 0) ? this.trunc(iParcialContactoInterlocutor / (iLoguin / 3600000), 2) : 0;
                              //console.log("tCUporHora",tCUporHora);
                              //P-P: Cantidad de llamadas que fueron tipificadas "venta" / Clientes Gestionados.
                              var iPP = (parcialClientesGestionados !== 0) ? Math.round((parcialVenta * 100 / parcialClientesGestionados) * 100) / 100 : null;
                              //console.log("parcialClientesGestionados",parcialClientesGestionados);
                              //console.log("iPP",iPP);
                              var tPP = (iPP === null) ? '0 %' : iPP.toString() + '%';
                              //console.log("tPP",tPP);
                              var tNotReady = this.parseMillisecondsIntoReadableTime(tOffQueue);
                              //console.log("tNotReady",tNotReady);
                              //console.log("tOffQueue",tOffQueue);
                              //console.log("iLogin", iLoguin);
                              //console.log("iLogin", iLoguin / 3600000);
                              //console.log("parcialVenta", parcialVenta);
                              var tSPH = (iLoguin != 0) ? Math.round((parcialVenta * 100) / (iLoguin / 3600000)) / 100 : 0;
                              if (agent !== undefined)
                                console.log('daily aca es: ', agent.daily[moment(timeRangeDay.fromParcial).format('YYYY-MM-DD')], ' totalLlamados: ', parcialLlamados, ' con a pushear \n ', { day: moment(timeRangeDay.fromParcial).format('YYYY-MM-DD'), aTotales: [{ totalOnQueue: tOnQueue, totalOffQueue: tOffQueue, totalIdle: tIdle, timeLoguin: tLoguin, totalConversation: parcialLlamados, totalClientesGestionados: parcialClientesGestionados, iTotalInterlocutorValido: iParcialContactoInterlocutor, totalInterlocutorValido: parcialContactoInterlocutor, iTotalContactoUtil: iParcialContactoUtil, totalContactoUtil: parcialContactoUtil, totalVentas: parcialVenta, totalVolverLlamar: parcialVolverLlamar, iTotalSalientes: tSalientes, iTotalHablado: tHablado, totalSalientes: promedioSaliente, totalTiempoHablado: this.parseMillisecondsIntoReadableTime(tHablado), totalReady: tReady, totalNotReady: tNotReady, totalLlamadosHora: tLlamadosPorHora, totalSPH: tSPH, totalConversionEfectividad: tConversionEfectividad, totalCUporHora: tCUporHora, totalPP: tPP }] });
    
                              Venta_CCI.aDayTotalAgente.push({
                                day: moment(timeRangeDay.fromParcial).format('YYYY-MM-DD'),
                                aTotales: [{
                                  totalOnQueue: tOnQueue,
                                  totalOffQueue: tOffQueue,
                                  totalIdle: tIdle,
                                  timeLoguin: tLoguin,
                                  totalConversation: parcialLlamados,
                                  totalClientesGestionados: parcialClientesGestionados,
                                  iTotalInterlocutorValido: iParcialContactoInterlocutor,
                                  totalInterlocutorValido: parcialContactoInterlocutor,
                                  iTotalContactoUtil: iParcialContactoUtil,
                                  totalContactoUtil: parcialContactoUtil,
                                  totalVentas: parcialVenta,
                                  totalVolverLlamar: parcialVolverLlamar,
                                  iTotalSalientes: tSalientes,
                                  iTotalHablado: tHablado,
                                  totalSalientes: promedioSaliente,
                                  totalTiempoHablado: this.parseMillisecondsIntoReadableTime(tHablado),
                                  totalReady: tReady,
                                  totalNotReady: tNotReady,
                                  totalLlamadosHora: tLlamadosPorHora,
                                  totalSPH: tSPH,
                                  totalConversionEfectividad: tConversionEfectividad,
                                  totalCUporHora: tCUporHora,
                                  totalPP: tPP
                                }]
                              });
    
                            }
                          } catch (error) {
                            console.error('Error al procesar el resultado de las conversaciones agrupados por dia: ', c, error);
                          }
                        });
                      } else {
  
                        var tLoguin = '00:00:00';
                        var tSalientes = 0;
                        var tHablado = 0;
                        var promedioSaliente = '';
                        var tReady = '0 %';
                        var tNotReady = '00:00:00';
                        var tLlamadosPorHora = 0;
                        var tSPH = 0;
                        var tConversionEfectividad = '0 %';
                        var tCUporHora = 0;
                        var tPP = '0 %';
                        if (agent !== undefined) console.log('daily aca es: ', agent.daily[moment(timeRangeDay.fromParcial).format('YYYY-MM-DD')]);
                        Venta_CCI.aDayTotalAgente.push({ day: moment(timeRangeDay.fromParcial).format('YYYY-MM-DD'), aTotales: [{ totalOnQueue: tOnQueue, totalOffQueue: tOffQueue, totalIdle: tIdle, timeLoguin: tLoguin, totalConversation: parcialLlamados, totalClientesGestionados: parcialClientesGestionados, iTotalInterlocutorValido: iParcialContactoInterlocutor, totalInterlocutorValido: parcialContactoInterlocutor, iTotalContactoUtil: iParcialContactoUtil, totalContactoUtil: parcialContactoUtil, totalVentas: parcialVenta, totalVolverLlamar: parcialVolverLlamar, iTotalSalientes: tSalientes, iTotalHablado: tHablado, totalSalientes: promedioSaliente, totalTiempoHablado: this.parseMillisecondsIntoReadableTime(tHablado), totalReady: tReady, totalNotReady: tNotReady, totalLlamadosHora: tLlamadosPorHora, totalSPH: tSPH, totalConversionEfectividad: tConversionEfectividad, totalCUporHora: tCUporHora, totalPP: tPP }] });
                      }
                    } catch (error) {
                      console.error('Error al procesar las conversaciones agrupados por dia: ', conversationAgregate, error);
                    }
                  });
                  timeRangeDay.fromParcial = this.convertStringToDate(moment(moment(timeRangeDay.fromParcial).add(1, 'days')).format('YYYY-MM-DD HH:mm:sss'));
                  timeRangeDay.toParcial = this.convertStringToDate(moment(moment(timeRangeDay.toParcial).add(1, 'days')).format('YYYY-MM-DD HH:mm:sss'));
                } catch (error) {
                  console.error('Error al procesar los datos de estado del usuario: ', m, error);
                }
              }

              //contiene totales generales
              //console.log("comienzo totales generales");
              var iTotalLoguin = Total_tOnQueue + Total_tOffQueue;
              //console.log("iTotalLoguin",iTotalLoguin);
              //console.log("totalLoguin4",iTotalLoguin/3600000);
              Venta_CCI.totalOnQueue = Total_tOnQueue;
              //console.log("Total_tOnQueue",Total_tOnQueue);
              Venta_CCI.totalOffQueue = Total_tOffQueue;
              //console.log("Total_tOffQueue",Total_tOffQueue);
              Venta_CCI.totalIdle = Total_tIdle;
              //console.log("Total_tIdle",Total_tIdle);
              Venta_CCI.timeAgentetLogueo = (Total_tOffQueue !== 0) ? this.parseMillisecondsIntoReadableTime(Total_tOnQueue + Total_tOffQueue) : '00:00:00';
              //console.log("Venta_CCI.timeAgentetLogueo",Venta_CCI.timeAgentetLogueo);
              Venta_CCI.totalConversations = totalLlamados;
              //console.log("totalLlamados",totalLlamados);
              Venta_CCI.totalClientesGestionados = clientesGestionados;
              //console.log("clientesGestionados",clientesGestionados);
              Venta_CCI.iTotalInterlocutorValido = iContactoInterlocutor;
              //console.log("Venta_CCI.iTotalInterlocutorValido",Venta_CCI.iTotalInterlocutorValido);
              var porcentajeTotalCIV = (totalLlamados !== 0) ? Math.round((iContactoInterlocutor * 100 / totalLlamados) * 100) / 100 : null;
              //console.log("porcentajeTotalCIV",porcentajeTotalCIV);
              Venta_CCI.totalInterlocutorValido = (porcentajeTotalCIV === null) ? '0 %' : porcentajeTotalCIV.toString() + ' %';
              //console.log(" Venta_CCI.totalInterlocutorValido", Venta_CCI.totalInterlocutorValido);
              Venta_CCI.iTotalContactoUtil = iContactoUtil;
              //console.log("Venta_CCI.iTotalContactoUtil",Venta_CCI.iTotalContactoUtil);
              var porcentajeTotalCU = (totalLlamados !== 0) ? Math.round((iContactoUtil / totalLlamados) * 100 * 100) / 100 : null;
              //console.log("porcentajeTotalCU",porcentajeTotalCU);
              Venta_CCI.totalContactoUtil = (porcentajeTotalCU === null) ? '0 %' : porcentajeTotalCU.toString() + ' %';
              //console.log("Venta_CCI.totalContactoUtil",Venta_CCI.totalContactoUtil);
              Venta_CCI.totalVentas = iVenta;
              //console.log("Venta_CCI.totalVentas",Venta_CCI.totalVentas);
              Venta_CCI.totalVolverLlamar = iVolverLlamar;
              //console.log("Venta_CCI.totalVolverLlamar",Venta_CCI.totalVolverLlamar);
              Venta_CCI.iTotalSalientes = totalSalientes;
              //console.log("Venta_CCI.iTotalSalientes",Venta_CCI.iTotalSalientes);
              Venta_CCI.totalSalientes = (totalSalientes !== 0) ? this.parseMillisecondsIntoReadableTime(totalTiempoHablado / totalSalientes) : '00:00:00';
              //console.log("Venta_CCI.totalSalientes",Venta_CCI.totalSalientes);
              Venta_CCI.iTotalHablado = totalTiempoHablado;
              //console.log("Venta_CCI.iTotalHablado",Venta_CCI.iTotalHablado);
              Venta_CCI.totalTiempoHablado = this.parseMillisecondsIntoReadableTime(totalTiempoHablado);
              //console.log("Venta_CCI.totalTiempoHablado",Venta_CCI.totalTiempoHablado);
              var iReady = (iTotalLoguin != 0) ? Math.round((Total_tIdle / iTotalLoguin) * 100 * 100) / 100 : null;
              //console.log("iReady",iReady);
              Venta_CCI.totalReady = (iReady === null) ? '0 %' : iReady.toString() + ' %';
              //console.log("Venta_CCI.totalReady ",Venta_CCI.totalReady );
              Venta_CCI.totalNotReady = this.parseMillisecondsIntoReadableTime(Total_tOffQueue);
              //console.log("Venta_CCI.totalNotReady",Venta_CCI.totalNotReady);
              const duration = moment.duration(Venta_CCI.timeAgentetLogueo);
              //console.log("duration",duration);
              var iHorasLoguin_ = duration.hours() + 1;
              //console.log("HorasLoguin_",iHorasLoguin_);
              Venta_CCI.totalLlamadosHora = (iTotalLoguin !== 0) ? Math.round(totalLlamados * 100 / (iTotalLoguin / 3600000)) / 100 : 0;
              //console.log("Venta_CCI.totalLlamadosHora",Venta_CCI.totalLlamadosHora);
              let num = (iTotalLoguin != 0) ? iVenta / (iTotalLoguin / 3600000) : 0;
              //console.log("num",num);
              Venta_CCI.totalSPH = this.trunc(num, 2);
              console.log("Venta_CCI.totalSPH", Venta_CCI.totalSPH);
              var iEfectividad = (iContactoInterlocutor !== 0) ? Math.round((iVenta * 100 / iContactoInterlocutor) * 100) / 100 : null;
              //console.log("iEfectividad",iEfectividad);
              Venta_CCI.totalConversionEfectividad = (iEfectividad === null) ? '0 %' : iEfectividad.toString() + '%';
              //console.log("Venta_CCI.totalConversionEfectividad ",Venta_CCI.totalConversionEfectividad );
              //console.log("iTotalLoguin",iTotalLoguin);
              //console.log("iTotalLoguinHour",iTotalLoguin/3600000);
              let iNum = (iTotalLoguin != 0) ? Venta_CCI.iTotalInterlocutorValido / (iTotalLoguin / 3600000) : 0;
              Venta_CCI.totalCUporHora = this.trunc(iNum, 2);
              //console.log("CU por hora",Venta_CCI.totalCUporHora);
              var iPP = (clientesGestionados !== 0) ? Math.round((iVenta * 100 / clientesGestionados) * 100) / 100 : null;
              Venta_CCI.totalPP = (iPP === null) ? '0 %' : iPP.toString() + '%';

              aVenta_CCI.push(Venta_CCI);

            }
          } catch (error) {
            console.error('Error al procesar el resultado del estado del usuario: ', u, error);
          }
        });
      } catch (error) {
        console.error('Error al procesar el estado del usuario: ', usersStatus, error);
      }
    });
  }

  /* Guarda en Venta_CCI los datos del usuario
  * user: platformClient.Models.User
  * aUsersComplete: any[]
  * Venta_CCI: venta_cci) {
  */
  private getDatosDelUsuario(user: platformClient.Models.User, aUsersComplete: any[], Venta_CCI: venta_cci) {
    if (user.manager != undefined) {

      let datosManager = aUsersComplete.filter(u => { return u.id.includes(user.manager.id); });

      datosManager.forEach(manager => {

        Venta_CCI.agenteId = user.id;
        Venta_CCI.agenteName = user.name;
        Venta_CCI.teamLeaderId = manager.id;
        Venta_CCI.teamLeaderName = manager.name;

      });
    }
    Venta_CCI.agenteId = user.id;
    Venta_CCI.agenteName = user.name;
    Venta_CCI.agenteUser = user.username.substring(0, user.username.indexOf('@'));
  }

  private computaTotales(totales: venta_cci, Venta_CCI_Queue: venta_cci_queue) {
    totales.aDayTotalAgente.forEach((day) => {

      if (Venta_CCI_Queue.aDayTotalQueue.length > 0) {
        //console.log(day.day);
        //let filter_ = headerDays.filter(elemento => {return elemento.includes(Venta_CCI_Queue.aDayTotalQueue[Venta_CCI_Queue.aDayTotalQueue.length - 1].day);});
        //existe el dia en Venta_CCI_Queue.aDayTotalQueue
        let filter_ = Venta_CCI_Queue.aDayTotalQueue.filter(elemento => { return elemento.day.includes(day.day); });
        //console.log(filter_);
        //(moment(day.day).format('YYYY-MM-DD') !== moment(Venta_CCI_Queue.aDayTotalQueue[Venta_CCI_Queue.aDayTotalQueue.length - 1].day).format('YYYY-MM-DD')) &&
        if (filter_.length > 0) {

          // //console.log('existe dia');
          Venta_CCI_Queue.aDayTotalQueue.forEach((elemento) => {
            if (elemento.day === day.day) {
              elemento.aTotales.forEach((d) => {
                day.aTotales.forEach((tot) => {
                  d.totalOnQueue = d.totalOnQueue + tot.totalOnQueue;
                  d.totalOffQueue = d.totalOffQueue + tot.totalOffQueue;
                  d.totalIdle = d.totalIdle + tot.totalIdle;
                  var iTotalLogueo = d.totalOnQueue + d.totalOffQueue;
                  d.timeLoguin = this.parseMillisecondsIntoReadableTime(iTotalLogueo);
                  d.totalConversation = d.totalConversation + tot.totalConversation;
                  d.totalClientesGestionados = d.totalClientesGestionados + tot.totalClientesGestionados;
                  d.iTotalInterlocutorValido = d.iTotalInterlocutorValido + tot.iTotalInterlocutorValido;
                  var iCIV = (d.totalConversation !== 0) ? Math.round((d.iTotalInterlocutorValido * 100 / d.totalConversation) * 100) / 100 : null;
                  d.totalInterlocutorValido = (iCIV === null) ? '0 %' : iCIV.toString() + ' %';
                  d.iTotalContactoUtil = d.iTotalContactoUtil + tot.iTotalContactoUtil;
                  var ICU = (d.totalConversation !== 0) ? Math.round((d.iTotalContactoUtil * 100 / d.totalConversation) * 100) / 100 : null;
                  d.totalContactoUtil = (ICU === null) ? '0 %' : ICU.toString() + ' %';
                  d.totalVentas = d.totalVentas + tot.totalVentas;
                  d.totalVolverLlamar = d.totalVolverLlamar + tot.totalVolverLlamar;
                  d.iTotalSalientes = d.iTotalSalientes + tot.iTotalSalientes;
                  d.iTotalHablado = d.iTotalHablado + tot.iTotalHablado;
                  d.totalSalientes = (d.iTotalSalientes !== 0) ? this.parseMillisecondsIntoReadableTime(d.iTotalHablado / d.iTotalSalientes) : '00:00:00';
                  d.totalTiempoHablado = this.parseMillisecondsIntoReadableTime(d.iTotalHablado);
                  var Ready = (iTotalLogueo !== 0) ? Math.round((d.totalIdle * 100 / iTotalLogueo) * 100) / 100 : null;
                  d.totalReady = (Ready === null) ? '0 %' : Ready.toString() + ' %';
                  d.totalNotReady = this.parseMillisecondsIntoReadableTime(d.totalOffQueue);
                  d.totalLlamadosHora = d.totalLlamadosHora + tot.totalLlamadosHora;
                  //TODO: aca cambiar suma por porcentaje
                  let num1 = (iTotalLogueo != 0) ? Math.round(d.totalVentas * 100 / (iTotalLogueo / 3600000)) / 100 : 0;
                  d.totalSPH = num1;
                  var Efectividad = (d.iTotalInterlocutorValido !== 0) ? Math.round((d.totalVentas * 100 / d.iTotalInterlocutorValido) * 100) / 100 : null;
                  d.totalConversionEfectividad = (Efectividad === null) ? '0 %' : Efectividad.toString() + '%';
                  d.totalCUporHora = d.totalCUporHora + tot.totalCUporHora;
                  var PP = (d.totalClientesGestionados !== 0) ? Math.round((d.totalVentas * 100 / d.totalClientesGestionados) * 100) / 100 : null;
                  d.totalPP = (PP === null) ? '0 %' : PP.toString() + '%';
                  Venta_CCI_Queue.totalPP = (PP === null) ? '0 %' : PP.toString() + '%';
                });
              });
            }
          });
        }
        else {
          ////console.log('no existe dia');
          day.aTotales.forEach((tot) => {
            Venta_CCI_Queue.aDayTotalQueue.push({ day: day.day, aTotales: [{ totalOnQueue: tot.totalOnQueue, totalOffQueue: tot.totalOffQueue, totalIdle: tot.totalIdle, timeLoguin: tot.timeLoguin, totalConversation: tot.totalConversation, totalClientesGestionados: tot.totalClientesGestionados, iTotalInterlocutorValido: tot.iTotalInterlocutorValido, totalInterlocutorValido: tot.totalInterlocutorValido, iTotalContactoUtil: tot.iTotalContactoUtil, totalContactoUtil: tot.totalContactoUtil, totalVentas: tot.totalVentas, totalVolverLlamar: tot.totalVolverLlamar, iTotalSalientes: tot.iTotalSalientes, iTotalHablado: tot.iTotalHablado, totalSalientes: tot.totalSalientes, totalTiempoHablado: tot.totalTiempoHablado, totalReady: tot.totalReady, totalNotReady: tot.totalNotReady, totalLlamadosHora: tot.totalLlamadosHora, totalSPH: tot.totalSPH, totalConversionEfectividad: tot.totalConversionEfectividad, totalCUporHora: tot.totalCUporHora, totalPP: tot.totalPP }] });
          });
        }
      }
      else {
        day.aTotales.forEach((tot) => {
          // //console.log('primer ingreso');
          Venta_CCI_Queue.aDayTotalQueue.push({ day: day.day, aTotales: [{ totalOnQueue: tot.totalOnQueue, totalOffQueue: tot.totalOffQueue, totalIdle: tot.totalIdle, timeLoguin: tot.timeLoguin, totalConversation: tot.totalConversation, totalClientesGestionados: tot.totalClientesGestionados, iTotalInterlocutorValido: tot.iTotalInterlocutorValido, totalInterlocutorValido: tot.totalInterlocutorValido, iTotalContactoUtil: tot.iTotalContactoUtil, totalContactoUtil: tot.totalContactoUtil, totalVentas: tot.totalVentas, totalVolverLlamar: tot.totalVolverLlamar, iTotalSalientes: tot.iTotalSalientes, iTotalHablado: tot.iTotalHablado, totalSalientes: tot.totalSalientes, totalTiempoHablado: tot.totalTiempoHablado, totalReady: tot.totalReady, totalNotReady: tot.totalNotReady, totalLlamadosHora: tot.totalLlamadosHora, totalSPH: tot.totalSPH, totalConversionEfectividad: tot.totalConversionEfectividad, totalCUporHora: tot.totalCUporHora, totalPP: tot.totalPP }] });
        });
      }
    });
  }

  public trunc(x, posiciones = 0) {
    var s = x.toString()
    var l = s.length
    var decimalLength = s.indexOf('.') + 1
    var numStr = s.substr(0, decimalLength + posiciones)
    return Number(numStr)

  }


  public parseMillisecondsIntoReadableTime(milliseconds: number): string {
    //Get hours from milliseconds
    var hours = milliseconds / (1000 * 60 * 60);
    var absoluteHours = Math.floor(hours);
    var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

    //Get remainder from hours and convert to minutes
    var minutes = (hours - absoluteHours) * 60;
    var absoluteMinutes = Math.floor(minutes);
    var m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

    //Get remainder from minutes and convert to seconds
    var seconds = (minutes - absoluteMinutes) * 60;
    var absoluteSeconds = Math.floor(seconds);
    var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;


    return h + ':' + m + ':' + s;
  }

  public convertStringToDate(date: string): Date {
    var year = parseInt(date.substring(0, 4));
    var month = parseInt(date.substring(5, 7)) - 1;
    var day = parseInt(date.substring(8, 10));
    var hours = parseInt(date.substring(11, 13));
    var minutes = parseInt(date.substring(14, 16));
    var seconds = parseInt(date.substring(17, 19));
    var milliseconds = 0;
    if (date.indexOf('.') >= 0)
      milliseconds = parseInt(date.substring(20, date.length - 1));

    return new Date(year, month, day, hours, minutes, seconds, milliseconds);
  }

}
