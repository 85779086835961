<div class="content">
  <div class="container-fluid">
     <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                        <div class="form-group">                        
                            <p class="card-text">Selecciona un rango:</p>
                            <div class="center">
                                <div>
                                    <mat-form-field>
                                        <input matInput [ngxMatDatetimePicker]="pickerFrom_ventasCCI" placeholder="Desde"
                                            [(ngModel)]="myDatePickerFrom_ventasCCI">
                                        <mat-datepicker-toggle matSuffix [for]="pickerFrom_ventasCCI"></mat-datepicker-toggle>
                                        <ngx-mat-datetime-picker [defaultTime]="[0,0,0]" #pickerFrom_ventasCCI [showSeconds]="true">
                                        </ngx-mat-datetime-picker>
                                    </mat-form-field>
                                    <mat-form-field>
                                        <input matInput [ngxMatDatetimePicker]="pickerTo_ventasCCI" placeholder="Hasta"
                                            [(ngModel)]="myDatePickerTo_ventasCCI" [min]="myDatePickerFrom_ventasCCI" [max]= "ayer">
                                        <mat-datepicker-toggle matSuffix [for]="pickerTo_ventasCCI"></mat-datepicker-toggle>
                                        <ngx-mat-datetime-picker [defaultTime]="[0,0,0]" #pickerTo_ventasCCI [showSeconds]="true">
                                        </ngx-mat-datetime-picker>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div> <!-- /.form group -->  
                    </div> <!-- /.cols FECHA-->  
                    <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                        <div class="form-group"> 
                            <p class="card-text">Seleccione Campaña:</p>                    
                                <div class="center">
                                <div>
                                    <mat-form-field>
                                        <mat-select name="campania_level" [(ngModel)]="campania_level"><!--   -->
                                            <mat-option (onSelectionChange)="onGenerateQueue($event)" *ngFor="let campanias of campania" [value]="campanias.value" >{{ campanias.viewValue }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>                             
                                </div>
                                </div>            
                        </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <!-- /.form group -->     
                     <div class="form-group"> 
                        <p class="card-text">Seleccione Queue:</p>
                            <div class="center">
                            <div>                               
                                <div class="container"> 
                                    <dual-list [source]="queue" key="value" display="viewValue" filter="true" (destinationChange)="onGenerateAgente(undefined,$event)" [(destination)]="destino_queue" height="100px" style="width:400px; display:block;" [format]="format"></dual-list><!-- (open)="onOpen($event)" -->
                                 </div>                             
                            </div>
                            </div>            
                    </div>
                    <!-- /.form group--> 
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div class="form-group"> 
                        <p class="card-text">Seleccione Agente:</p>
                            <div class="center">
                            <div>                               
                                <div class="container">
                                    <dual-list [source]="agente" key="value" display="viewValue" filter="true" [(destination)]="destino_agente" height="100px" style="width:400px; display:block;" (destinationChange)="onCtrlSelectedItems($event,'agente')" [format]="format"></dual-list><!-- (destinationChange)="onCtrlSelectedItems($event,'agente')" -->
                                </div>                                                           
                            </div>
                            </div>            
                    </div>
                    <!--  /.form group-->
              </div> 
              <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                <button [disabled]="!myDatePickerFrom_ventasCCI || !myDatePickerTo_ventasCCI || !campania_level" id="generate-ventas" type="button" class="btn btn-block btn-secondary" (click)="onGenerateReporteVentasCCI()"
                >Generar</button>
              </div>
                </div>
            </div>
          </div> 
        </div>
     </div><!-- /.row -->
  </div><!-- /.container-fluid -->
</div><!-- /.content -->