import * as platformClient  from 'purecloud-platform-client-v2';

export class calidad_customer{
    evaluationId: string;
    conversationId : string;

    //fecha
    fecha_llamada: string;
    fecha:string;
    hora:string;
    //fecha gestion
    fecha_auditoria: string;
    fecha_aud : string;
    hora_aud: string;

    //evaluator
    evaluatorId: string;
    evaluator: string;

    //canal
    mediaType: string;

    //dni cliente(puede o no estar)
    dni_cliente: string;
    //asesor - age,nte 
    agenteId: string;
    agente: string

    //ponderaciones
    id_questionGroupPonderaciones: string;
    RegistroActividad: string;
    RegistroActividadId: string;
    errorInfoId:string;
    errorInfo: string;
    ValidacionDatosId: string;
    validacionDatos: string;
    interesResolverId: string;
    interesResolver:string;
    procedimientoRetencionId:string;
    procedimientoRetencion: string;
    saludosIniFinId: string;
    saludosIniFin: string;
    habilidadesComunicacionId: string;
    habilidadesComunicacion:string;
    resumenGestionId:string;
    resumenGestion:string;
    holdId:string;
    hold: string;

    //evaluacion Cross 
    ofertaCross_id:string;
    id_questionGroupCross: string;
    answer_textCross: string;

    //carga en sistema:motivo real del llamado:registro
    cargaSistema_id: string;
    id_motivoLlamado: string;
    motivo_llamado: string;

    //retencion 
    retencion_id:string;
    option_retencion_id: string;
    retencion: string;

    score: number;
    NotaCalidad: string; //si score >= 80 SI sino NO

    segmentType: string;//wrapup
    wrapUpCode: string;//"84eeeceb-7b19-472a-b48f-7861d9c521bb"
    registro:string;

    //observaciones
    //questionGroupScores/questionScores
    questionId:string;
    answerId:string;
    questionScores: number;
    comments: string;

}