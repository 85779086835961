import { Injectable } from '@angular/core';
import { Workbook, Worksheet } from 'exceljs';
import * as fs from 'file-saver';
import { calidad_ventas }  from '../../models/hipotecario/calidad_ventas';

@Injectable({
  providedIn: 'root'
})
export class hipotecarioExcelVentasService {

  constructor() { }
  exportExcel(info : calidad_ventas[],sector_name:string) { 
    
    let workbook = new Workbook(); 
    
    var header = ['CALIDAD_ID', 'AUDITOR', 'CANAL', 'FECHA', 'FECHA VENTA', 'VENDEDOR', 'DNI CLIENTE', 'DATOS DEL PRODUCTO','ID_AUDIO','EVALUACIÓN CROSS','CLAÚSULA DE ACEPTACIÓN DE LA COBERTURA','DENOTA PREEXISTENCIA','INF. ERRÓNEA','OMITE OBJECIONES/NO NEGOCIA','ERROR AL MENCIONAR EL COSTO','ERROR EN LAS PREGUNTAS DE ASEGURABILIDAD/NO INF. CARENCIA','ERROR DE CORROBORACIÓN DE DATOS O EN FRASE POST VALIDACIÓN','ASUME RESPONSABILIDAD DEL SPONSOR','NO INF. NRO. DE TELÉFONO DE HS.','HABILIDADES DE COMUNICACIÓN','ASISTENCIA INCOMPLETA','SCORE','NOTA DE CALIDAD','REGISTRO','SIN ACEPTACIÓN','NO CONFIRMADA','OFERTA ADD ON','OBS. ADICIONAL'];
    var name = 'reporte_calidadVentas_hipotecario';
    let worksheet = workbook.addWorksheet('Calidad Ventas Hipotecario');
    var fila = worksheet.addRow([]);
    fila.height = 30;       
    fila.font = { name: 'Arial', family: 4, size: 10, bold: true};//underline: 'double', 
    fila.getCell('A').value = 'REPORTE CALIDAD VENTAS';
    fila.getCell('A').style.alignment = {horizontal: 'center'};
    fila.getCell('A').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
          argb: '00939b9e'
      } 
    };
   
    fila.getCell('K').value = 'PONDERACIONES VENTAS';
    fila.getCell('K').style.alignment = {horizontal: 'center'};
    fila.getCell('K').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
          argb: '00D3D7D8'
      }
    };

    fila.getCell('V').value = '';
    fila.getCell('V').style.alignment = {horizontal: 'center'};
    fila.getCell('V').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
          argb: '00939b9e'
      }
    };

 
    worksheet.mergeCells('A1:J1');   
    worksheet.mergeCells('K1:U1');  
    worksheet.mergeCells('V1:AB1');
    //worksheet.mergeCells('V1:AA2');
    worksheet.getRow(1).alignment = { horizontal: 'center',vertical: 'justify'};

   
    //fila 2: subtitulos
    var filaEncabezado = worksheet.addRow(header);
    filaEncabezado.height = 50;
    filaEncabezado.font = { name: 'Calibri', family: 4, size: 9, bold: true };
    filaEncabezado.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
          argb: '00D3D7D8'
      }
    };
    worksheet.getRow(2).alignment = { horizontal: 'center' };
   
          
    info.forEach(d => {
      this.addRow(d, worksheet);
     
    });

   
    //calidadId
    worksheet.getColumn(1).width = 40;
    filaEncabezado.getCell(1).alignment = { horizontal: 'center', wrapText: true};
    //auditor
    worksheet.getColumn(2).width = 20;
    filaEncabezado.getCell(2).alignment = { horizontal: 'center', wrapText: true};
    //canal
    worksheet.getColumn(3).width = 40;
    filaEncabezado.getCell(3).alignment = { horizontal: 'center', wrapText: true};
    //fecha
    worksheet.getColumn(4).width = 20;
    filaEncabezado.getCell(4).alignment = { horizontal: 'center', wrapText: true};
    //fecha venta
    worksheet.getColumn(5).width = 20;
    filaEncabezado.getCell(5).alignment = { horizontal: 'center', wrapText: true};
    //vendedor
    worksheet.getColumn(6).width = 20;
    filaEncabezado.getCell(6).alignment = { horizontal: 'center', wrapText: true};
    //dni cliente ()
    worksheet.getColumn(7).width = 10;
    filaEncabezado.getCell(7).alignment = { horizontal: 'center', wrapText: true};
    //datos del producto
    worksheet.getColumn(8).width = 20;  
    filaEncabezado.getCell(8).alignment = { horizontal: 'center', wrapText: true};
    //id audio
    worksheet.getColumn(9).width = 40;
    filaEncabezado.getCell(9).alignment = { horizontal: 'center', wrapText: true};
    //evaluacion cross
    worksheet.getColumn(10).width = 20;
    filaEncabezado.getCell(10).alignment = { horizontal: 'center', wrapText: true};
    //aceptacion de cobertura
    worksheet.getColumn(11).width = 12;
    filaEncabezado.getCell(11).alignment = { horizontal: 'center', wrapText: true};
    //denota preexistencia
    worksheet.getColumn(12).width = 18;
    filaEncabezado.getCell(12).alignment = { horizontal: 'center', wrapText: true};
    //informacion erronea
    worksheet.getColumn(13).width = 15;
    filaEncabezado.getCell(13).alignment = { horizontal: 'center', wrapText: true};
    //omite objeciones
    worksheet.getColumn(14).width = 20;  
    filaEncabezado.getCell(14).alignment = { horizontal: 'center', wrapText: true};
    //error al mencionar el costo
    worksheet.getColumn(15).width = 25;  
    filaEncabezado.getCell(15).alignment = { horizontal: 'center', wrapText: true};
   
    //error en las preguntas de asegurabilidad
    worksheet.getColumn(16).width = 20;  
    filaEncabezado.getCell(16).alignment = { horizontal: 'center', wrapText: true};
    //error de corroboracion de datos
    worksheet.getColumn(17).width = 40;  
    filaEncabezado.getCell(17).alignment = { horizontal: 'center', wrapText: true};
    //asume responsabilidad del sponsor
    worksheet.getColumn(18).width = 25;  
    filaEncabezado.getCell(18).alignment = { horizontal: 'center', wrapText: true};
    //No informa nro. de telefono
    worksheet.getColumn(19).width = 20;  
    filaEncabezado.getCell(19).alignment = { horizontal: 'center', wrapText: true};
    //Habilidades de comunicacion
    worksheet.getColumn(20).width = 20;  
    filaEncabezado.getCell(20).alignment = { horizontal: 'center', wrapText: true};
    //Asistencia incompleta
    worksheet.getColumn(21).width = 20;  
    filaEncabezado.getCell(21).alignment = { horizontal: 'center', wrapText: true};
    //SCORE
    worksheet.getColumn(22).width = 10;  
    filaEncabezado.getCell(22).alignment = { horizontal: 'center', wrapText: true};
    //Nota de calidad
    worksheet.getColumn(23).width = 20;  
    filaEncabezado.getCell(23).alignment = { horizontal: 'center', wrapText: true};
     //Registro
     worksheet.getColumn(24).width = 20;  
     filaEncabezado.getCell(24).alignment = { horizontal: 'center', wrapText: true};
    //Sin aceptacion
    worksheet.getColumn(25).width = 20;  
    filaEncabezado.getCell(25).alignment = { horizontal: 'center', wrapText: true};
    //No confirmada
    worksheet.getColumn(26).width = 20;  
    filaEncabezado.getCell(26).alignment = { horizontal: 'center', wrapText: true};
     //OFERTA ADD ON
     worksheet.getColumn(27).width = 40;  
     filaEncabezado.getCell(27).alignment = { horizontal: 'center', wrapText: true};
     
    //Obs Adicional
    worksheet.getColumn(28).width = 40;  
    filaEncabezado.getCell(28).alignment = { horizontal: 'center', wrapText: true};
    

    workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, name + '.xlsx');
        });
    }

  private addRow(info : calidad_ventas, worksheet: Worksheet) {    
    var row = worksheet.addRow([info.evaluationId,info.evaluator,info.mediaType,info.fechaHora_venta, info.fecha_llamada,info.agente,info.dni_cliente,info.productoText,info.conversationId,info.answer_textCross,info.clausulaAceptacion,info.preexistencia,info.InfoErronea,info.OmiteObj,info.ErrorCosto,info.ErrorEnPreguntas,info.ErrorCorroboracionDatos,info.asumeRespSponsor,info.NoInfTel,info.HabComunicacion,info.AsistenciaInc, info.score,info.NotaCalidad,info.motivo_llamado,info.sinAceptacion,info.noConfirmada,info.ofertaText,info.comments]);
    row.height = 25; 
    row.font = {name: 'Calibri', size: 9};
  }
}
