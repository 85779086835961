import { Injectable } from '@angular/core';
import Swal from 'sweetalert2'
import * as platformClient  from 'purecloud-platform-client-v2';
import { PurecloudService }  from '../purecloud/purecloud.service';

/**estructura de combos */
import { grupo }  from '../../models/servicios/grupo';
import { queue }  from '../../models/servicios/queue';
import { servicio }  from '../../models/servicios/servicio';
import { agente } from 'src/app/models/hipotecario/agente';

/**utilizando moment para manipular fechas */
import * as moment from 'moment';

/**para segmentar las consultas por rango de fechas */
import { ConversationDataPurecloudService } from '../../services/purecloud/conversationDetail/conversation-data-purecloud.service';


@Injectable({
    providedIn: 'root'
  })

//export class serviciosDataService {
export abstract class serviciosDataService extends ConversationDataPurecloudService {

 /*  private Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1000
  }); */

  constructor(private purecloud: PurecloudService) {super(purecloud);}

  /**combo sector */
  generateSync_grupo() : Promise<grupo[]> {
    let promise = new Promise<grupo[]>((resolve, reject) => {
      var calls : grupo[] = [];
      
      this.downSync_grupo(0,calls, resolve, reject);
    });
    return promise;
  }
  private downSync_grupo(index:number,calls : grupo[], resolve, reject) : void {       
    index++;
    this.purecloud.getGroups(index) 
    .then((queryResponse) => this.processSync_grupo(queryResponse.entities, index,calls, resolve, reject))
    .catch((response) => reject(response));
  }

  private processSync_grupo(groups : platformClient.Models.Group[], index: number, calls : grupo[], resolve, reject) {
    if(groups == undefined || groups.length == 0) {
      resolve(calls);
    } else {        
      
      groups.forEach((group) => this.processCall_grupo(group, calls));
       this.Toast.fire({
        title: 'Llamadas consideradas ' + calls.length + ' de ' + (100*(index - 1) + groups.length),
        timer: 1000
      }); 
             
      this.downSync_grupo(index, calls, resolve, reject); 
    }
  }

  private processCall_grupo(group : platformClient.Models.Group, calls : grupo[]) {
     
     var servicio_grupo = new grupo();
    
     servicio_grupo.viewValue = group.name
     servicio_grupo.value = group.id;
     calls.push(servicio_grupo);   
  } 

  /**combo queues .campanias */
  generateSync_queue() : Promise<queue[]> {
    let promise = new Promise<grupo[]>((resolve, reject) => {
      var calls : queue[] = [];
      
      this.downSync_queue(0,calls, resolve, reject);
    });
    return promise;
  }
  private downSync_queue(index:number,calls : queue[], resolve, reject) : void {       
    index++; 
    this.purecloud.getRoutingQueues(undefined,index)
    .then((queryResponse) => this.processSync_queue(queryResponse.entities, index,calls, resolve, reject))
    .catch((response) => reject(response));
  }

  private processSync_queue(queues : platformClient.Models.Queue[], index: number, calls : queue[], resolve, reject) {
    if(queues == undefined || queues.length == 0) {
      resolve(calls);
    } else {        
      //console.log(queues);
      
      queues.forEach((queue_) => this.processCall_queue(queue_, calls));
       this.Toast.fire({
        title: 'Llamadas consideradas ' + calls.length + ' de ' + (100*(index - 1) + queues.length),
        timer: 1000
      }); 
             
      this.downSync_grupo(index, calls, resolve, reject); 
    }
  }

  private processCall_queue(queue_ : platformClient.Models.Queue, calls : queue[]) {
     
     var servicio_queue = new queue();
  
     servicio_queue.viewValue = queue_.name
     servicio_queue.value = queue_.id;
     calls.push(servicio_queue);   
  } 
 
  /**combo agentes 1-consulto los group members:userId 2-consulto user/userId/queue
   * filtro puede ser de grupo o queue
   */
  generateSync_agentes(filtro:string,origenId: number) : Promise<agente[]> {
    let promise = new Promise<agente[]>((resolve, reject) => {
      var calls : agente[] = [];      
      this.downSync_agente(0,filtro,origenId,calls, resolve, reject);
    });
    return promise;
  }

  private downSync_agente(index:number,filtro:string,origenId:number,calls : agente[], resolve, reject) : void {       
    index++;
    //consultar si filtro es por grupo o queue
    switch(origenId){//si es filtro por grupo, estoy pasando grupoId
      case 0:     
          this.purecloud.getGroupMembers(filtro,index)
          .then((queryResponse) => this.processSync_groupMembers(queryResponse.entities, filtro,origenId,index,calls, resolve, reject))
          .catch((response) => reject(response)); 
      break;
      case 1://si el filtro por queue, estoy pasando queueId
        this.purecloud.getRoutingQueueMembers(filtro,index)
        .then((queryResponse) => this.processSync_queueMembers(queryResponse.entities, filtro,origenId,index,calls, resolve, reject))
        .catch((response) => reject(response)); 
      break;
      case 2: //carga los agentes por defecto
        this.purecloud.getUsers(400,index)
        .then((queryResponse) => this.processSync_agentes(queryResponse.entities, null,origenId,index,calls, resolve, reject))
        .catch((response) => reject(response));
      break;
   }
  }
  //filtro por grupo
  private processSync_groupMembers(groupMembers:platformClient.Models.User[], groupId:string,origenId:number,index: number, calls : agente[], resolve, reject) {
    if(groupMembers == undefined || groupMembers.length == 0) {
      resolve(calls);
    } else { 

        for (var i = 0; i < groupMembers.length; i++){         
            //agente_=agente_.concat(this.processCall_groupsUser(groupMembers[i], queues,agente_,calls, index,resolve,reject));  
            this.processCall_groupsUser(groupMembers[i], calls, index,resolve,reject);
        }
      
      this.Toast.fire({
        title: 'Llamadas consideradas ' + calls.length + ' de ' + (100*(index - 1) + groupMembers.length),
        timer: 1000
      });         
     this.downSync_agente(index, groupId,origenId,calls, resolve, reject); 
    }
  }

  private processCall_groupsUser(user:platformClient.Models.User,calls : agente[], index: number,resolve,reject) {
    //sumar los users de las queues seleccionadas(destino_queue)
    
 
    var servicio_agente = new agente();
    servicio_agente.value = user.id;
    servicio_agente.viewValue = user.name;
    calls.push(servicio_agente);  
   
  }
  /**filtrado por queueId */
  private processSync_queueMembers(queueMembers:platformClient.Models.QueueMember[], queueId:string,origenId:number,index: number, calls : agente[], resolve, reject) {
    if(queueMembers == undefined || queueMembers.length == 0) {
      resolve(calls);
    } else {             
        for (var i = 0; i < queueMembers.length; i++){ 
          //agente_=agente_.concat(this.processCall_queueUser(queueMembers[i],agente_,agentes, calls, index,resolve,reject));  
          this.processCall_queueUser(queueMembers[i], calls, index,resolve,reject);
        }
      this.Toast.fire({
        title: 'Agentes de Queue considerados ' + calls.length + ' de ' + (100*(index - 1) + queueMembers.length),
        timer: 1000
      });         
     this.downSync_agente(index, queueId,origenId,calls, resolve, reject); 
    }
  }

  private processCall_queueUser(user:platformClient.Models.QueueMember, calls : agente[], index: number,resolve,reject) {
    //llamar a getusers
    
      var servicio_agente = new agente();  
      servicio_agente.value = user.id;
      servicio_agente.viewValue = user.name;
      calls.push(servicio_agente); 
   }
 
  /**sin filtro */
  private processSync_agentes(users:platformClient.Models.User[],filtro:string,origenId:number,index: number, calls : agente[], resolve, reject) {
    if(users == undefined || users.length == 0) {
      resolve(calls);
    } else {  
      // console.log(this.grupo);           
        for (var i = 0; i < users.length; i++){          
            this.processCall_user(users[i], calls, index,resolve,reject);                  
        }
      this.Toast.fire({
        title: 'Agentes considerados ' + calls.length + ' de ' + (100*(index - 1) + users.length),
        timer: 1000
      });         
     this.downSync_agente(index, filtro,origenId,calls, resolve, reject); 
    }
  }

  private processCall_user(user:platformClient.Models.User, calls : agente[], index: number,resolve,reject) {
    //llamar a getusers
      var servicio_agente = new agente();  
      servicio_agente.value = user.id;
      servicio_agente.viewValue = user.name;
      calls.push(servicio_agente);    
  }

 

//RESULTADOS REPORTE
 /* generateSync_users(idUsers?: Array<any>) : Promise<any> {
  let promise = new Promise<any[]>((resolve, reject) => {   
    this.downSync_users(0,idUsers,resolve, reject);
  });
  return promise;
}
private downSync_users(index:number,users:Array<any>, resolve, reject) : void {       
  index++; 
  this.purecloud.getUsers(100,index,users)
  .then((queryResponse) => this.processSync_users(queryResponse.entities, index, resolve, reject))
  .catch((response) => reject(response));
}

private async processSync_users(users : platformClient.Models.User[], index: number, resolve, reject) {
  if(users == undefined || users.length == 0) {
    resolve(users);
  } else {        
     for(var i= 0; i< users.length; i++) {    
      //await  this.processQueues_customer(campanias_dataTable,conversations[i],conversations[i].evaluations[j].evaluationId,calls,resolve,reject);
      this.Toast.fire({
        title: 'Llamadas consideradas ' + users.length + ' de ' + (100*(index - 1) + users.length),
        timer: 1000
      }); 
    } 
    return users;
    this.Toast.fire({
      title: 'Llamadas consideradas ' + users.length + ' de ' + (100*(index - 1) + users.length),
      timer: 1000
    });
           
    this.downSync_users(index,users,resolve, reject); 
  }
}  */






  public convertStringToDate(date : string) : Date {
    var year = parseInt(date.substring(0, 4));
    var month = parseInt(date.substring(5, 7)) - 1;
    var day = parseInt(date.substring(8, 10));
    var hours = parseInt(date.substring(11, 13));
    var minutes = parseInt(date.substring(14, 16));
    var seconds = parseInt(date.substring(17, 19));
    var milliseconds = 0;
    if(date.indexOf('.') >= 0)
      milliseconds = parseInt(date.substring(20, date.length - 1));
    
    return new Date(year, month, day, hours, minutes, seconds, milliseconds);
  }

}
