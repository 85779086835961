import { Injectable } from '@angular/core';
import { userDetail } from 'src/app/models/purecloud/userDetail';
import { userDetailDataOpenedPurecloudService } from '../purecloud/userDetail/userDetail-data-opened-purecloud.service';
import { PurecloudService } from '../purecloud/purecloud.service';
//import { UsersSessionsCloudService } from '../../services/servicios/users-sessions-cloud.service';

@Injectable({
  providedIn: 'root'
})
export class UsersDetailDataService extends userDetailDataOpenedPurecloudService {

    constructor(private purecloudService : PurecloudService) {
        super(purecloudService);
      }
      
     
      public async processCall(UserDetail : userDetail, calls : Object[]) {
        /* if(calls.length == 0) {
          calls.push({});        
        } */

        var call = new Object();
        call = UserDetail;
        calls.push(call);
       
       // console.log(calls);  
       
      }
    }