import { Injectable } from '@angular/core';
import { Conversation } from 'src/app/models/purecloud/conversation';
import { ConversationDataOpenedPurecloudService } from '../purecloud/conversationDetail/conversation-data-opened-purecloud.service';
import { PurecloudService } from '../purecloud/purecloud.service';
import { UsersSessionsCloudService } from '../../services/servicios/users-sessions-cloud.service';

@Injectable({
  providedIn: 'root'
})
export class UsersSessionsDataService extends ConversationDataOpenedPurecloudService {

    constructor(private purecloudService : PurecloudService,private cloudService : UsersSessionsCloudService) {
        super(purecloudService);
      }
      
     
      public async processCall(conversation : Conversation, calls : Object[]) {
        /* if(calls.length == 0) {
          calls.push({});        
        } */

        var call = new Object();
        call = conversation;
        calls.push(call);
       
        //console.log(calls);  
       
      }
    }