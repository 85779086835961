import { PercentPipe } from "@angular/common";

export class servicio {
   index:number;
   conversationsId:string;
   conversationsStart:string;
   participant_userId:string;

   agenteId: string;//userId
   agenteName: string;
   timeAgentetLogueo:string;
   

   teamLeaderId: string;//userId
   teamLeaderName: string;

   /**datos de estados del agente */
   timeAgenteEnCola: string;
   timeAgenteOcioso: string;
   timeAgenteNoResponde: string;
   timeAgenteFueraCola:string;
   timeAgenteDisponible: string;
   timeAgenteOcupado: string;
   timeAgenteAway: string;
   timeAgenteBreak: string;
   timeAgenteComida: string;
   timeAgenteSystemAway: string;
   timeAgenteReunion: string;
   timeAgenteCapacitacion: string;
   timeAgenteInteractuando: string;
   timeAgenteOffLine: string;

   porcentajeOccupancy: string;

   /**/
   cantAlert :number;
   cantAlertNoAtiende: number;
   cantAtendida: number;
   cantSaliente : number;
   cantManejadas : number;

   timeAgenteTalk: string;
   timeAgenteHold: string;
   timeAgenteACW: string;

   fechaLogiinAgente: string;

   //promedios
   promedioHold: string;
   promedioACW: string;
   promedioTalk: string;
   
   //organizationPresences:subsitemas;
   timeAgenteBanio: string;
   timeAgenteCargaFueraLinea: string;
   timeAgenteProblemasSistema: string;
   timeAgenteLlamadaManual: string;
   timeAgenteBackOffice: string;

   timeMinutes: number;

}