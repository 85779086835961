import * as platformClient  from 'purecloud-platform-client-v2';

export class calidad_ventas{
    evaluationId: string;
    conversationId : string;

    //fecha
    fecha_llamada: string;
    fecha:string;
    hora:string;
    //fecha gestion
    fecha_venta: string;
    fechaHora_venta : string;
    hora_venta: string;

    //evaluator
    evaluatorId: string;
    evaluator: string;

    //canal
    mediaType: string;

    //dni cliente(puede o no estar)
    dni_cliente: string;
    //asesor - age,nte 
    agenteId: string;
    agente: string

    //datos del producto: no en todos los casos aparece
    datosProductoId: string;
    productoquestionId: string;
    productoId: string;
    productoText: string;


    //ponderaciones
    id_questionGroupPonderaciones: string;
    clausulaAceptacion: string;
    clausulaAceptacionId: string;
    preexistencia:string;
    preexistenciaId: string;
    InfoErronea: string;
    InfoErroneaId: string;
    OmiteObj: string;
    OmiteObjId:string;
    ErrorCosto:string;
    ErrorCostoId: string;
    ErrorEnPreguntas: string;
    ErrorEnPreguntasId: string;
    ErrorCorroboracionDatos: string;
    ErrorCorroboracionDatosId:string;
    asumeRespSponsor:string;
    asumeRespSponsorId:string;
    NoInfTel:string;
    NoInfTelId: string;
    HabComunicacion:string;
    HabComunicacionId: string;
    AsistenciaInc:string;
    AsistenciaIncId: string;

    //evaluacion Cross 
    ofertaCross_id:string;
    id_questionGroupCross: string;
    answer_textCross: string;

    //carga en sistema:motivo real del llamado:registro
    cargaSistema_id: string;
    id_motivoLlamado: string;
    motivo_llamado: string;

    //sin aceptacion o no confirmada: form 
    sinAcepNoconf_id:string;
    sinAceptacion_id: string;
    answer_sinAceptacion_id: string;
    sinAceptacion: string;

    //ofertas addon
    ofertaId : string;
    ofertaText : string

    
    noConfirmada_id: string;
    answer_noConfirmada_id: string;
    noConfirmada: string;

    retencion: string;

    score: number;
    NotaCalidad: string; //si score >= 80 SI sino NO

    segmentType: string;//wrapup
    wrapUpCode: string;//"84eeeceb-7b19-472a-b48f-7861d9c521bb"
    registro:string;

    //observaciones
    //questionGroupScores/questionScores
    questionId:string;
    answerId:string;
    questionScores: number;
    comments: string;

}