<!-- Main content -->
<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                            <div class="form-group">                        
                                <p class="card-text">Selecciona un rango:</p>
                                <div class="center">
                                    <div>
                                        <mat-form-field>
                                            <input matInput [ngxMatDatetimePicker]="pickerFrom_serviceCustomer" placeholder="Desde"
                                                [(ngModel)]="myDatePickerFrom_serviceCustomer">
                                            <mat-datepicker-toggle matSuffix [for]="pickerFrom_serviceCustomer"></mat-datepicker-toggle>
                                            <ngx-mat-datetime-picker [defaultTime]="[0,0,0]" #pickerFrom_serviceCustomer [showSeconds]="true">
                                            </ngx-mat-datetime-picker>
                                        </mat-form-field>
                                        <mat-form-field>
                                            <input matInput [ngxMatDatetimePicker]="pickerTo_serviceCustomer" placeholder="Hasta"
                                                [(ngModel)]="myDatePickerTo_serviceCustomer" [min]="myDatePickerFrom_serviceCustomer">
                                            <mat-datepicker-toggle matSuffix [for]="pickerTo_serviceCustomer"></mat-datepicker-toggle>
                                            <ngx-mat-datetime-picker [defaultTime]="[0,0,0]" #pickerTo_serviceCustomer [showSeconds]="true">
                                            </ngx-mat-datetime-picker>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div> <!-- /.form group -->  
                        </div> <!-- /.cols -->  
                                    
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <!-- /.form group -->     
                            <div class="form-group"> 
                                <p class="card-text">Seleccione Grupo:</p>
                                    <div class="center">
                                    <div>                               
                                        <div class="container"> 
                                            <dual-list [source]="grupo" key="value" display="viewValue" filter="true" [(destination)]="destino_grupo" height="100px" style="width:400px; display:block;" (destinationChange)="onGenerateAgente($event,0)" [format]="format"></dual-list><!-- (open)="onOpen($event)" -->
                                        </div>                             
                                    </div>
                                    </div>            
                            </div>
                            <!-- /.form group--> 
                      </div>
                      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div class="form-group"> 
                                <p class="card-text">Seleccione Campaña:</p>
                                    <div class="center">
                                    <div>                               
                                        <div class="container">
                                            <dual-list [source]="queue" key="value" display="viewValue" filter="true" [(destination)]="destino_queue" height="100px" style="width:400px; display:block;" (destinationChange)="onGenerateAgente($event,1)" [format]="format"></dual-list> <!-- (destinationChange)="onCtrlSelectedItems($event,'agente')" -->
                                        </div>
                                        <!--<select class="duallistbox" multiple="multiple"></select>[key]="key" -->                               
                                    </div>
                                    </div>            
                            </div>
                            <!--  /.form group-->
                      </div> 
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="form-group"> 
                            <p class="card-text">Seleccione Agente:</p>
                                <div class="center">
                                <div>                               
                                    <div class="container">
                                        <dual-list [source]="agente" key="value" display="viewValue" filter="true" [(destination)]="destino_agente" height="100px" style="width:400px; display:block;" [format]="format" (destinationChange)="onCtrlSelectedItems($event,'agente')"></dual-list> <!-- (destinationChange)="onCtrlSelectedItems($event,'agente')" -->
                                    </div>
                                    <!--<select class="duallistbox" multiple="multiple"></select>[key]="key" -->                               
                                </div>
                                </div>            
                        </div>
                        <!--  /.form group-->
                  </div> 
                      </div>
                      <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                        <button [disabled]="!myDatePickerFrom_serviceCustomer || !myDatePickerTo_serviceCustomer" id="generate-servicio" type="button" class="btn btn-block btn-secondary"
                        (click)="onGenerateReportesServices()">Generar</button> <!--   -->
                      </div>
                   
                    </div>
                  </div>
               </div>
        </div>
        <!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content -->
