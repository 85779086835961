import { Injectable } from '@angular/core';
import Swal from 'sweetalert2'
import * as platformClient  from 'purecloud-platform-client-v2';
import { PurecloudService }  from '../../services/purecloud/purecloud.service';
import { campania }  from '../../models/hipotecario/campania';
import { queue }  from '../../models/servicios/queue';
import { agente } from 'src/app/models/hipotecario/agente';
import { venta_cci }  from 'src/app/models/ventas/venta_cci';

@Injectable({
    providedIn: 'root'
  })

export class ventasDataService {
    private Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 1000
    });
  
constructor(private purecloud: PurecloudService) {}

/**combo campania */
generateSync_campania() : Promise<campania[]> {
    let promise = new Promise<campania[]>((resolve, reject) => {
        var calls : campania[] = [];        
        this.downSync_campania(0,calls, resolve, reject);
    });
    return promise;
}
private downSync_campania(index:number,calls : campania[], resolve, reject) : void {       
    index++;
    //console.log(index);
     this.purecloud.getFlowsDatatableRows('5697c831-f28a-479a-ae12-65acdb4686a9',false,index) 
    .then((queryResponse) => this.processSync_campania(queryResponse.entities, index,calls, resolve, reject))
    .catch((response) => reject(response)); 
}

private processSync_campania(aCampanias : { [key: string]: object; }[], index: number, calls : campania[], resolve, reject) {
    if(aCampanias == undefined || aCampanias.length == 0) {
        resolve(calls);
    } else {        
      for (var i = 0; i < aCampanias.length; i++){       
         
        this.processCall_campania(aCampanias[i], calls, index,resolve,reject);  
    }   
    this.Toast.fire({
        title: 'Llamadas consideradas - campañas ' + calls.length + ' de ' + (100*(index - 1) + aCampanias.length),
        timer: 1000
    }); 
            
    this.downSync_campania(index, calls, resolve, reject); 
    }
}

private processCall_campania(Campania :{ [key: string]: object; }, calls : campania[], index: number,resolve,reject) {
    var ventas_campania = new campania();
    let values = Object.values(Campania);    
    //console.log(values); 
  
    //console.log(JSON.parse(JSON.stringify(values[0])));
    ventas_campania.value = JSON.parse(JSON.stringify(values[0]));
    ventas_campania.viewValue = JSON.parse(JSON.stringify(values[0]));
    calls.push(ventas_campania);
    //console.log(calls);  
}

//generateSync_queue(campania:string,callsVenta: venta_cci[]) : Promise<queue[]> {
generateSync_queue(campania:string) : Promise<queue[]> {
        let promise = new Promise<queue[]>((resolve, reject) => {
            var calls : queue[] = [];
           /*  var ventas_cci = new venta_cci;
            ventas_cci.aQueues = []; */
            this.downSync_queue(0,campania,calls,resolve, reject);
        });
        return promise;
}
private downSync_queue(index:number,campania:string,calls : queue[],resolve, reject) : void {       
        index++;
        //console.log(index);
        this.purecloud.getFlowsDatatableRows('5697c831-f28a-479a-ae12-65acdb4686a9',false,index) 
        .then((queryResponse) => {
            // console.log(queryResponse.entities);
             this.processSync_queue(queryResponse.entities,campania, index,calls, resolve, reject);
        })
        .catch((response) => reject(response));
}
    
private processSync_queue(aQueues : { [key: string]: object; }[],campania:string, index: number, calls : queue[],resolve, reject) {
        if(aQueues == undefined || aQueues.length == 0) {
         // console.log(calls);
            resolve(calls); 
        } else {      
       // console.log(aQueues);
        for (var i = 0; i < aQueues.length; i++){                   
            this.processCall_queues(aQueues[i], campania,calls, index,resolve,reject);  
        } 
     
        this.Toast.fire({
            title: 'Llamadas consideradas ' + calls.length + ' de ' + (100*(index - 1) + aQueues.length),
            timer: 1000
        }); 
         
        this.downSync_queue(index, campania,calls,resolve, reject); 
       }
 }

 private processCall_queues(Queue :{ [key: string]: object; }, sCampania:string,calls : queue[],index: number,resolve,reject) {
   // console.log(Queue);
    let values = Object.values(Queue); 
    //console.log(values);     
    let filter = values.filter((valor) => {
    /*   console.log(valor); 
      console.log(JSON.parse(JSON.stringify(valor))); 
      console.log(sCampania);  */
      return JSON.parse(JSON.stringify(valor)).includes(sCampania); 
    }); 
  //  console.log(filter);
  
    if (filter.length > 0){
      //let datosQueue = this.purecloud.getRoutingQueues(JSON.parse(JSON.stringify(values[1])),index)
      filter.forEach(element => {
        //let datosQueue = this.purecloud.getRoutingQueues(JSON.parse(JSON.stringify(element)),index)
        this.purecloud.getRoutingQueues(JSON.parse(JSON.stringify(values[1])),index)
        .then((response) => this.processCall_queue(response.entities, calls))
        .catch((response) => reject(response)); 
  
      });
   
   } 
}

private processCall_queue(aQueue : platformClient.Models.Queue[],calls : queue[]) {
     var ventas_queue = new queue();
     //console.log(aQueue);
     for (var i = 0; i < aQueue.length; i++){
      ventas_queue.value = aQueue[i].id;
      ventas_queue.viewValue = aQueue[i].name;
      calls.push(ventas_queue);    
      console.log(ventas_queue);    
    // ventas_cci.push({id:queue.id,name:queue.name});
    }
     
}

/*combo agentes*/

//generateSync_agente(queueId:string,callsVenta: venta_cci[]) : Promise<agente[]> {
generateSync_agente(queueId:string) : Promise<agente[]> {
    let promise = new Promise<agente[]>((resolve, reject) => {
      var calls : agente[] = [];
     /*  var ventas_cci = new venta_cci;
      ventas_cci.aUsers = []; */
     // this.downSync_agente(0,queueId,calls,callsVenta, ventas_cci.aUsers, resolve, reject);
      this.downSync_agente(0,queueId,calls,resolve, reject);
    });
    return promise;
  }

  //private downSync_agente(index:number,queueId:string,calls : agente[],callsVenta : venta_cci[],ventas_cciUser : any,resolve, reject) : void { 
  private downSync_agente(index:number,queueId:string,calls : agente[],resolve, reject) : void {       
    index++; 
    //console.log(index);
    this.purecloud.getRoutingQueueMembers(queueId,index) 
    .then((queryResponse) => this.processSync_queueMember(queryResponse.entities,queueId, index,calls,resolve, reject))
    .catch((response) => reject(response));  
  }
  
  private processSync_queueMember(queueMembers : platformClient.Models.QueueMember[],queueId:string, index: number, calls : agente[], resolve, reject) {
    if(queueMembers == undefined || queueMembers.length == 0) {
      resolve(calls);   
    /*   var ventas_cci1 = new venta_cci();
      ventas_cci1.aUsers = ventas_cciUser;
      callsVenta.push(ventas_cci1); */
   

    } else {                    
       for(var i = 0;i < queueMembers.length; i++){
        this.process_agente(queueMembers[i],calls)
       }
        this.Toast.fire({
        title: 'Llamadas consideradas ' + calls.length + ' de ' + (100*(index - 1) + queueMembers.length),
        timer: 1000
      }); 
      console.log(queueId);       
      this.downSync_agente(index,queueId, calls, resolve, reject); 
    }
  }
  private process_agente(agente_:platformClient.Models.QueueMember,calls : agente[]){
    var ventas_agente = new agente();
    var agent = agente_;
    ventas_agente.viewValue = agente_.name;
    ventas_agente.value = agente_.id; 
    calls.push(ventas_agente);
 
//    ventas_cciUser.push({id:agent.id,name:agent.name});
  
  }

  //Nos aseguramos de obtener todos los usuarios
generateSync_users() : Promise<platformClient.Models.User[]> {
  let promise = new Promise<platformClient.Models.User[]>((resolve, reject) => {   
    var aUsers : Array<any> = [];
    this.downSync_users(0,aUsers,resolve, reject);
  });
  return promise;
}
private downSync_users(index:number,aUsers:Array<any>, resolve, reject) : void {       
  index++; 
  this.purecloud.getUsers(100,index)
  .then((queryResponse) => this.processSync_users(queryResponse.entities,aUsers, index, resolve, reject))
  .catch((response) => reject(response));
}

private async processSync_users(users : platformClient.Models.User[], aUsers:Array<any>,index: number, resolve, reject) {
  if(users === undefined || users.length === 0) {
    resolve(aUsers);
  } else {        
    aUsers = aUsers.concat(users);
    this.Toast.fire({
      title: 'Llamadas consideradas ' + users.length + ' de ' + (100*(index - 1) + users.length),
      timer: 1000
    });             
    this.downSync_users(index,aUsers,resolve, reject);
    //return aUsers;  
  }
}

/* generateSync_ventaCCI(from: Date, to: Date, predicateClause): Promise<venta_cci[]>{//queue: Array<queue>,agentes: Array<agente>
  let promise = new Promise<venta_cci[]>((resolve, reject) => {
    var calls : venta_cci[] = [];
    let aUsersAggregatesQuery : Array<any> = [];

    this.downSync_ventaCCI(0, from, to,predicateClause,calls, resolve, reject);
  });
  return promise;

}

private downSync_ventaCCI(index:number,from: Date, to: Date,predicateClause,calls : venta_cci[], resolve, reject) : void {       
  index++;
   this.purecloud.postAnalyticsConversationsDetailsQuery(from, to,predicateClause,undefined,index)
  .then((queryResponse) => this.processSync_conversations(queryResponse.conversations, index,from,to,predicateClause,calls, resolve, reject))
  .catch((response) => reject(response));
}

private async processSync_conversations(aConversations : platformClient.Models.AnalyticsConversationWithoutAttributes[], index: number,from: Date, to: Date, predicateClause,calls : venta_cci[], resolve, reject) {//queue: Array<queue>,agentes: Array<agente>,aUsersAggregatesQuery : Array<any>,
  if(aConversations == undefined || aConversations.length == 0) {
    resolve(calls);
  } else {        
       
   
    this.Toast.fire({
      title: 'Llamadas consideradas ' + calls.length + ' de ' + (100*(index - 1) + aConversations.length),
      timer: 1000
    }); 
   
           
    this.downSync_ventaCCI(index, from,to,predicateClause,calls, resolve, reject); 
  }
} */


}