import { Injectable } from '@angular/core';
import Swal from 'sweetalert2'
import * as platformClient from 'purecloud-platform-client-v2';
import { PurecloudService } from '../../services/purecloud/purecloud.service';
/**estructura de combos */
import { campania } from '../../models/hipotecario/campania';
import { agente } from 'src/app/models/hipotecario/agente';
import { auditor } from 'src/app/models/hipotecario/auditor';
import { resultado } from 'src/app/models/hipotecario/resultado';

/**Models de reportes */
import { calidad_siniestros } from 'src/app/models/hipotecario/calidad_siniestros';

@Injectable({
  providedIn: 'root'
})
export class hipotecarioDataSiniestrosService {
  private Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1000
  });

  constructor(private purecloud: PurecloudService) {
  }

  /**
   * Obtener datos reporte calidad de ventas
   * @param from
   * @param to
   * @param sector
   * @param campanias
   * @param campanias_dataTable
   * @param agentes
   * @param resultados
   * @param auditores
   * @returns
   */
  generateSync_siniestros(from: Date, to: Date, sector: string, campanias: Array<campania>, campanias_dataTable: Array<campania>, agentes: Array<agente>, resultados: Array<resultado>, auditores: Array<auditor>): Promise<calidad_siniestros[]> {

    let promise = new Promise<calidad_siniestros[]>((resolve, reject) => {
      var calls: calidad_siniestros[] = [];
      this.downSync_siniestros(0, from, to, sector, campanias, campanias_dataTable, agentes, resultados, auditores, calls, resolve, reject);
    });
    return promise;
  }
  private downSync_siniestros(index: number, from: Date, to: Date, sector: string, campanias: Array<campania>, campanias_dataTable: Array<campania>, agentes: Array<agente>, resultados: Array<resultado>, auditores: Array<auditor>, calls: calidad_siniestros[], resolve, reject): void {
    index++;
    this.purecloud.postAnalyticsConversationsDetailsQuery_calidad(from, to, campanias, agentes, resultados, auditores, campanias_dataTable, index)
      .then((queryResponse) => this.processSync_siniestros(queryResponse.conversations, index, from, to, sector, campanias, campanias_dataTable, agentes, resultados, auditores, calls, resolve, reject))
      .catch((response) => {
        //reject(response);
        Swal.fire(
          response.status,
          response.text,
          'error'
        )
      }
      );
  }

  /**
   *
   * @param conversations
   * @param index
   * @param from
   * @param to
   * @param sector
   * @param campanias
   * @param campanias_dataTable
   * @param agentes
   * @param resultados
   * @param auditores
   * @param calls
   * @param resolve
   * @param reject
   */
  private async processSync_siniestros(conversations: platformClient.Models.AnalyticsConversationWithoutAttributes[], index: number, from: Date, to: Date, sector: string, campanias: Array<campania>, campanias_dataTable: Array<campania>, agentes: Array<agente>, resultados: Array<resultado>, auditores: Array<auditor>, calls: calidad_siniestros[], resolve, reject) {
    if (conversations == undefined || conversations.length == 0) {
      resolve(calls);
    } else {
      for (var i = 0; i < conversations.length; i++) {
        this.Toast.fire({
          title: 'Llamadas consideradas ' + calls.length + ' de ' + (100 * (index - 1) + conversations.length),
          timer: 1000
        });
        for (var j = 0; j < conversations[i].evaluations.length; j++) {
          /*  var containQueue = false;
           for(var k= 0; k< campanias_dataTable.length; k++){
               if (conversations[i].evaluations[j].queueId === campanias_dataTable[k].value){
                 console.log(conversations[i].evaluations[j].queueId);
                 console.log(campanias_dataTable[k].value);
                 containQueue = true;
               }

           } */

          if (conversations[i].evaluations[j].deleted === undefined && conversations[i].evaluations[j].formName.indexOf(sector) > 0) {
            await this.processQueues_siniestros(campanias_dataTable, conversations[i], conversations[i].evaluations[j].evaluationId, calls, resolve, reject);
          }
        }
      }
      this.downSync_siniestros(index, from, to, sector, campanias, campanias_dataTable, agentes, resultados, auditores, calls, resolve, reject);
    }
  }

  private async processQueues_siniestros(campanias: Array<campania>, conversation: platformClient.Models.AnalyticsConversationWithoutAttributes, evaluationId: string, calls: calidad_siniestros[], resolve, reject) {

    var queryResponse = await this.purecloud.getQualityConversationEvaluation(conversation.conversationId, evaluationId);
    this.process_ConversationEvaluation_siniestros(campanias, queryResponse, conversation, calls);
  }

  private process_ConversationEvaluation_siniestros(campanias: Array<campania>, evaluation: platformClient.Models.Evaluation, conversation: platformClient.Models.AnalyticsConversationWithoutAttributes, calls: calidad_siniestros[]) {
    if (evaluation.status === 'FINISHED') {
      var call = new calidad_siniestros();
      call.evaluationId = evaluation.id;
      call.conversationId = conversation.conversationId;
      var start = this.convertStringToDate(conversation.conversationStart);
      start.setHours(start.getHours() - 3);
      call.fecha_aud = this.formatDate(start);

      call.hora_aud = this.formatHour(start);
      call.fecha_auditoria = call.fecha_aud + ' ' + call.hora_aud;

      var fecha = (evaluation.releaseDate === undefined) ? this.convertStringToDate(evaluation.changedDate) : this.convertStringToDate(evaluation.releaseDate);
      fecha.setHours(fecha.getHours() - 3);
      call.fecha = this.formatDate(fecha);
      call.hora = this.formatHour(fecha);
      call.fecha_llamada = call.fecha + ' ' + call.hora;


      this.process_evaluator_siniestros(evaluation.evaluator, call);
      this.process_dniCliente(conversation.participants, call);
      this.process_canal(campanias, conversation.evaluations, call);
      this.process_vendedor_siniestros(evaluation.agent, call);
      this.process_EvaluationForm_siniestros(evaluation.evaluationForm, evaluation.answers, call);

      calls.push(call);
      /*  console.log('calls');
       console.log(calls); */
    }
  }

  private process_evaluator_siniestros(evaluator: platformClient.Models.User, call: calidad_siniestros) {
    call.evaluatorId = evaluator.id;
    call.evaluator = evaluator.name;
  }

  private process_dniCliente(participants: platformClient.Models.AnalyticsParticipantWithoutAttributes[], call: calidad_siniestros) {
    participants.forEach((participant) => {
      participant.sessions.forEach((session) => {
        session.segments.forEach((segment) => {
          if (segment.properties != undefined) {
            segment.properties.forEach((property) => {
              if (property.property === 'DNI_cliente') {
                call.dni_cliente = property.value;
              }
            })
          }
          if (segment.segmentType === 'wrapup') {
            call.wrapUpCode = segment.wrapUpCode;//llamar api
          }
        })
      })
    })
  }

  private process_canal(campanias: Array<campania>, evaluations: platformClient.Models.AnalyticsEvaluation[], call: calidad_siniestros) {
    for (var i = 0; i < evaluations.length; i++) {
      if (evaluations[i].deleted === undefined && evaluations[i].queueId !== undefined) {
        /*  for (var j = 0; j < campanias.length; j++) {
           if (campanias[j].value === evaluations[i].queueId)
           {call.mediaType = campanias[j].viewValue;}
         } */

        this.purecloud.getRoutingQueue(evaluations[i].queueId)
          .then((queryResponse) => { console.log('queueName -Canal' + queryResponse.name); call.mediaType = queryResponse.name })
          .catch((response) => console.log(response));
      }
    }
  }

  private process_vendedor_siniestros(agente: platformClient.Models.User, call: calidad_siniestros) {
    call.agenteId = agente.id;
    call.agente = agente.name;
  }


  private process_EvaluationForm_siniestros(evaluationForm: platformClient.Models.EvaluationForm, answers: platformClient.Models.EvaluationScoringSet, call: calidad_siniestros) {
    evaluationForm.questionGroups.forEach((questionGroup) => {
      switch (questionGroup.name) {
        case 'Ponderaciones Siniestros':
          call.id_questionGroupPonderaciones = questionGroup.id;
          questionGroup.questions.forEach((question) => {
            console.log('question ' + question.text);
            switch (question.text) {
              case 'Falta o error en el registro:':
                question.answerOptions.forEach((answer) => {
                  if (answer.id === this.process_answer_(question.id, answers, questionGroup.id, call)) {
                    call.errorRegistroId = answer.id;
                    call.errorRegistro = answer.text;

                  }
                })
                break;
              case 'Cierre obligatorio / Adelanto de documentación:':
                question.answerOptions.forEach((answer) => {
                  if (answer.id === this.process_answer_(question.id, answers, questionGroup.id, call)) {
                    call.cierreObligatorioId = answer.id;
                    call.cierreObligatorio = answer.text;

                  }

                })
                break;
              case 'Verificación de Verbal ID:':
                question.answerOptions.forEach((answer) => {
                  if (answer.id === this.process_answer_(question.id, answers, questionGroup.id, call)) {
                    call.verificacionVerbalId = answer.id;
                    call.verificacionVerbal = answer.text;

                  }
                })
                break;
              case 'Correcta información de tiempos y procedimientos:':
                question.answerOptions.forEach((answer) => {
                  if (answer.id === this.process_answer_(question.id, answers, questionGroup.id, call)) {
                    call.correctaInfoId = answer.id;
                    call.correctaInfo = answer.text;
                  }
                })
                break;
              case 'Habilidades de comunicación:':
                question.answerOptions.forEach((answer) => {
                  if (answer.id === this.process_answer_(question.id, answers, questionGroup.id, call)) {
                    call.habilidadesComunicacionId = answer.id;
                    call.habilidadesComunicacion = answer.text;
                  }

                })
                break;
              case 'Saludo inicial / Saludo final:':
                question.answerOptions.forEach((answer) => {
                  if (answer.id === this.process_answer_(question.id, answers, questionGroup.id, call)) {
                    call.saludoIniFinId = answer.id;
                    call.saludoIniFin = answer.text;
                  }
                })
                break;
              case 'Hold:':
                question.answerOptions.forEach((answer) => {
                  if (answer.id === this.process_answer_(question.id, answers, questionGroup.id, call)) {
                    call.holdId = answer.id;
                    call.hold = answer.text;
                  }
                })
                break;
            }
          })
          call.comments = this.process_comments(answers, '', questionGroup.id, call);
          break;
          case 'Resolución de la denuncia:':
            call.id_questionGroupDenuncia = questionGroup.id;
            questionGroup.questions.forEach((questionCross) => {
              console.log("questionCross.text",questionCross.text);
              if (questionCross.text === 'Resolución de la denuncia:') {
                questionCross.answerOptions.forEach((answerCross) => {
                  if (answerCross.id === this.process_answer_(questionCross.id, answers, questionGroup.id, call)) {
                    call.denunciaId = answerCross.id;
                    call.denuncia = answerCross.text;

                  }
                })
              }
            })
            break;
        case 'Resolución de la llamada:':
          call.id_questionGroupDenuncia = questionGroup.id;
          questionGroup.questions.forEach((questionCross) => {
            console.log("questionCross.text",questionCross.text);
          if (questionCross.text === 'Resolución de la llamada:') {
              questionCross.answerOptions.forEach((answerCross) => {
                console.log("answerCross.id",answerCross.id);
                console.log("answerCross.text",answerCross.text);
                console.log("answerCross.value",answerCross.value);
                console.log("process_answer"+this.process_answer_(questionCross.id, answers, questionGroup.id, call));
                if (answerCross.id === this.process_answer_(questionCross.id, answers, questionGroup.id, call)) {
                  call.denunciaId = answerCross.id;
                  call.denuncia = answerCross.text;

                }
              })
            }
          })
          break;
      }
    })
  }

  private process_answer_(questionId_: string, answers: platformClient.Models.EvaluationScoringSet, questionGroupId: string, call: calidad_siniestros): string {
    var answerId = '';
    call.score = Math.floor(answers.totalScore);
    answers.questionGroupScores.forEach((questionGroupScore) => {
      if (questionGroupId === questionGroupScore.questionGroupId) {
        // call.score = questionGroupScore.totalCriticalScore;
        call.NotaCalidad = 'NO APROBADO';
        if (answers.totalScore >= 80)
          call.NotaCalidad = 'APROBADO';
        questionGroupScore.questionScores.forEach((question) => {
          if (question.questionId === questionId_) {
            answerId = question.answerId;

          }
        })
      }
    })
    return answerId;
  }
  private process_comments(answers: platformClient.Models.EvaluationScoringSet, answerId: string, questionGroupId: string, call: calidad_siniestros): string {
    var comments = '';
    answers.questionGroupScores.forEach((questionGroupScore) => {
      if (questionGroupId === questionGroupScore.questionGroupId) {
        questionGroupScore.questionScores.forEach((question) => {
          // if(questionId_ = question.questionId)
          comments += question.comments + ' ';
          // });
        })
      }
    });
    comments += answers.comments;
    return comments;
  }


  public convertStringToDate(date: string): Date {
    var year = parseInt(date.substring(0, 4));
    var month = parseInt(date.substring(5, 7)) - 1;
    var day = parseInt(date.substring(8, 10));
    var hours = parseInt(date.substring(11, 13));
    var minutes = parseInt(date.substring(14, 16));
    var seconds = parseInt(date.substring(17, 19));
    var milliseconds = 0;
    if (date.indexOf('.') >= 0)
      milliseconds = parseInt(date.substring(20, date.length - 1));

    return new Date(year, month, day, hours, minutes, seconds, milliseconds);
  }

  public convertDateToString(date: Date): string {
    var year = date.getFullYear();
    var month = '' + (date.getMonth() + 1);
    var day = '' + date.getDate();
    var hour = '' + date.getHours();
    var minute = '' + date.getMinutes();
    var second = '' + date.getSeconds();
    var msecond = '' + date.getMilliseconds();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    if (hour.length < 2)
      hour = '0' + hour;
    if (minute.length < 2)
      minute = '0' + minute;
    if (second.length < 2)
      second = '0' + second;
    if (msecond.length < 2)
      msecond = '0' + msecond;
    if (msecond.length < 3)
      msecond = '0' + msecond;

    return year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + second + '.' + msecond + 'Z';
  }
  public formatDate(date: Date): string {
    var year = date.getFullYear();
    var month = '' + (date.getMonth() + 1);
    var day = '' + date.getDate();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  public formatHour(date: Date): string {
    var hour = '' + date.getHours();
    var minute = '' + date.getMinutes();
    var second = '' + date.getSeconds();

    if (hour.length < 2)
      hour = '0' + hour;
    if (minute.length < 2)
      minute = '0' + minute;
    if (second.length < 2)
      second = '0' + second;

    return [hour, minute, second].join(':');
  }
}
