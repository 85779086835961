import { Injectable } from '@angular/core';
import { Workbook, Worksheet } from 'exceljs';
import * as fs from 'file-saver';
import { calidad_siniestros }  from '../../models/hipotecario/calidad_siniestros';

@Injectable({
  providedIn: 'root'
})
export class hipotecarioExcelSiniestrosService {

  constructor() { }

  /**
   * 
   * @param info :calidad_siniestros[]
   * @param sector_name :string
   */
  exportExcel(info : calidad_siniestros[],sector_name:string) { 
    
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Calidad Siniestros Hipotecario');
   
    var name = 'reporte_calidadSiniestros_hipotecario';
    var header = ['CALIDAD_ID', 'AUDITOR', 'CANAL', 'FECHA', 'FECHA GESTION', 'ASESOR', 'DNI CLIENTE', 'ID AUDIO','FALTA O ERROR EN EL SINIESTRO','CIERRE OBLIGATORIO / ADELANTO DE DOCUMENTACIÓN','VERIFICACIÓN DE VERBAL ID','CORRECTA INFORMACIÓN DE TIEMPOS Y PROCEDIMIENTOS','HABILIDADES DE COMUNICACIÓN','SALUDO INICIAL/SALUDO FINAL','HOLD','SCORE','NOTA DE CALIDAD','RESOLUCIÓN DE LA LLAMADA','OBSERVACIÓN ADICIONAL'];
  
    
    var fila = worksheet.addRow([]);
    fila.height = 30;                      
    fila.font = { name: 'Arial', family: 4, size: 10, bold: true};//underline: 'double', 
    fila.getCell('A').value = 'REPORTE CALIDAD SINIESTROS';
    fila.getCell('A').style.alignment = {horizontal: 'center'}; 

    fila.getCell('A').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
          argb: '00939b9e'
      }
    };

    fila.getCell('I').value = 'PONDERACIONES SINIESTROS';
    fila.getCell('I').style.alignment = {horizontal: 'center'};
    fila.getCell('I').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
          argb: '00D3D7D8'
      }
    };
    
    fila.getCell('P').value = '';
    fila.getCell('P').style.alignment = {horizontal: 'center'};
    fila.getCell('P').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
          argb: '00939b9e'
      }
    };

    worksheet.mergeCells('A1:H1');   
    worksheet.mergeCells('I1:O1');
    worksheet.mergeCells('P1:S1');    
    

    //worksheet.getColumn(1).alignment = { horizontal: 'center' };
    worksheet.getRow(1).alignment = { horizontal: 'center' };
  
    //fila 2: subtitulos
    var filaEncabezado = worksheet.addRow(header);

    filaEncabezado.height = 50;
    filaEncabezado.font = { name: 'Calibri', family: 4, size: 9, bold: true };
    filaEncabezado.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
          argb: '00D3D7D8'
      }
    };
    worksheet.getRow(2).alignment = { horizontal: 'center' };
  
      
    info.forEach(d => {
      this.addRow(d, worksheet); 
     
    });

   
    //calidadId
    worksheet.getColumn(1).width = 40;
    filaEncabezado.getCell(1).alignment = { horizontal: 'center', wrapText: true};
    //auditor
    worksheet.getColumn(2).width = 20;
    filaEncabezado.getCell(2).alignment = { horizontal: 'center', wrapText: true};
    //canal
    worksheet.getColumn(3).width = 40;
    filaEncabezado.getCell(3).alignment = { horizontal: 'center', wrapText: true};
    //fecha
    worksheet.getColumn(4).width = 20;
    filaEncabezado.getCell(4).alignment = { horizontal: 'center', wrapText: true};
    //fecha gestion
    worksheet.getColumn(5).width = 20;
    filaEncabezado.getCell(5).alignment = { horizontal: 'center', wrapText: true};
    //asesor
    worksheet.getColumn(6).width = 16;
    filaEncabezado.getCell(6).alignment = { horizontal: 'center', wrapText: true};
    //dni cliente ()
    worksheet.getColumn(7).width = 15;
    filaEncabezado.getCell(7).alignment = { horizontal: 'center', wrapText: true};
    //id audio
    worksheet.getColumn(8).width = 40;  
    filaEncabezado.getCell(8).alignment = { horizontal: 'center', wrapText: true};
    //FALTA O ERRROR EN EL REGISTRO
    worksheet.getColumn(9).width = 15;
    filaEncabezado.getCell(9).alignment = { horizontal: 'center', wrapText: true};
    //CIERRE OBLIGATORIO / ADELANTO DE DOCUMENTACION
    worksheet.getColumn(10).width = 20;
    filaEncabezado.getCell(10).alignment = { horizontal: 'center', wrapText: true};
    //verificacin de verbal
    worksheet.getColumn(11).width = 15;
    filaEncabezado.getCell(11).alignment = { horizontal: 'center', wrapText: true};
    //correcta informacion de tiempos y procedimientos
    worksheet.getColumn(12).width = 20;
    filaEncabezado.getCell(12).alignment = { horizontal: 'center', wrapText: true};
    //habilidades de comunicacion
    worksheet.getColumn(13).width = 15;
    filaEncabezado.getCell(13).alignment = { horizontal: 'center', wrapText: true};
    //saludo inicial final
    worksheet.getColumn(14).width = 20;  
    filaEncabezado.getCell(14).alignment = { horizontal: 'center', wrapText: true};
    //hold
    worksheet.getColumn(15).width = 20;  
    filaEncabezado.getCell(15).alignment = { horizontal: 'center', wrapText: true};
    //score
    worksheet.getColumn(16).width = 10;  
    filaEncabezado.getCell(16).alignment = { horizontal: 'center', wrapText: true};
    //nota de calidad
    worksheet.getColumn(17).width = 20;  
    filaEncabezado.getCell(17).alignment = { horizontal: 'center', wrapText: true};
    //resolucion de la denuncia
    worksheet.getColumn(18).width = 20;  
    filaEncabezado.getCell(18).alignment = { horizontal: 'center', wrapText: true};
    //obs adicional
    worksheet.getColumn(19).width = 40;  
    filaEncabezado.getCell(19).alignment = { horizontal: 'center', wrapText: true}; 
    
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, name + '.xlsx');
    });
  }
  /**
   * info : calidad_siniestros
   * worksheet: Worksheet
   */
  private addRow(info : calidad_siniestros, worksheet: Worksheet) {    
    var row = worksheet.addRow([info.evaluationId,info.evaluator,info.mediaType,info.fecha_llamada,info.fecha_auditoria,info.agente,info.dni_cliente,info.conversationId,info.errorRegistro,info.cierreObligatorio,info.verificacionVerbal,info.correctaInfo,info.habilidadesComunicacion,info.saludoIniFin,info.hold,info.score,info.NotaCalidad,info.denuncia,info.comments]);
    row.height = 25; 
    row.font = {name: 'Calibri', size: 9};
  }
}
