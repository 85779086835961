import { Injectable } from '@angular/core';
import * as platformClient  from 'purecloud-platform-client-v2';
import { userDetailDataPurecloudService } from './userDetail-data-purecloud.service';
import { PurecloudService } from '../purecloud.service';
import { MapUserDetail }  from '../../../utils/map-userDetail';
import { DateTime }  from '../../../utils/date-time';
import { TimeRange }  from '../time-range';
/**utilizando moment para manipular fechas */
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export abstract class userDetailDataOpenedPurecloudService extends userDetailDataPurecloudService {

  constructor(private acloudService : PurecloudService) {
    super(acloudService);
  }

   protected processInternalCall(usersDetail: platformClient.Models.AnalyticsUserDetail, timeRange : TimeRange, calls : Object[]) {
    //console.log(timeRange);   
   var timePrimaryPresence = undefined;
   var timeRoutingStatus = undefined;
  
   if(usersDetail.primaryPresence !== undefined && usersDetail.routingStatus === undefined){   
      //console.log(usersDetail.primaryPresence[usersDetail.primaryPresence.length-1].endTime);
      timePrimaryPresence = DateTime.convertStringToDate((usersDetail.primaryPresence[usersDetail.primaryPresence.length-1].endTime !== undefined ? usersDetail.primaryPresence[usersDetail.primaryPresence.length-1].endTime : usersDetail.primaryPresence[usersDetail.primaryPresence.length-1].startTime));
      if(timeRange.fromParcial.getTime() === timeRange.from.getTime() && (timePrimaryPresence.getTime() <= timeRange.from.getTime())) {     
        this.processCall(MapUserDetail.analyticsUserDetail(usersDetail), calls);
       // console.log('presence return');  
        return;
      } 
      if(timePrimaryPresence.getTime() > timeRange.fromParcial.getTime()) {
       // console.log(usersDetail);
        this.processCall(MapUserDetail.analyticsUserDetail(usersDetail), calls);    
        //console.log('solo presence');  
      } 
      //console.log(MapUserDetail.analyticsUserDetail(usersDetail)); 
    }
   if(usersDetail.primaryPresence === undefined && usersDetail.routingStatus !== undefined){     
    timeRoutingStatus = DateTime.convertStringToDate((usersDetail.routingStatus[usersDetail.routingStatus.length-1].endTime !== undefined ? usersDetail.routingStatus[usersDetail.routingStatus.length-1].endTime : usersDetail.routingStatus[usersDetail.routingStatus.length-1].startTime));
    if(timeRange.fromParcial.getTime() === timeRange.from.getTime() && (timeRoutingStatus.getTime() <= timeRange.from.getTime())) {     
      this.processCall(MapUserDetail.analyticsUserDetail(usersDetail), calls);
      return;
    } 
    if(timeRoutingStatus.getTime() > timeRange.fromParcial.getTime()) {
      this.processCall(MapUserDetail.analyticsUserDetail(usersDetail), calls);  
      //console.log('solo routing');      
    }  
  }

  if(usersDetail.primaryPresence !== undefined && usersDetail.routingStatus !== undefined){
    timePrimaryPresence = DateTime.convertStringToDate((usersDetail.primaryPresence[usersDetail.primaryPresence.length-1].endTime !== undefined ? usersDetail.primaryPresence[usersDetail.primaryPresence.length-1].endTime : usersDetail.primaryPresence[usersDetail.primaryPresence.length-1].startTime));
    timeRoutingStatus = DateTime.convertStringToDate((usersDetail.routingStatus[usersDetail.routingStatus.length-1].endTime !== undefined ? usersDetail.routingStatus[usersDetail.routingStatus.length-1].endTime : usersDetail.routingStatus[usersDetail.routingStatus.length-1].startTime));
    if(timeRange.fromParcial.getTime() === timeRange.from.getTime() && (timePrimaryPresence.getTime() <= timeRange.from.getTime() && timeRoutingStatus.getTime() <= timeRange.from.getTime())) {     
      this.processCall(MapUserDetail.analyticsUserDetail(usersDetail), calls);
      return;
    } 
    if(timePrimaryPresence.getTime() > timeRange.fromParcial.getTime() && timeRoutingStatus.getTime() > timeRange.fromParcial.getTime() ) {
      this.processCall(MapUserDetail.analyticsUserDetail(usersDetail), calls);  
      //console.log('ambas');      
    }  
  }
 
  
 /*   if(timePrimaryPresence !== undefined || timeRoutingStatus !== undefined) {
       if(timeRange.fromParcial.getTime() === timeRange.from.getTime() && ((timePrimaryPresence !== undefined ? timePrimaryPresence.getTime(): undefined) <= timeRange.from.getTime() || (timeRoutingStatus !== undefined ? timeRoutingStatus.getTime() : undefined) <= timeRange.from.getTime())) {
        console.log('dasdas');
        this.processCall(MapUserDetail.analyticsUserDetail(usersDetail), calls);
        return;
      }      
     
      console.log(timePrimaryPresence);
       console.log(timeRoutingStatus);
      //if((timePrimaryPresence !== undefined && timeRoutingStatus === undefined && timePrimaryPresence.getTime() > timeRange.fromParcial.getTime()) || (timePrimaryPresence === undefined && timeRoutingStatus !== undefined && timeRoutingStatus.getTime() > timeRange.fromParcial.getTime()) || (timePrimaryPresence !== undefined && timeRoutingStatus !== undefined && timePrimaryPresence.getTime() > timeRange.fromParcial.getTime() && timeRoutingStatus.getTime() > timeRange.fromParcial.getTime())) {
     if(timePrimaryPresence !== undefined && timeRoutingStatus === undefined && (timePrimaryPresence.getTime() > timeRange.fromParcial.getTime())) {
        this.processCall(MapUserDetail.analyticsUserDetail(usersDetail), calls);
        
      } 
      
      if(timePrimaryPresence === undefined && timeRoutingStatus !== undefined && (timeRoutingStatus.getTime() > timeRange.fromParcial.getTime())){
        this.processCall(MapUserDetail.analyticsUserDetail(usersDetail), calls);
       
      }
      if (timePrimaryPresence !== undefined && timeRoutingStatus !== undefined && (timePrimaryPresence.getTime() > timeRange.fromParcial.getTime() && timeRoutingStatus.getTime() > timeRange.fromParcial.getTime())){
          this.processCall(MapUserDetail.analyticsUserDetail(usersDetail), calls);
          
      }
               
   }   */
  } 
}
