/**utilizando moment para manipular fechas */
import * as moment from 'moment';

export class TimeRange {
    public fromParcial: Date;
    public toParcial: Date;
    public count: number;
    public iDaysParcial: number;
    constructor(public from: Date, public to: Date, public iDays: number) {
        //ejecutar mientras diferencia sea mayor a 31 dias
        //console.log(moment(to).diff(from));
        this.fromParcial = from;
       // console.log(this.fromParcial);
        //const durationAvailable = moment.duration(moment(to).diff(from));
        //while (durationAvailable.days() > 31 )
       // {
            this.toParcial = this.convertStringToDate(moment(moment(from).add(iDays,'days')).format('YYYY-MM-DD HH:mm:sss'));
           // console.log(this.toParcial);
            if(this.toParcial.getTime() > this.to.getTime()) {
                this.toParcial = this.to;
               // console.log(this.toParcial);
            }
            this.count = 0;
            this.iDaysParcial = iDays;
       // }
        
    /*     this.toParcial =  new Date(from.getFullYear(), from.getMonth(), from.getDate(), from.getHours() + 1, from.getMinutes(), from.getSeconds(), from.getMilliseconds());
        if(this.toParcial.getTime() > this.to.getTime()) {
            this.toParcial = this.to;
        }
        this.count = 0; */
    }
    public addDate() {
        this.fromParcial = this.convertStringToDate(moment(moment(this.fromParcial).add(this.iDaysParcial,'days')).format('YYYY-MM-DD HH:mm:sss'));//new Date(this.fromParcial.getFullYear(), this.fromParcial.getMonth(), this.fromParcial.getDate(), this.fromParcial.getHours() + 1, this.fromParcial.getMinutes(), this.fromParcial.getSeconds(), this.fromParcial.getMilliseconds());
        this.toParcial = this.convertStringToDate(moment(moment(this.toParcial).add(this.iDaysParcial,'days')).format('YYYY-MM-DD HH:mm:sss'));//new Date(this.toParcial.getFullYear(), this.toParcial.getMonth(), this.toParcial.getDate(), this.toParcial.getHours() + 1, this.toParcial.getMinutes(), this.toParcial.getSeconds(), this.toParcial.getMilliseconds());
        if(this.toParcial.getTime() > this.to.getTime()) {
            this.toParcial = this.to;
        }
    }
    public isOk() : boolean {
        return this.to.getTime() > this.fromParcial.getTime();
    }
    public addCount(count : number) {
        this.count = this.count + count;
    }
    public convertStringToDate(date : string) : Date {
        var year = parseInt(date.substring(0, 4));
        var month = parseInt(date.substring(5, 7)) - 1;
        var day = parseInt(date.substring(8, 10));
        var hours = parseInt(date.substring(11, 13));
        var minutes = parseInt(date.substring(14, 16));
        var seconds = parseInt(date.substring(17, 19));
        var milliseconds = 0;
        if(date.indexOf('.') >= 0)
          milliseconds = parseInt(date.substring(20, date.length - 1));
        
        return new Date(year, month, day, hours, minutes, seconds, milliseconds);
      }
}
  