import * as platformClient  from 'purecloud-platform-client-v2';

export class calidad_siniestros{
    evaluationId: string;
    conversationId : string;

    //fecha de evaluacion
    fecha_llamada: string;
    fecha:string;
    hora:string;
    //fecha gestion: fecha llamada
    fecha_auditoria: string;
    fecha_aud : string;
    hora_aud: string;

    //evaluator
    evaluatorId: string;
    evaluator: string;

    //canal
    mediaType: string;

    //dni cliente(puede o no estar)
    dni_cliente: string;
    //asesor - age,nte 
    agenteId: string;
    agente: string

    //ponderaciones
    id_questionGroupPonderaciones: string;
    errorRegistro:string;
    errorRegistroId:string;
    cierreObligatorio:string;
    cierreObligatorioId:string;
    verificacionVerbal:string;
    verificacionVerbalId:string;
    correctaInfo:string;
    correctaInfoId:string;
    habilidadesComunicacion:string;
    habilidadesComunicacionId:string;
    saludoIniFin:string;
    saludoIniFinId:string;
    holdId:string;
    hold: string;

   //resolucion de la denuncia
   id_questionGroupDenuncia:string;
   denunciaId:string;
   denuncia: string;

  

    score: number;
    NotaCalidad: string; //si score >= 80 SI sino NO

    segmentType: string;//wrapup
    wrapUpCode: string;//"84eeeceb-7b19-472a-b48f-7861d9c521bb"
    registro:string;

    //observaciones
    //questionGroupScores/questionScores
    questionId:string;
    answerId:string;
    questionScores: number;
    comments: string;

}