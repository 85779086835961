export class Conversation {
    conversationEnd: string;
    conversationId: string;
    conversationStart: string;
    divisionIds: Array<string>;
    originatingDirection: string;
    participants: Array<Participant>;
}
export class Participant {
    participantName: string;
    purpose: string;
    userId: string;
    sessions: Array<Session>;
}
export class Session {
    ani: string;
    direction: string;
    dnis: string;
    edgeId: string;
    sessionId: string;
    peerId: string;
    mediaType: string;
    segments: Array<Segment>;
    monitoredParticipantId: string;
    outboundCampaignId: string;
	outboundContactId: string;
	outboundContactListId: string;
}
export class Segment {
    disconnectType: string;
    segmentEnd: string;
    segmentStart: string;
    segmentType: string;
    wrapUpCode: string;
    wrapUpNote: string;
    properties: Array<Property>;
}
export class Property {
    property: string;
    propertyType: string;
    value: string;
}