import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule } from "@angular/forms"

import { TranslateLoader, TranslateModule } from "@ngx-translate/core"
import { HttpClient, HttpClientModule } from "@angular/common/http"
import { TranslateHttpLoader } from "@ngx-translate/http-loader"

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';

import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';

import { NgxMatMomentModule, NgxMatMomentAdapter, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';
import { NGX_MAT_DATE_FORMATS, NgxMatDateAdapter, NgxMatDateFormats } from '@angular-material-components/datetime-picker';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DownloaderComponent } from './components/downloader/downloader.component';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { HipotecarioComponent } from './components/hipotecario/calidad/hipotecario.component';
import { HipotecarioServices } from './components/hipotecario/servicios/servicios.component';
import { HipotecarioVentas } from './components/hipotecario/ventas/ventasCCI.component';
import { LanguageService } from './services/language/language.service';
export function HttpLoaderFactory (client : HttpClient) {
  return new TranslateHttpLoader(client);
}

const CUSTOM_DATE_FORMATS : NgxMatDateFormats = {
  parse: {
    dateInput: "DD-MM-YYYY HH:mm:ss"
  },
  display: {
    dateInput: "DD-MM-YYYY HH:mm:ss",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@NgModule({
  declarations: [
    AppComponent,
    DownloaderComponent,
    HipotecarioComponent,
    HipotecarioServices,
    HipotecarioVentas
  ],
  imports: [
    CommonModule,
    FormsModule, 
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    
    MatDatepickerModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatNativeDateModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,   
    AngularDualListBoxModule ,
    BrowserModule,
    AppRoutingModule,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      deps: [LanguageService],
      useFactory: (languagesService) => languagesService.getLanguage()
    },
   
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    
    MatDatepickerModule,
    MatNativeDateModule,
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: NgxMatDateAdapter, useClass: NgxMatMomentAdapter }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
