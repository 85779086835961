import { Injectable } from '@angular/core';
import { Workbook, Worksheet } from 'exceljs';
import * as fs from 'file-saver';
import { venta_cci }  from '../../models/ventas/venta_cci';
import { venta_cci_queue }  from '../../models/ventas/venta_cci';
import { TimeRange } from '../../services/purecloud/time-range';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ventasExcelService {

  constructor() { }

  /*
  * campania_level:string
  * info : venta_cci[] :: totales datos por persona
  * infoQueue : venta_cci_queue[] : datos por persona por día
  * from: Date
  * to: Date) {
  */
  exportExcel(campania_level:string,info : venta_cci[],infoQueue : venta_cci_queue[], from: Date, to: Date) {

    let workbook = new Workbook();

    var headerMerge = ['USER', 'OPERADOR','TEAM LEADER'];
    var header = ['LOGIN', 'TOTAL LLAMADOS','CLIENTES GESTIONADOS','CIV (CONTACTO INTERLOCUTOR VALIDO)','CU (CONTACTO UTIL)','VENTAS','VOLVER A LLAMAR','HABLADO EN SALIENTES','TALKING TIME','% READY','NOT READY','LLAMADOS POR HORA','SPH','CONVERSION (efectividad)','CU POR HORA','P-P (punta a punta)'];
    var headerDays = [];

    var timeRangeDays = new TimeRange(from, to,1);
    //console.log(timeRangeDays);
    while (timeRangeDays.toParcial.getTime() <= to.getTime()){
       headerDays.push(moment(timeRangeDays.fromParcial).format('YYYY-MM-DD'));
       timeRangeDays.fromParcial = this.convertStringToDate(moment(moment(timeRangeDays.fromParcial).add(1,'days')).format('YYYY-MM-DD HH:mm:sss'));
       timeRangeDays.toParcial = this.convertStringToDate(moment(moment(timeRangeDays.toParcial).add(1,'days')).format('YYYY-MM-DD HH:mm:sss'));
       //console.log(timeRangeDays);
    }
    console.log(headerDays);

    var name = 'reporte_ventas_cci';
    let worksheet = workbook.addWorksheet(campania_level);
    var fila = worksheet.addRow([]);
    fila.height = 30;
    fila.font = { name: 'Arial', family: 4, size: 8, bold: true};//underline: 'double',
    fila.getCell('A').value = campania_level;
    fila.getCell('A').style.alignment = {horizontal: 'center'};
    fila.getCell('A').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
          argb: '00939b9e'
      }
    };

    //Configura titulos
    var { i, column } = this.configuraTitulosPrincipales(worksheet, headerDays);

    //FILA 3
    infoQueue.forEach(e => this.addRowQueue(e, worksheet,headerDays));

    //primeras columnas
    worksheet.mergeCells(2,1,3,1);
    worksheet.mergeCells(2,2,3,2);
    worksheet.mergeCells(2,3,3,3);

    //FILA 4
    var filaEncabezado = worksheet.addRow([]);
    filaEncabezado.height = 50;
    filaEncabezado.font = { name: 'Calibri', family: 4, size: 8, bold: true };

    //Titulos por dia  filas "T" en adelante

    for (var i = 0; i <= headerDays.length; i++) {
      column = 4 + (15 * i) + i;
       //LOGUEO
      filaEncabezado.getCell(column).value = 'LOGIN';
      worksheet.getColumn(column).width = 10;
      filaEncabezado.getCell(column).alignment = { horizontal: 'center', wrapText: true};
       //TOTAL LLAMADOS
      filaEncabezado.getCell(column + 1).value = 'TOTAL LLAMADOS';
      worksheet.getColumn(column + 1).width = 10;
      filaEncabezado.getCell(column + 1).alignment = { horizontal: 'center', wrapText: true};
       //CLIENTES GESTIONADOS
      filaEncabezado.getCell(column + 2).value = 'CLIENTES GESTIONADOS';
      worksheet.getColumn(column + 2).width = 15;
      filaEncabezado.getCell(column + 2).alignment = { horizontal: 'center', wrapText: true};
       //CIV (CONTACTO INTERLOCUTOR VALIDO)
      filaEncabezado.getCell(column + 3).value ='CIV (CONTACTO INTERLOCUTOR VALIDO)';
      worksheet.getColumn(column + 3).width = 20;
      filaEncabezado.getCell(column + 3).alignment = { horizontal: 'center', wrapText: true};
      //CU (CONTACTO UTIL)
      filaEncabezado.getCell(column + 4).value ='CU (CONTACTO UTIL)';
      worksheet.getColumn(column + 4).width = 15;
      filaEncabezado.getCell(column + 4).alignment = { horizontal: 'center', wrapText: true};
      //VENTAS
      filaEncabezado.getCell(column + 5).value ='VENTAS';
      worksheet.getColumn(column + 5).width = 10;
      filaEncabezado.getCell(column + 5).alignment = { horizontal: 'center', wrapText: true};
      //VOLVER A LLAMAR
      filaEncabezado.getCell(column + 6).value ='VOLVER A LLAMAR';
      worksheet.getColumn(column + 6).width = 10;
      filaEncabezado.getCell(column + 6).alignment = { horizontal: 'center', wrapText: true};
       //HABLADO EN SALIENTES
      filaEncabezado.getCell(column + 7).value ='HABLADO EN SALIENTES';
      worksheet.getColumn(column + 7).width = 10;
      filaEncabezado.getCell(column + 7).alignment = { horizontal: 'center', wrapText: true};
       //TALKING TIME
      filaEncabezado.getCell(column + 8).value ='TALKING TIME';
      worksheet.getColumn(column + 8).width = 10;
      filaEncabezado.getCell(column + 8).alignment = { horizontal: 'center', wrapText: true};
      //% READY
      filaEncabezado.getCell(column + 9).value ='% READY';
      worksheet.getColumn(column + 9).width = 10;
      filaEncabezado.getCell(column + 9).alignment = { horizontal: 'center', wrapText: true};
      //NOT READY
      filaEncabezado.getCell(column + 10).value ='NOT READY';
      worksheet.getColumn(column + 10).width = 10;
      filaEncabezado.getCell(column + 10).alignment = { horizontal: 'center', wrapText: true};
       //LLAMADOS POR HORA
      filaEncabezado.getCell(column + 11).value ='LLAMADOS POR HORA';
      worksheet.getColumn(column + 11).width = 12;
      filaEncabezado.getCell(column + 11).alignment = { horizontal: 'center', wrapText: true};
      //SPH
      filaEncabezado.getCell(column + 12).value ='SPH';
      worksheet.getColumn(column + 12).width = 10;
      filaEncabezado.getCell(column + 12).alignment = { horizontal: 'center', wrapText: true};
      //CONVERSION (efectividad)
      filaEncabezado.getCell(column + 13).value ='CONVERSION (efectividad)';
      worksheet.getColumn(column + 13).width = 10;
      filaEncabezado.getCell(column + 13).alignment = { horizontal: 'center', wrapText: true};
       //CU POR HORA
      filaEncabezado.getCell(column + 14).value ='CU POR HORA';
      worksheet.getColumn(column + 14).width = 10;
      filaEncabezado.getCell(column + 14).alignment = { horizontal: 'center', wrapText: true};
      //P-P (punta a punta)
      filaEncabezado.getCell(column + 15).value ='P-P (punta a punta)';
      worksheet.getColumn(column + 15).width = 10;
      filaEncabezado.getCell(column + 15).alignment = { horizontal: 'center', wrapText: true};
    }

    filaEncabezado.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
          argb: '00D3D7D8'
      }
    };


    worksheet.getRow(4).alignment = { horizontal: 'center', wrapText: true };

  //Agrega filas totales generales
  info.forEach(d => this.addRow(d, worksheet,headerDays));



  workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, name + '.xlsx');
      });
  }

  private configuraTitulosPrincipales(worksheet: Worksheet, headerDays: any[]) {
    worksheet.mergeCells('A1:S1');
    worksheet.getRow(1).alignment = { horizontal: 'center', vertical: 'justify' };

    //fila 2: TOTAL
    var filaTotal = worksheet.addRow([]);
    filaTotal.height = 30;
    filaTotal.font = { name: 'Arial', family: 4, size: 8, bold: true };
    //USER NAME
    filaTotal.getCell(1).value = 'USER';
    worksheet.getColumn(1).width = 10;
    filaTotal.getCell(1).alignment = { horizontal: 'center', wrapText: true };
    //AGENT NAME
    filaTotal.getCell(2).value = 'OPERADOR';
    worksheet.getColumn(2).width = 20;
    filaTotal.getCell(2).alignment = { horizontal: 'center', wrapText: true };
    //TEAM LEADER
    filaTotal.getCell(3).value = 'TEAM LEADER';
    worksheet.getColumn(3).width = 20;
    filaTotal.getCell(3).alignment = { horizontal: 'center', wrapText: true };

    filaTotal.getCell(4).value = 'TOTAL';
    filaTotal.getCell(4).style.alignment = { horizontal: 'center' };

    // merge by start row, start column, end row, end column (equivalent to K10:M12)
    worksheet.mergeCells(2, 4, 2, 19);
    //ahora merge por titulos day de forma dinamica
    var column = 0;
    var j = 0;
    for (var i = 0; i < headerDays.length; i++) {
      column = 20 + (15 * i) + i;
      filaTotal.getCell(column).value = headerDays[i];
      //console.log(filaTotal.getCell(5 + i).value);
      filaTotal.getCell(column).style.alignment = { horizontal: 'center' };
      j = column + 15;
      worksheet.mergeCells(2, column, 2, j);
    }

    filaTotal.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb: '00D3D7D8'
      }
    };

    worksheet.getRow(2).alignment = { horizontal: 'center', vertical: 'justify' };
    return { i, column };
  }

  //Agrega totales lineas A a S
  private async addRowQueue(info : venta_cci_queue,worksheet: Worksheet,headerDays) {

      var values = [];
      var row =  worksheet.addRow([]);
      row.getCell(4).value = info.timeQueueLogueo;
      row.getCell(5).value = info.totalConversations;
      row.getCell(6).value = info.totalClientesGestionados;
      row.getCell(7).value = info.totalInterlocutorValido;
      row.getCell(8).value = info.totalContactoUtil;
      row.getCell(9).value = info.totalVentas;
      row.getCell(10).value = info.totalVolverLlamar;

      row.getCell(11).value = info.totalSalientes;
      row.getCell(12).value = info.totalTiempoHablado;
      row.getCell(13).value = info.totalReady;
      row.getCell(14).value = info.totalNotReady;
      row.getCell(15).value = info.totalLlamadosHora;
      row.getCell(16).value = info.totalSPH;
      row.getCell(17).value = info.totalConversionEfectividad;
      row.getCell(18).value = info.totalCUporHora;
      row.getCell(19).value = info.totalPP;


      //20-35 T->
      var k = 0;
      info.aDayTotalQueue.forEach((t) => {
        t.aTotales.forEach((inf) => {
        values = [inf.timeLoguin,inf.totalConversation,inf.totalClientesGestionados,inf.totalInterlocutorValido,inf.totalContactoUtil,inf.totalVentas,inf.totalVolverLlamar,inf.totalSalientes,inf.totalTiempoHablado,inf.totalReady,inf.totalNotReady,inf.totalLlamadosHora,inf.totalSPH,inf.totalConversionEfectividad,inf.totalCUporHora,inf.totalPP];
        for (var i = 0; i < values.length; i++){
                  row.getCell(20 + k).value = values[i];
                  k++;
              }
          })
      })

      row.height = 25;
      row.font = {name: 'Calibri', size: 8};
  }

  //Agrega filas de A al final
  private async addRow(info : venta_cci,worksheet: Worksheet,headerDays) {

    //filadatosQueue.getCell(5).value = info.

    var values = [];
    var row =  worksheet.addRow([]);

    row.getCell(1).value = info.agenteUser;
    row.getCell(2).value = info.agenteName;
    row.getCell(3).value = info.teamLeaderName;
    if(info.timeAgentetLogueo ==='00:00:00'  ){

    }
    else{
      row.getCell(4).value = info.timeAgentetLogueo;
      row.getCell(5).value = info.totalConversations;
      row.getCell(6).value = info.totalClientesGestionados;
      row.getCell(7).value = info.totalInterlocutorValido;
      row.getCell(8).value = info.totalContactoUtil;
      row.getCell(9).value = info.totalVentas;
      row.getCell(10).value = info.totalVolverLlamar;
      row.getCell(11).value = info.totalSalientes;
      row.getCell(12).value = info.totalTiempoHablado;
      row.getCell(13).value = info.totalReady;
      row.getCell(14).value = info.totalNotReady;
      row.getCell(15).value = info.totalLlamadosHora;
      row.getCell(16).value = info.totalSPH;
      row.getCell(17).value = info.totalConversionEfectividad;
      row.getCell(18).value = info.totalCUporHora;
      row.getCell(19).value = info.totalPP;
   }
    //TODO: revisar aca tambien el tema de los totales, suena sospechoso
    var k = 0;
    info.aDayTotalAgente.forEach((t) => {
       t.aTotales.forEach((inf) => {
        values = [inf.timeLoguin,
                  inf.totalConversation,
                  inf.totalClientesGestionados,
                  inf.totalInterlocutorValido,
                  inf.totalContactoUtil,
                  inf.totalVentas,
                  inf.totalVolverLlamar,
                  inf.totalSalientes,
                  inf.totalTiempoHablado,
                  inf.totalReady,
                  inf.totalNotReady,
                  inf.totalLlamadosHora,
                  inf.totalSPH,
                  inf.totalConversionEfectividad,
                  inf.totalCUporHora,
                  inf.totalPP];
        for (var i = 0; i < values.length; i++){
            if (inf.timeLoguin ==='00:00:00'){ //saltea los valores que tienen tiempo 0 de logueo
              k = k + values.length;
              i =  values.length;
            } else{
              row.getCell(20 + k).value = values[i];
              k++;
            }
          }

       })
    })

   // row = worksheet.addRow([info.agenteUser,info.agenteName,info.teamLeaderName,info.timeAgentetLogueo,info.totalConversations,info.totalClientesGestionados,info.totalInterlocutorValido,info.totalContactoUtil,info.totalVentas,info.totalVolverLlamar,info.totalSalientes,info.totalTiempoHablado,info.totalReady,info.totalNotReady,info.totalLlamadosHora,info.totalSPH,info.totalConversionEfectividad,info.totalCUporHora,info.totalPP]);

    row.height = 25;
    row.font = {name: 'Calibri', size: 8};
  }


  public convertStringToDate(date : string) : Date {
    var year = parseInt(date.substring(0, 4));
    var month = parseInt(date.substring(5, 7)) - 1;
    var day = parseInt(date.substring(8, 10));
    var hours = parseInt(date.substring(11, 13));
    var minutes = parseInt(date.substring(14, 16));
    var seconds = parseInt(date.substring(17, 19));
    var milliseconds = 0;
    if(date.indexOf('.') >= 0)
      milliseconds = parseInt(date.substring(20, date.length - 1));

    return new Date(year, month, day, hours, minutes, seconds, milliseconds);
  }
}
