import { Injectable } from '@angular/core';
import * as platformClient  from 'purecloud-platform-client-v2';
import { Conversation, Participant, Segment, Session } from 'src/app/models/purecloud/conversation';
import { MapConversation } from 'src/app/utils/map-conversation';
import { PurecloudService } from '../purecloud/purecloud.service';
import { ConversationDataPurecloudService } from '../purecloud/conversationDetail/conversation-data-purecloud.service';
import { TimeRange } from '../purecloud/time-range';
import { DateTime } from 'src/app/utils/date-time';
import { venta_cci }  from 'src/app/models/ventas/venta_cci';
import * as moment from 'moment';
import { TimeRangeDays } from '../purecloud/time-rangeDay';
@Injectable({
  providedIn: 'root'
})
export class ConversationsDetailSimpleDataService extends ConversationDataPurecloudService {

    constructor(private purecloudService : PurecloudService) {
        super(purecloudService);
      }

      protected async processInternalCall(conversation: platformClient.Models.AnalyticsConversation, timeRange : TimeRange, calls : Object[],timeRangeDay: TimeRange,call: any,callQueue: any,aWraUps,aUserStatusAggregateDay: Array<any>) {
        var from = DateTime.convertStringToDate(conversation.conversationStart);
        if(from.getTime() >= timeRange.fromParcial.getTime()) {
          this.processIntervalCall(MapConversation.analyticsConversation(conversation), timeRange, calls, call, aWraUps);
        }
        if(timeRange.from.getTime() == timeRange.fromParcial.getTime() && from.getTime() < timeRange.from.getTime() ) {
          this.processIntervalCall(MapConversation.analyticsConversation(conversation), timeRange, calls, call, aWraUps);
        }
      }

      public processCall(conversation : Conversation, calls : Object[]) {}

      public async processIntervalCall(conversation : Conversation, timeRange : TimeRange, calls : Object[], call:any, aWraUps) {
        /*if (calls.length === 0) {
          calls.push({}); //! Esto ok?
        }*/
        var agents = calls[0];

        //let aCtrlUser = [];
        for (var i = 0; i < conversation.participants.length; i++) {
          var participant = conversation.participants[i];
          if(participant.purpose === 'agent' && (participant.sessions[0].segments.length > 1 || (participant.sessions[0].segments[0].segmentType !== 'alert' && participant.sessions[0].segments[0].segmentType !== 'dialing')) ) {
            //if(!aCtrlUser.includes(participant.userId)){
            //  aCtrlUser.push(participant.userId);

              var agent = agents[participant.userId];
              if(agent === undefined) {
                agent = {};
                agent.id = participant.userId;
                agent.daily = {};
                agent.totals = {};
                agent.totals.iClientesGestionados = 0;
                agent.totals.iContactoInterlocutor = 0;
                agent.totals.iContactoUtil = 0;
                agent.totals.iVenta = 0;
                agent.totals.iVolverLlamar = 0;
                agent.totals.totalLlamados = 0;

                agents[participant.userId] = agent;
              }

              this.processAgent(participant,conversation.participants, timeRange, agent.totals, agent, aWraUps, conversation.conversationId);
            //}
          }
        }
      }

      public processAgent(participant : Participant, participants: Participant[], timeRange : TimeRange, totals : any, agent : any, aWraUps,conversationId) {

        //console.log('Participant id: ', participant.userId);
        for (var i = 0; i < participant.sessions.length; i++) {

          var iContactoInterlocutor = 0;
          var iContactoUtil = 0;
          var iVenta = 0;
          var iVolverLlamar = 0;
          var iClientesGestionados = 0;
          var session = participant.sessions[i];
          iClientesGestionados = this.processSession(session, iClientesGestionados, iContactoInterlocutor, iContactoUtil, iVenta, iVolverLlamar, aWraUps, timeRange, conversationId, agent, totals, participant.userId);
/*
        participants.forEach((peerParticipant) => {
           peerParticipant.sessions.forEach((sessionPeer)=> {
               if(sessionPeer.peerId === participant.sessions[i].sessionId || sessionPeer.sessionId === participant.sessions[i].sessionId || sessionPeer.sessionId === participant.sessions[i].sessionId ) {
                  var session = sessionPeer;
                  iClientesGestionados = this.processSession(session, iClientesGestionados, iContactoInterlocutor, iContactoUtil, iVenta, iVolverLlamar, aWraUps, timeRange, conversationId, agent, totals);
                }
          })
        })
*/

          }
      }

  private processSession(session: Session, iClientesGestionados: number, iContactoInterlocutor: number, iContactoUtil: number, iVenta: number, iVolverLlamar: number, aWraUps: any, timeRange: TimeRange, conversationId: any, agent: any, totals: any, userId: string) {
    if (session.outboundContactId !== undefined) {
      iClientesGestionados = 1;
    }

    var segmentLast: Segment = undefined;
    var segmentDate: string = undefined;
    for (var j = 0; j < session.segments.length; j++) {
      var segment = session.segments[j];
      if (segmentDate === undefined) {
        segmentDate = segment.segmentStart;
      }
      if (segment.wrapUpCode !== undefined) {
        segmentLast = segment;
      }
    }

    if (segmentLast !== undefined) {
      segmentDate = segmentLast.segmentStart;

      ({ iContactoInterlocutor, iContactoUtil, iVenta, iVolverLlamar } = this.processWrapups(aWraUps, segmentLast, iContactoInterlocutor, iContactoUtil, iVenta, iVolverLlamar));
    }

    var date = DateTime.convertStringToDate(segmentDate);
    //var dateConvStart = DateTime.convertStringToDate(conversationStart);

    if (timeRange.from.getTime() <= date.getTime() && timeRange.to.getTime() >= date.getTime()) {

      date.setHours(date.getHours() - 3);
      //console.log("date-3: ", date);
      var segmentOriginDate = DateTime.convertDateToString(date);
      segmentDate = segmentOriginDate.substring(0, 10);
      var daily = agent.daily[segmentDate];
      //console.log("segmentDate", segmentDate);
     //console.log("timerange",timeRange.from);
      //console.log("timerange",timeRange.to);

      daily = this.addValues(daily, agent,  segmentDate, iContactoInterlocutor, iContactoUtil, iVenta, iVolverLlamar, iClientesGestionados, totals,conversationId, segmentOriginDate);
    }
    return iClientesGestionados;
  }

  private addValues(daily: any, agent: any, segmentDate: string, iContactoInterlocutor: number, iContactoUtil: number, iVenta: number, iVolverLlamar: number, iClientesGestionados: number, totals: any, conversationId: string, segmentOriginDate: string) {
    if (daily === undefined) {
      daily = {};
      daily.iClientesGestionados = 0;
      daily.iContactoInterlocutor = 0;
      daily.iContactoUtil = 0;
      daily.iVenta = 0;
      daily.iVolverLlamar = 0;
      daily.totalLlamados = 0;
      daily.conversationsIds = [];
      daily.segmentsStarts = []; //la cual tiener registro de los segmetns que se procesaron
      agent.daily[segmentDate] = daily;
    }

    if (daily.segmentsStarts.includes(segmentOriginDate)){
      return daily;
    }
    console.log("Date: "+segmentOriginDate + ", agente: "+agent.id +", conversationId considerado: "+conversationId);
    daily.segmentsStarts.push(segmentOriginDate);
    daily.iContactoInterlocutor += iContactoInterlocutor;
    daily.iContactoUtil += iContactoUtil;
    daily.iVenta += iVenta;
    daily.iVolverLlamar += iVolverLlamar;
    if (!daily.conversationsIds.includes(conversationId)){
      daily.iClientesGestionados += iClientesGestionados;
      daily.conversationsIds.push(conversationId);
    }
    daily.totalLlamados += 1;

    totals.iContactoInterlocutor += iContactoInterlocutor;
    totals.iContactoUtil += iContactoUtil;
    totals.iVenta += iVenta;
    totals.iVolverLlamar += iVolverLlamar;
    totals.iClientesGestionados += iClientesGestionados;
    totals.totalLlamados += 1;
    return daily;
  }

  private sleep2(ms:number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private processWrapups(aWraUps: any, segmentLast: Segment, iContactoInterlocutor: number, iContactoUtil: number, iVenta: number, iVolverLlamar: number) {
    //console.log("Wrapup",aWraUps);

    //console.log('Procesando wrapups: ', segmentLast.segmentStart);
    var i=0;

    for (let clave in aWraUps ) {
      //console.log("print clave: ",clave);
      //console.log("print wraup: ",aWraUps[clave]);
      switch (aWraUps[clave]) {
        case 'Venta':
          switch (clave) {
            case segmentLast.wrapUpCode: iContactoInterlocutor = 1; iContactoUtil = 1; iVenta = 1;
            //console.log("suma venta",clave);
              break;
          }
          break;
        case '(Pauta) No Cumple Con Las Expectativas':
          switch (clave) {
            case segmentLast.wrapUpCode: iContactoInterlocutor = 1; iContactoUtil = 1;
              break;
          }
          break;
        case '(Producto) Sumas Insuficientes':
          switch (clave) {
            case segmentLast.wrapUpCode: iContactoInterlocutor = 1; iContactoUtil = 1;
              break;
          }
          break;
        case 'Cliente Disconforme con la CIA':
          switch (clave) {
            case segmentLast.wrapUpCode: iContactoInterlocutor = 1; iContactoUtil = 1;
              break;
          }
          break;
        case 'Confunde el objetivo del formulario':
          switch (clave) {
            case segmentLast.wrapUpCode: iContactoInterlocutor = 1; iContactoUtil = 1;
              break;
          }
          break;
        case 'Desconoce Formulario':
          switch (clave) {
            case segmentLast.wrapUpCode: iContactoInterlocutor = 1; iContactoUtil = 1;
              break;
          }
          break;
        case 'Gestión No Realizable':
          switch (clave) {
            case segmentLast.wrapUpCode: iContactoInterlocutor = 1; iContactoUtil = 1;
              break;
          }
          break;
        case 'Medio de Pago No Habilitado':
          switch (clave) {
            case segmentLast.wrapUpCode: iContactoInterlocutor = 1; iContactoUtil = 1;
              break;
          }
          break;
        case 'Muy Caro':
          switch (clave) {
            case segmentLast.wrapUpCode: iContactoInterlocutor = 1; iContactoUtil = 1;
              break;
          }
          break;
        case 'No elegible no posee condiciones':
          switch (clave) {
            case segmentLast.wrapUpCode: iContactoInterlocutor = 1;
              break;
          }
          break;
        case 'No Interesado':
          switch (clave) {
            case segmentLast.wrapUpCode: iContactoInterlocutor = 1; iContactoUtil = 1;
              break;
          }
          break;
        case 'No Permite Argumentar':
          switch (clave) {
            case segmentLast.wrapUpCode: iContactoInterlocutor = 1; iContactoUtil = 1;
              break;
          }
          break;
        case 'No posee TC':
          switch (clave) {
            case segmentLast.wrapUpCode: iContactoInterlocutor = 1; iContactoUtil = 1;
              break;
          }
          break;
        case 'Producto similar en otra CIA': //este consultar
          switch (clave) {
            case segmentLast.wrapUpCode: iContactoInterlocutor = 1; iContactoUtil = 1;
              break;
          }
          break;
        case 'Volver a Llamar Argumentado':
          switch (clave) {
            case segmentLast.wrapUpCode: iContactoInterlocutor = 1; iContactoUtil = 1;
              break;
          }
          break;
        case 'Llamar A La Mañana':
          switch (clave) {
            case segmentLast.wrapUpCode: iVolverLlamar = 1;
              break;
          }
          break;
        case 'Llamar A La Tarde':
          switch (clave) {
            case segmentLast.wrapUpCode: iVolverLlamar = 1;
              break;
          }
          break;
      }
    }
    return { iContactoInterlocutor, iContactoUtil, iVenta, iVolverLlamar };
  }

      public convertStringToDate(date : string) : Date {
        var year = parseInt(date.substring(0, 4));
        var month = parseInt(date.substring(5, 7)) - 1;
        var day = parseInt(date.substring(8, 10));
        var hours = parseInt(date.substring(11, 13));
        var minutes = parseInt(date.substring(14, 16));
        var seconds = parseInt(date.substring(17, 19));
        var milliseconds = 0;
        if(date.indexOf('.') >= 0)
          milliseconds = parseInt(date.substring(20, date.length - 1));

        return new Date(year, month, day, hours, minutes, seconds, milliseconds);
      }

      public convertDateToString(date : Date) : string {
        var year = date.getFullYear();
        var month = '' + (date.getMonth() + 1);
        var day = '' + date.getDate();
        var hour = '' + date.getHours();
        var minute = '' + date.getMinutes();
        var second = '' + date.getSeconds();
        var msecond = '' + date.getMilliseconds();

        if (month.length < 2)
          month = '0' + month;
        if (day.length < 2)
          day = '0' + day;
        if (hour.length < 2)
          hour = '0' + hour;
        if (minute.length < 2)
          minute = '0' + minute;
        if (second.length < 2)
          second = '0' + second;
        if (msecond.length < 2)
          msecond = '0' + msecond;
        if (msecond.length < 3)
          msecond = '0' + msecond;

        return year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + second + '.' + msecond + 'Z';
      }
      public formatDate(date: Date) : string {
        var year = date.getFullYear();
        var month = '' + (date.getMonth() + 1);
        var day = '' + date.getDate();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
      }
      public formatHour(date: Date) : string {
        var hour = '' + date.getHours();
        var minute = '' + date.getMinutes();
        var second = '' + date.getSeconds();

        if (hour.length < 2)
          hour = '0' + hour;
        if (minute.length < 2)
          minute = '0' + minute;
        if (second.length < 2)
          second = '0' + second;

        return [hour, minute, second].join(':');
      }
    }
