export class userDetail {
    /* from:string;
    to:string; */
    //interval:string;
    userId: string;
    primaryPresence: Array<primaryPresence>;
    routingStatus: Array<routingStatus>;
}

/* export class usersId {
    userId: string;
} */
export class primaryPresence {
    startTime: string;
    endTime: string;
    systemPresence: string;
    organizationPresenceId: string;
}

export class routingStatus {
    startTime: string;
    endTime: string;
    routingStatus: string;
    
}