import { Injectable } from '@angular/core';
import Swal from 'sweetalert2'
import * as platformClient from 'purecloud-platform-client-v2';
import { PurecloudService } from '../../services/purecloud/purecloud.service';
/**estructura de combos */
import { campania } from '../../models/hipotecario/campania';
import { agente } from 'src/app/models/hipotecario/agente';
import { resultado } from 'src/app/models/hipotecario/resultado';
import { auditor } from 'src/app/models/hipotecario/auditor';
/**Models de reportes */
import { calidad_customer } from 'src/app/models/hipotecario/calidad_customer';

@Injectable({
  providedIn: 'root'
})
export class hipotecarioDataCustomerService {
  private Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1000
  });

  constructor(private purecloud: PurecloudService) { }


  /*Obtener datos reporte calidad de customer */
  generateSync_customer(from: Date, to: Date, sector: string, campanias: Array<campania>, campanias_dataTable: Array<campania>, agentes: Array<agente>, resultados: Array<resultado>, auditores: Array<auditor>): Promise<calidad_customer[]> {

    let promise = new Promise<calidad_customer[]>((resolve, reject) => {
      var calls: calidad_customer[] = [];

      this.downSync_customer(0, from, to, sector, campanias, campanias_dataTable, agentes, resultados, auditores, calls, resolve, reject);
    });
    return promise;
  }

  private downSync_customer(index: number, from: Date, to: Date, sector: string, campanias: Array<campania>, campanias_dataTable: Array<campania>, agentes: Array<agente>, resultados: Array<resultado>, auditores: Array<auditor>, calls: calidad_customer[], resolve, reject): void {
    index++;
    this.purecloud.postAnalyticsConversationsDetailsQuery_calidad(from, to, campanias, agentes, resultados, auditores, campanias_dataTable, index)
      .then((queryResponse) => this.processSync_customer(queryResponse.conversations, index, from, to, sector, campanias, campanias_dataTable, agentes, resultados, auditores, calls, resolve, reject))
      .catch((response) => {
        //reject(response);
        Swal.fire(
          response.status,
          response.text,
          'error'
        )
      }
      );
  }

  private async processSync_customer(conversations: platformClient.Models.AnalyticsConversationWithoutAttributes[], index: number, from: Date, to: Date, sector: string, campanias: Array<campania>, campanias_dataTable: Array<campania>, agentes: Array<agente>, resultados: Array<resultado>, auditores: Array<auditor>, calls: calidad_customer[], resolve, reject) {
    if (conversations === undefined || conversations.length === 0) {
      resolve(calls);
    } else {
      for (var i = 0; i < conversations.length; i++) {
        this.Toast.fire({
          title: 'Llamadas consideradas ' + calls.length + ' de ' + (100 * (index - 1) + conversations.length),
          timer: 1000
        });
        for (var j = 0; j < conversations[i].evaluations.length; j++) {
          /*var containQueue = false;
           for(var k= 0; k< campanias_dataTable.length; k++){
              if (conversations[i].evaluations[j].queueId === campanias_dataTable[k].value){
                containQueue = true;
              }

          }  */
          if (conversations[i].evaluations[j].deleted === undefined && conversations[i].evaluations[j].formName.indexOf(sector) > 0) {// && containQueue
            await this.processQueues_customer(campanias_dataTable, conversations[i], conversations[i].evaluations[j].evaluationId, calls, resolve, reject);
          }
        }
      }
      this.downSync_customer(index, from, to, sector, campanias, campanias_dataTable, agentes, resultados, auditores, calls, resolve, reject);
    }
  }

  private async processQueues_customer(campanias_dataTable: Array<campania>, conversation: platformClient.Models.AnalyticsConversationWithoutAttributes, evaluationId: string, calls: calidad_customer[], resolve, reject) {

    let queryResponse = await this.purecloud.getQualityConversationEvaluation(conversation.conversationId, evaluationId)
      .then((queryResponse) => this.process_ConversationEvaluation_customer(campanias_dataTable, queryResponse, conversation, calls))
      .catch((response) => reject(response));
  }

  private process_ConversationEvaluation_customer(campanias_dataTable: Array<campania>, evaluation: platformClient.Models.Evaluation, conversation: platformClient.Models.AnalyticsConversationWithoutAttributes, calls: calidad_customer[]) {
    if (evaluation.status === 'FINISHED') {//si estan en estado PENDING, no tiene formulario de evaluacion
      var call = new calidad_customer();
      call.evaluationId = evaluation.id;
      var start = this.convertStringToDate(conversation.conversationStart);
      start.setHours(start.getHours() - 3);
      call.conversationId = conversation.conversationId;
      call.fecha_aud = this.formatDate(start);
      call.hora_aud = this.formatHour(start);
      call.fecha_auditoria = call.fecha_aud + ' ' + call.hora_aud;

      /**si status de evaluation no es FINISHED, no tiene una fecha de relaeaseDate */
      //var fecha = this.convertStringToDate(evaluation.releaseDate);
      var fecha = (evaluation.releaseDate === undefined) ? this.convertStringToDate(evaluation.changedDate) : this.convertStringToDate(evaluation.releaseDate);
      // console.log(fecha);
      fecha.setHours(fecha.getHours() - 3);
      call.fecha = this.formatDate(fecha);
      call.hora = this.formatHour(fecha);
      call.fecha_llamada = call.fecha + ' ' + call.hora;



      this.process_evaluator(evaluation.evaluator, call);
      this.process_agente(evaluation.agent, call);
      this.process_dniCliente(conversation.participants, call);
      //  console.log(conversation.evaluations);
      //  console.log(evaluation);
      this.process_canal(campanias_dataTable, conversation.evaluations, call);
      // console.log(evaluation.evaluationForm);
      this.process_EvaluationForm(evaluation.evaluationForm, evaluation.answers, call);
      // this.process_answers(evaluation.answers,call);
      calls.push(call);
      /*   console.log('calls');
         console.log(calls);  */

    }
  }


  private process_evaluator(evaluator: platformClient.Models.User, call: calidad_customer) {
    call.evaluatorId = evaluator.id;
    call.evaluator = evaluator.name;
  }
  private process_agente(agente: platformClient.Models.User, call: calidad_customer) {
    call.agenteId = agente.id;
    call.agente = agente.name;
  }

  private process_EvaluationForm(evaluationForm: platformClient.Models.EvaluationForm, answers: platformClient.Models.EvaluationScoringSet, call: calidad_customer) {

    evaluationForm.questionGroups.forEach((questionGroup) => {
      switch (questionGroup.name) {
        case 'Ponderaciones Customer Service':
          call.id_questionGroupPonderaciones = questionGroup.id;
          questionGroup.questions.forEach((question) => {
            //console.log('question ' + question.text);
            switch (question.text) {
              case 'Registro de actividad en sistema:':
                question.answerOptions.forEach((answer) => {
                  if (answer.id === this.process_answer_(question.id, answers, questionGroup.id, call)) {
                    call.RegistroActividadId = answer.id;
                    call.RegistroActividad = answer.text;
                  }
                })
                break;
              case 'Error de información:':
                question.answerOptions.forEach((answer) => {
                  if (answer.id === this.process_answer_(question.id, answers, questionGroup.id, call)) {
                    call.errorInfoId = answer.id;
                    call.errorInfo = answer.text;

                  }
                })
                break;
              case 'Validación y actualización de datos:':
                question.answerOptions.forEach((answer) => {
                  if (answer.id === this.process_answer_(question.id, answers, questionGroup.id, call)) {
                    call.ValidacionDatosId = answer.id;
                    call.validacionDatos = answer.text;

                  }
                })
                break;
              case 'Interés por resolver / retener:':
                question.answerOptions.forEach((answer) => {
                  if (answer.id === this.process_answer_(question.id, answers, questionGroup.id, call)) {
                    call.interesResolverId = answer.id;
                    call.interesResolver = answer.text;

                  }
                })
                break;
              case 'Procedimiento de retención / Resolución:':
                question.answerOptions.forEach((answer) => {
                  if (answer.id === this.process_answer_(question.id, answers, questionGroup.id, call)) {
                    call.procedimientoRetencionId = answer.id;
                    call.procedimientoRetencion = answer.text;

                  }
                })
                break;
              case 'Saludo inicial / Saludo final:':
                question.answerOptions.forEach((answer) => {
                  if (answer.id === this.process_answer_(question.id, answers, questionGroup.id, call)) {
                    call.saludosIniFinId = answer.id;
                    call.saludosIniFin = answer.text;

                  }
                })
                break;
              case 'Habilidades de comunicación':
                question.answerOptions.forEach((answer) => {
                  if (answer.id === this.process_answer_(question.id, answers, questionGroup.id, call)) {
                    call.habilidadesComunicacionId = answer.id;
                    call.habilidadesComunicacion = answer.text;

                  }
                })
                break;
              case 'Resumen de Gestión:':
                question.answerOptions.forEach((answer) => {
                  if (answer.id === this.process_answer_(question.id, answers, questionGroup.id, call)) {
                    call.resumenGestionId = answer.id;
                    call.resumenGestion = answer.text;

                  }
                })
                break;
              case 'Hold:':
                question.answerOptions.forEach((answer) => {
                  if (answer.id === this.process_answer_(question.id, answers, questionGroup.id, call)) {
                    call.holdId = answer.id;
                    call.hold = answer.text;

                  }
                })
                break;
            }

          })
          call.comments = this.process_comments(answers, '', questionGroup.id, call);

          break;
        case 'Oferta Cross':
          call.id_questionGroupCross = questionGroup.id;
          questionGroup.questions.forEach((questionCross) => {
            //  console.log(questionCross.text);
            //if(questionCross.text.indexOf('Opciones en Oferta Cross:') > 0 ){
            if (questionCross.text === 'Producto que se ofreció:') {
              call.answer_textCross = 'N/A';
              questionCross.answerOptions.forEach((answerCross) => {
                if (answerCross.id === this.process_answer_(questionCross.id, answers, questionGroup.id, call)) {
                  call.ofertaCross_id = answerCross.id;
                  call.answer_textCross = answerCross.text;
                }
              })

            }
          })
          break;
        case 'Carga en sistema':
          call.cargaSistema_id = questionGroup.id;
          questionGroup.questions.forEach((questionSistema) => {
            if (questionSistema.text === 'Motivo real del llamado') {
              call.cargaSistema_id = questionSistema.id;
              questionSistema.answerOptions.forEach((answerSistema) => {
                if (answerSistema.id === this.process_answer_(questionSistema.id, answers, questionGroup.id, call)) {
                  call.id_motivoLlamado = answerSistema.id;
                  call.motivo_llamado = answerSistema.text;
                }

              })
            }
            if (questionSistema.text === 'No Retención:') {
              call.retencion_id = questionSistema.id;
              questionSistema.answerOptions.forEach((answerRetencion) => {
                if (answerRetencion.id === this.process_answer_(questionSistema.id, answers, questionGroup.id, call)) {
                  call.option_retencion_id = answerRetencion.id;
                  call.retencion = answerRetencion.text;
                }
              })
            }
          })
          break;
      }
    })
  }

  private process_answer_(questionId_: string, answers: platformClient.Models.EvaluationScoringSet, questionGroupId: string, call: calidad_customer): string {
    var answerId = '';
    call.score = Math.floor(answers.totalScore);
    answers.questionGroupScores.forEach((questionGroupScore) => {
      if (questionGroupId === questionGroupScore.questionGroupId) {

        call.NotaCalidad = 'NO APROBADO';
        if (answers.totalScore >= 80)
          call.NotaCalidad = 'APROBADO';
        questionGroupScore.questionScores.forEach((question) => {
          if (question.questionId === questionId_) {
            answerId = question.answerId;
          }
        })
      }
    })
    return answerId;
  }

  private process_comments(answers: platformClient.Models.EvaluationScoringSet, answerId: string, questionGroupId: string, call: calidad_customer): string {
    var comments = '';
    answers.questionGroupScores.forEach((questionGroupScore) => {
      if (questionGroupId === questionGroupScore.questionGroupId) {
        questionGroupScore.questionScores.forEach((question) => {
          // if(questionId_ = question.questionId)
          comments += question.comments + ' ';
          // });
        })
      }
    });
    comments += answers.comments;
    return comments;
  }

  private process_canal(campanias: Array<campania>, evaluations: platformClient.Models.AnalyticsEvaluation[], call: calidad_customer) {

    for (var i = 0; i < evaluations.length; i++) {
      if (evaluations[i].deleted === undefined && evaluations[i].queueId !== undefined) {
        this.purecloud.getRoutingQueue(evaluations[i].queueId)
          .then((queryResponse) => { call.mediaType = queryResponse.name })
          .catch((response) => console.log(response));
        /* for (var j = 0; j < campanias.length; j++) {
          if (campanias[j].value === evaluations[i].queueId)
          {call.mediaType = campanias[j].viewValue;}
        } */
      }
    }
  }

  private process_dniCliente(participants: platformClient.Models.AnalyticsParticipantWithoutAttributes[], call: calidad_customer) {
    participants.forEach((participant) => {
      participant.sessions.forEach((session) => {
        session.segments.forEach((segment) => {
          if (segment.properties != undefined) {
            segment.properties.forEach((property) => {
              if (property.property === 'DNI_cliente') {
                call.dni_cliente = property.value;
              }
            })
          }
          if (segment.segmentType === 'wrapup') {
            call.wrapUpCode = segment.wrapUpCode;//llamar api
          }
        })
      })
    })
  }

  public convertStringToDate(date: string): Date {
    var year = parseInt(date.substring(0, 4));
    var month = parseInt(date.substring(5, 7)) - 1;
    var day = parseInt(date.substring(8, 10));
    var hours = parseInt(date.substring(11, 13));
    var minutes = parseInt(date.substring(14, 16));
    var seconds = parseInt(date.substring(17, 19));
    var milliseconds = 0;
    if (date.indexOf('.') >= 0)
      milliseconds = parseInt(date.substring(20, date.length - 1));

    return new Date(year, month, day, hours, minutes, seconds, milliseconds);
  }

  public convertDateToString(date: Date): string {
    var year = date.getFullYear();
    var month = '' + (date.getMonth() + 1);
    var day = '' + date.getDate();
    var hour = '' + date.getHours();
    var minute = '' + date.getMinutes();
    var second = '' + date.getSeconds();
    var msecond = '' + date.getMilliseconds();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    if (hour.length < 2)
      hour = '0' + hour;
    if (minute.length < 2)
      minute = '0' + minute;
    if (second.length < 2)
      second = '0' + second;
    if (msecond.length < 2)
      msecond = '0' + msecond;
    if (msecond.length < 3)
      msecond = '0' + msecond;

    return year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + second + '.' + msecond + 'Z';
  }
  public formatDate(date: Date): string {
    var year = date.getFullYear();
    var month = '' + (date.getMonth() + 1);
    var day = '' + date.getDate();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  public formatHour(date: Date): string {
    var hour = '' + date.getHours();
    var minute = '' + date.getMinutes();
    var second = '' + date.getSeconds();

    if (hour.length < 2)
      hour = '0' + hour;
    if (minute.length < 2)
      minute = '0' + minute;
    if (second.length < 2)
      second = '0' + second;

    return [hour, minute, second].join(':');
  }
}
