import { Segment } from '../models/purecloud/conversation';

export class DateTime {
    public static getTimeBySegment(segment: Segment): number {
        return DateTime.getTimeByDate(DateTime.convertStringToDate(segment.segmentStart), DateTime.convertStringToDate(segment.segmentEnd));
    }
    public static getTimeByDate(startDate: Date, endDate: Date): number {
        return endDate.getTime() - startDate.getTime();
    }
    public static convertStringToDatePlus3(date: string): Date {
        var value = this.convertStringToDate(date);
        value.setHours(value.getHours() + 3);
        return value;
    }
    public static convertStringToDate(date: string): Date {
        var year = parseInt(date.substring(0, 4));
        var month = parseInt(date.substring(5, 7)) - 1;
        var day = parseInt(date.substring(8, 10));
        var hours = parseInt(date.substring(11, 13));
        var minutes = parseInt(date.substring(14, 16));
        var seconds = parseInt(date.substring(17, 19));
        var milliseconds = 0;
        if (date.indexOf('.') >= 0) {
            milliseconds = parseInt(date.substring(20, date.length - 1));
        }
        return new Date(year, month, day, hours, minutes, seconds, milliseconds);
    }
    public static convertDateToString(date: Date): string {
        var year = date.getFullYear();
        var month = '' + (date.getMonth() + 1);
        var day = '' + date.getDate();
        var hour = '' + date.getHours();
        var minute = '' + date.getMinutes();
        var second = '' + date.getSeconds();
        var msecond = '' + date.getMilliseconds();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        if (hour.length < 2)
            hour = '0' + hour;
        if (minute.length < 2)
            minute = '0' + minute;
        if (second.length < 2)
            second = '0' + second;
        if (msecond.length < 2)
            msecond = '0' + msecond;
        if (msecond.length < 3)
            msecond = '0' + msecond;

        return year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + second + '.' + msecond + 'Z';
    }
}